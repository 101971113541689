import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddNumeroParte = () => {
	const [submitted, setSubmitted] = useState(false);
	const [clients, setClients] = useState([]);
	const [units, setUnits] = useState([]);
	const [countries, setCountries] = useState([]);
	const alert = useAlert();

	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getUnits = async () => {
			let { unidades } = await axiosConfig({
				method: 'get',
				url: '/data-system/partes/unidades'
			});
			const data = unidades.map(obj => {
				return { display: obj.Unidad_Medida, value: String(obj.Id_Unidad_Medida) };
			});

			setUnits(data);
		};
		const getCountries = async () => {
			let { paises: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/paises'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Esp, value: String(obj.Id_Pais) };
			});

			setCountries(data);
		};
		getClients();
		getCountries();
		getUnits();
	}, []);
	const submitPartNumber = async (form, values) => {
		if (form.valid) {
			try {
				const partNumberInfo = {
					numeroParte: values.partNumber,
					idCliente: values.client,
					idUnidad: values.unit,
					peso: values.unitWeight,
					descripcionIng: values.descriptionEng,
					descripcionEsp: values.descriptionSp,
					idPais: values.country,
					fraccion: values.fraction,
					marca: values.brand,
					modelo: values.model,
					serie: values.serie,
					IMMEX: values.IMMEX,
					comentarios: values.comments
				};
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Partes/Nuevo',
					data: partNumberInfo
				});
				if (data === 'OK') {
					alert.show(`El # Parte ${values.partNumber} fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema agregando el numero de parte, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/NumerosParte" />;
	}
	return (
		<div className="w-full bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Numero de Parte</p>
				</div>
				<WafoForm formId="newPartNumberForm" onSubmit={submitPartNumber}>
					<div className="flex flex-col mx-auto w-full items-center pr-2 lg:pr-0">
						<div className="flex flex-col xl:flex-row w-full">
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormInput
									name="partNumber"
									label="# Parte"
									placeholder="# Parte"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo # Parte es requerido'
										}
									}}
								/>
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									customClass="form-group-lg"
									options={clients}
									validations={{
										required: {
											value: true,
											message: 'El campo Cliente es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormSelect
									name="unit"
									defaultValue="Seleccione una Opcion"
									label="U.M"
									customClass="form-group-lg"
									options={units}
									type="number"
									extraProps={{
										min: 0
									}}
									validations={{
										required: {
											value: true,
											message: 'El campo U.M es requerido'
										}
									}}
								/>
								<WafoFormInput
									name="unitWeight"
									label="Peso Unitario"
									placeholder="Peso Unitario"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Peso Unitario es requerido'
										}
									}}
									type="number"
									extraProps={{
										min: 0,
										step: 'any'
									}}
								/>
							</div>
						</div>
						<div className="flex flex-col xl:flex-row w-full">
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormInput
									name="descriptionSp"
									label="Descripcion Esp"
									placeholder="Descripcion Esp"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Descripcion Esp es requerido'
										}
									}}
								/>
								<WafoFormInput
									name="descriptionEng"
									label="Descripcion Ing"
									placeholder="Descripcion Ing"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Descripcion Ing es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormSelect
									name="country"
									defaultValue="Seleccione una Opcion"
									label="Pais Origen"
									customClass="form-group-lg"
									options={countries}
									validations={{
										required: {
											value: true,
											message: 'El campo Pais Origen es requerido'
										}
									}}
								/>
								<WafoFormInput
									name="fraction"
									label="Fraccion"
									placeholder="Fraccion"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Fraccion es requerido'
										},
										regex: {
											value: /\d{6,10}/,
											message: 'Fraccion invalida'
										}
									}}
									extraProps={{
										maxLength: 10
									}}
								/>
							</div>
						</div>
						<div className="flex flex-col xl:flex-row w-full">
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormInput
									name="IMMEX"
									label="IMMEX"
									placeholder="IMMEX"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo IMMEX es requerido'
										}
									}}
									extraProps={{
										maxLength: 10
									}}
								/>
								<WafoFormInput
									name="brand"
									label="Marca"
									placeholder="Marca"
									customClass="form-group-lg"
								/>
							</div>
							<div className="flex flex-col lg:flex-row w-full">
								<WafoFormInput
									name="model"
									label="Modelo"
									placeholder="Modelo"
									customClass="form-group-lg"
								/>
								<WafoFormInput
									name="serie"
									label="Serie"
									placeholder="Serie"
									customClass="form-group-lg"
								/>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row w-full lg:w-2/4">
							<WafoFormTextArea
								name="comments"
								customClass="form-group-lg"
								placeholder="Comentarios"
								label="Comentarios"
								extraProps={{ rows: 3 }}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-2 mb-2">
							Agregar Numero de Parte
						</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddNumeroParte;
