import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListRegimenes from './ListRegimenes';
import AddRegimen from './AddRegimen';
import EditRegimen from './EditRegimen';
import PrivateRoute from '#components/router/PrivateRoute';

const Regimenes = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Regimenes" component={ListRegimenes} />
			<Route exact path="/Data-System/Catalogos/Regimenes/Nuevo" component={AddRegimen} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Regimenes/Editar"
				component={EditRegimen}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/Regimenes" />} />
		</Switch>
	);
};

export default Regimenes;
