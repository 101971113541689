import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListEstados from './ListEstados';
import AddEstado from './AddEstado';
import EditEstado from './EditEstado';
import PrivateRoute from '#components/router/PrivateRoute';

const Estados = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Estados" component={ListEstados} />
			<Route exact path="/Data-System/Catalogos/Estados/Nuevo" component={AddEstado} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Estados/Editar"
				component={EditEstado}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/Estados" />} />
		</Switch>
	);
};

export default Estados;
