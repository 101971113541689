import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit, FaCheckCircle } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import { getVerifiedToken } from '#utils/authService';
const ListClientes = () => {
	const titles = ['Cliente', 'Emails', 'Capacidad Bultos', 'Habilitado/Deshabilitado', 'Opciones'];
	const [clients, setClients] = useState([]);
	const [totalRows, setTotalRows] = useState();
	const [clientsPerPage, setClientsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentClient, setCurrentClient] = useState({});
	const [userInfo, setUserInfo] = useState();
	const [search, setSearch] = useState();
	const alert = useAlert();
	useEffect(() => {
		setCurrentPage(1);
	}, [clientsPerPage]);

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
		getClients();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, clientsPerPage, search]);

	const getClients = async () => {
		const { FilasTotales, result } = await axiosConfig({
			method: 'get',
			url: '/data-system/clientes',
			params: {
				paginaActual: currentPage,
				limit: clientsPerPage,
				deshabilitados: true,
				search
			}
		});
		if (result) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}
			setClients(result);
		}
	};

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / clientsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, clientsPerPage]);

	const deleteClient = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Clientes/Habilitar',
				data: {
					idCliente: currentClient.Id_Cliente
				}
			});

			if (data === 'OK') {
				alert.show(`El Cliente ${currentClient.Nombre_Cliente} fue eliminado correctamente.`, {
					type: 'success'
				});
				getClients();
			}
		} catch (error) {
			alert.show('Hubo un problema eliminando al cliente, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (userInfo.Id_Tipo_Usuario === 3) {
				return (
					<Modal>
						<p className="text-xl text-black">
							­¿Desea {currentClient.Disabled === 1 ? 'Habilitar' : 'Deshabilitar'} Este Cliente?:
						</p>
						<p className="text-xl text-black">{currentClient.Nombre_Cliente}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => deleteClient()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			} else {
				setShowModal(false);
				alert.show('Solo los administradores pueden habilitar/deshabilitar clientes.', {
					type: 'error'
				});
			}
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Clientes</p>
				<input
					className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
					id="search"
					type="text"
					value={search}
					onChange={event => setSearch(event.target.value)}
					placeholder="Buscar Cliente"
				/>
				<div className="ml-4 flex flex-row">
					<button
						className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage - 1);
						}}
						disabled={currentPage === 1 ? 'disabled' : ''}
					>
						&laquo;
					</button>
					<select
						value={currentPage}
						name="currentPage"
						id="currentPage"
						onChange={event => setCurrentPage(parseInt(event.target.value))}
						className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						{pageNumbers.map(p => {
							return (
								<option key={p} value={p}>
									{p}
								</option>
							);
						})}
					</select>
					<button
						className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage + 1);
						}}
						disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
					>
						&raquo;
					</button>
				</div>
				<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
					Mostrar
					<select
						value={clientsPerPage}
						onChange={event => setClientsPerPage(parseInt(event.target.value))}
						className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option value="20">20</option>
						<option value="50">50</option>
					</select>
				</label>
				<Link to="/Data-System/Catalogos/Clientes/Nuevo" style={{ textDecoration: 'none' }}>
					<button className="button btn-lg bg-green-600 ml-3 ">Agregar Cliente</button>
				</Link>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{clients.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{clients.map(client => {
								return (
									<tr key={client.Id_Cliente}>
										<td>{client.Nombre_Cliente}</td>
										<td>
											{client.email_cliente.map(
												(email, index) =>
													`${email.Email} ${client.email_cliente.length === index + 1 ? '' : ','} `
											)}
										</td>
										<td>{client.Capacidad_Bultos}</td>
										<td>{+client.Disabled === 0 ? 'Habilitado' : 'Deshabilitado'}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Clientes/Editar',
														state: { clientId: client.Id_Cliente }
													}}
												>
													<button className="button bg-blue-700 w-7 h-8">
														<FaEdit />
													</button>
												</Link>

												<button
													className={`mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button ${
														+client.Disabled === 1 ? 'bg-green-700' : 'bg-red-700'
													}`}
													onClick={() => {
														setCurrentClient(client);
														setShowModal(true);
													}}
												>
													{+client.Disabled === 1 ? <FaCheckCircle /> : <FaTrashAlt />}
												</button>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListClientes;
