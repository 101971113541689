import React from 'react';
import { MdWarning, MdInfo, MdCheckCircle } from 'react-icons/md';
const CustomAlert = ({ options, message, close }) => (
	// <div
	// 	className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
	// 	role="alert"
	// >
	// {options.type === 'info' && <MdInfo />}
	// {options.type === 'success' && <MdCheckCircle />}
	// {options.type === 'error' && <MdWarning />}
	// 	<p>{message}</p>
	// 	<button onClick={close}>X</button>
	// </div>

	<div className={`alert alert-${options.type} `} role="alert">
		<div className="flex">
			<div className="py-1 mx-2">
				<p className={`alert-icon-${options.type}`}>
					{options.type === 'info' && <MdInfo />}
					{options.type === 'success' && <MdCheckCircle />}
					{options.type === 'error' && <MdWarning />}
				</p>
			</div>
			<div>
				<p className="text-lg font-bold">
					{options.type === 'info' && 'Info'}
					{options.type === 'success' && 'Exito'}
					{options.type === 'error' && 'Error'}
				</p>
				<p className="text-md">{message}</p>
			</div>
		</div>
	</div>
);

export default CustomAlert;
