import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddUnidadBultoForm } from '#forms';

const AddUnidadBulto = () => {
	const history = useHistory();
	const onSubmitSuccess = () => {
		history.push('/data-system/catalogos/UnidadesBulto');
	};

	return <AddUnidadBultoForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />;
};

export default AddUnidadBulto;
