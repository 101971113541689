import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListLocaciones from './ListLocaciones';
import AddLocacion from './AddLocacion';
import EditLocacion from './EditLocacion';
import PrivateRoute from '#components/router/PrivateRoute';

const Locaciones = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Locaciones" component={ListLocaciones} />
			<Route exact path="/Data-System/Catalogos/Locaciones/Nueva" component={AddLocacion} />
			<Route exact path="/Data-System/Catalogos/Locaciones/Editar" component={EditLocacion} />
			<Route render={() => <Redirect to="/Data-System/Catalogos/Locaciones" />} />
		</Switch>
	);
};

export default Locaciones;
