import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListLogistica from './ListLogistica';
import AddLogistica from './AddLogistica';
import EditLogistica from './EditLogistica';
const Logistica = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Logistica" component={ListLogistica} />
			<Route exact path="/Data-System/Logistica/Agregar" component={AddLogistica} />
			<Route exact path="/Data-System/Logistica/Editar" component={EditLogistica} />
			<Route render={() => <Redirect to="/Data-System/Logistica" />} />
		</Switch>
	);
};

export default Logistica;
