import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaCheckCircle, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Entradas = () => {
	const titles = [
		'Entrada',
		'Fecha',
		'Revisada',
		'Bultos',
		'Dias',
		'Proveedor',
		'PO',
		'Tracking',
		'Packing List'
	];
	const [entries, setEntries] = useState([]);
	const [search, setSearch] = useState('');
	const [totalRows, setTotalRows] = useState();
	const [entriesPerPage, setEntriesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);

	useEffect(() => {
		setCurrentPage(1);
	}, [entriesPerPage, search]);

	useEffect(() => {
		const getEntries = async () => {
			const { result, FilasTotales } = await axiosConfig({
				method: 'get',
				url: '/inventario/entradas',
				params: {
					search: search,
					rowsPorPagina: entriesPerPage,
					paginaActual: currentPage
				}
			});
			if (result) {
				if (FilasTotales > 0) {
					setTotalRows(FilasTotales);
				} else {
					setTotalRows(1);
				}
				setEntries(result);
			}
		};
		getEntries();
	}, [search, currentPage, entriesPerPage]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / entriesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, entriesPerPage]);

	const downloadEntrieDetails = async (entrieId, entrie) => {
		const params = {
			idEntrada: +entrieId
		};
		axiosConfig({
			url: '/data-system/entradas/reportes',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Entrada ${entrie}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row w-full mt-2 md:mt-0">
					<p className="text-xl mt-2">Entradas</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar entrada, referencia o tracking"
					/>
				</div>
				<div className="flex flex-row w-full ml-32">
					<div className="ml-4 flex flex-row my-2">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostar
						<select
							value={entriesPerPage}
							onChange={event => setEntriesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{entries.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{entries.map(entrie => {
								let lumpsQuantity = 0;
								let maxDays = 0;
								let entrieState = {};
								entrie.partida_entrada.map(pe => {
									lumpsQuantity += pe.Cantidad_Bultos;
									return null;
								});
								entrie.partida_entrada.map(departure => {
									if (departure.partida_regimen.Dias > maxDays) {
										maxDays = departure.partida_regimen.Dias;
									}
									if (!entrieState.Prioridad) {
										entrieState = departure.estado_partida;
									} else if (departure.estado_partida.Prioridad > entrieState.Prioridad) {
										entrieState = departure.estado_partida;
									}
								});

								let rowStyle = 'selected';
								if (+entrie.Dias > maxDays) rowStyle = 'old-row';
								return (
									<tr className={rowStyle} key={entrie.Numero_Entrada}>
										<td className="flex flex-col">
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadEntrieDetails(entrie.Id_Entrada, entrie.Entrada);
												}}
											>
												{entrie.Entrada}
											</button>

											<button
												className={`button bg-blue-600 w-full`}
												style={{ backgroundColor: entrieState.Color }}
												onClick={e => {
													e.preventDefault();
												}}
											>
												{entrieState.Estado}
											</button>
										</td>
										<td>{entrie.Fecha}</td>
										<td>
											{entrie.Revisada === 1 ? <FaCheckCircle style={{ color: 'green' }} /> : '-'}
										</td>
										<td>{lumpsQuantity}</td>
										<td>{entrie.Dias}</td>
										<td>{entrie.proveedor_entrada.Nombre_Proveedor}</td>
										<td>{entrie.PO}</td>
										<td>{entrie.Tracking}</td>
										<td></td>
										<td>
											<Link
												to={{
													pathname: '/Inventario/Entradas/Detalles',
													state: { entrieId: entrie.Id_Entrada }
												}}
											>
												Detalles
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Entradas;
