import React, { useState } from 'react';
import { AddProveedorForm } from '#forms';
import { useHistory } from 'react-router-dom';

const AddProveedor = () => {
	const history = useHistory();
	const onSubmitSuccess = () => {
		history.push('/data-system/catalogos/proveedores');
	};

	return <AddProveedorForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />;
};

export default AddProveedor;
