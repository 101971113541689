import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt, FaCheckCircle } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import { getVerifiedToken } from '#utils/authService';

const ListLocaciones = () => {
	const [totalRows, setTotalRows] = useState();
	const [locationsPerPage, setLocationsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const titles = ['Locacion', 'Habilitada/Deshabilitada', 'Opciones'];
	const [locations, setLocations] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentLocation, setCurrentLocation] = useState({});
	const alert = useAlert();
	const [userInfo, setUserInfo] = useState();
	const [search, setSearch] = useState();

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
		getLocations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, locationsPerPage, search]);

	useEffect(() => {
		setCurrentPage(1);
	}, [locationsPerPage]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / locationsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, locationsPerPage]);

	const getLocations = async () => {
		let { locaciones: locations, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/Inventario/Cajas/Locaciones',
			params: {
				limit: locationsPerPage,
				paginaActual: currentPage,
				deshabilitados: true,
				search
			}
		});
		if (FilasTotales > 0) {
			setTotalRows(FilasTotales);
		} else {
			setTotalRows(1);
		}
		setLocations(locations);
	};

	const enableLocation = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Inventario/Cajas/Locaciones/Habilitar',
				data: {
					idLocacion: currentLocation.Id_Locacion
				}
			});

			if (data === 'OK') {
				alert.show(
					`La locacion ${currentLocation.Locacion} fue ${
						currentLocation.Disabled === 1 ? 'habilitada' : 'deshabilitada'
					} correctamente.`,
					{
						type: 'success'
					}
				);
				getLocations();
			}
		} catch (error) {
			alert.show(
				`Hubo un problema ${
					currentLocation.Disabled === 1 ? 'habilitando' : 'deshabilitando'
				} la locacion.`,
				{
					type: 'error'
				}
			);
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (userInfo.Id_Tipo_Usuario === 3) {
				return (
					<Modal>
						<p className="text-xl text-black">
							­¿Desea {currentLocation.Disabled === 1 ? 'Habilitar' : 'Deshabilitar'} Esta
							Locacion?:
						</p>
						<p className="text-xl text-black">{currentLocation.Locacion}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => enableLocation()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			} else {
				setShowModal(false);
				alert.show('Solo los administradores pueden habilitar/deshabilitar locaciones.', {
					type: 'error'
				});
			}
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Locaciones</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar locacion"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={locationsPerPage}
							onChange={event => setLocationsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Locaciones/Nueva"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Locacion</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{locations.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{locations.map(location => {
								return (
									<tr key={location.Id_Locacion}>
										<td>{location.Locacion}</td>
										<td>{location.Disabled === 1 ? 'Deshabilitada' : 'Habilitada'}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Locaciones/Editar',
														state: { locationId: location.Id_Locacion }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
												<button
													className={`mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button ${
														+location.Disabled === 1 ? 'bg-green-700' : 'bg-red-700'
													}`}
													onClick={() => {
														setCurrentLocation(location);
														setShowModal(true);
													}}
												>
													{+location.Disabled === 1 ? <FaCheckCircle /> : <FaTrashAlt />}
												</button>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListLocaciones;
