import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaSquareFull, FaCheckSquare } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from '#components/Modal';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import Axios from 'axios';

const InventarioCajas = () => {
	const titles = [
		'Rampa',
		'Caja',
		'Cargada',
		'Comentarios',
		'Cliente',
		'Fecha',
		'Dias',
		'Archivos',
		'Retirar'
	];
	const [boxes, setBoxes] = useState([]);
	const [locations, setLocations] = useState([]);
	const alert = useAlert();
	const [showModal, setShowModal] = useState(false);
	const [currentBox, setCurrentBox] = useState({});
	useEffect(() => {
		const getLocations = async () => {
			const { locaciones: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/inventario/cajas/locaciones'
			});
			setLocations(result);
		};
		getLocations();
		getBoxes();
	}, []);

	const getBoxes = async () => {
		const result = await axiosConfig({
			method: 'get',
			url: '/data-system/inventario/cajas'
		});
		setBoxes(result);
	};

	const removeBox = async (form, values) => {
		if (form.valid) {
			setShowModal(false);
			const boxInfo = {
				idCaja: currentBox.Id_Caja,
				fechaSalida: moment(values.date).toISOString()
			};
			const { data } = await axiosConfig({
				method: 'put',
				url: '/data-system/inventario/cajas/retirar',
				params: boxInfo
			});

			if (data === 'OK') {
				alert.show('Caja retirada correctamente', { type: 'success' });
				getBoxes();
				setShowModal(false);
			} else {
				alert.show('Hubo un problema al retirar la caja, intente mas tarde', { type: 'error' });
			}
		}
	};

	function renderModal() {
		if (showModal) {
			return (
				<Modal>
					<p className="text-xl text-black">­Retirar caja {currentBox.Numero_Caja}:</p>
					<WafoForm formId={'reportInfo'} onSubmit={removeBox}>
						<WafoFormInput
							name="date"
							label="Fecha Salida"
							customClass="form-group-lg"
							type="datetime-local"
							validations={{
								required: {
									value: true,
									message: 'El campo Fecha Salida es requerido'
								}
							}}
						/>

						<div className="flex flex-row pl-3 justify-around mt-2">
							<button
								className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="ml-1 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								type="submit"
							>
								Confirmar
							</button>
						</div>
					</WafoForm>
				</Modal>
			);
		}
	}
	const downloadInventoryReport = async () => {
		axiosConfig({
			url: '/data-system/inventario/cajas/reporte',
			method: 'GET',
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte-Inventario Cajas.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
	};

	const downloadFiles = async (boxId, boxNumber) => {
		const files = await axiosConfig({
			method: 'get',
			url: '/data-system/inventario/cajas/archivos',
			params: {
				idCaja: boxId
			}
		});

		const params = {
			idCaja: boxId
		};
		axiosConfig({
			url: '/data-system/inventario/cajas/archivos',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Archivos Caja ${boxNumber}.zip`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Inventario cajas</p>

				<Link to="/Data-System/Inventario/Cajas/Nueva">
					<button className="ml-2 button w-48 bg-green-600 h-8">Agregar Registro</button>
				</Link>

				<button
					className="ml-2 button w-48 bg-green-600 h-8"
					onClick={event => {
						event.preventDefault();
						downloadInventoryReport();
					}}
				>
					Descargar Reporte
				</button>
			</div>

			{locations.map(({ Locacion, Id_Locacion }) => {
				return (
					<div className="flex flex-col shadow-md mt-3 overflow-y-auto" key={Id_Locacion}>
						<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
							<p className="text-xl mt-2">{Locacion}</p>
						</div>
						<table className="text-left mt-2 w-full border-collapse table">
							<thead>
								<tr>
									{titles.map(title => (
										<th className="text-gray-dark" key={title}>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{boxes.map(box => {
									if (box.locacion_caja.Id_Locacion === Id_Locacion) {
										return (
											<tr key={box.Id_Caja}>
												<td>{box.Posicion}</td>
												<td>{box.Numero_Caja}</td>
												<td>
													{box.Cargada === 1 ? (
														<FaCheckSquare style={{ color: 'green' }} />
													) : (
														<FaSquareFull style={{ color: 'red' }} />
													)}
												</td>
												<td>{box.Comentarios}</td>
												<td>{box.caja_cliente.Nombre_Cliente}</td>
												<td>{moment.utc(box.Fecha_Entrada).format('YYYY-MM-DD')}</td>
												<td>{Math.round(box.Dias)}</td>
												<td>
													<button
														className="button btn-md bg-red-700 h-8"
														onClick={() => {
															downloadFiles(box.Id_Caja, box.Numero_Caja);
														}}
														disabled={box.archivo_caja.length > 0 ? false : true}
													>
														Descargar
													</button>
												</td>
												<td>
													<button
														className="button btn-md bg-red-700 h-8"
														onClick={() => {
															setCurrentBox(box);
															setShowModal(true);
														}}
													>
														Retirar caja
													</button>
												</td>
											</tr>
										);
									} else {
										return null;
									}
								})}
							</tbody>
						</table>
					</div>
				);
			})}
			{renderModal()}
		</div>
	);
};

export default InventarioCajas;
