import React, { useEffect, useState } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { CustomFileInput } from '#components/form/controls';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';

const DetallesEntrada = ({ location: { state } }) => {
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [providers, setProviders] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [lumpsUnit, setLumpsUnit] = useState([]);
	const [measureUnits, setMeasureUnits] = useState([]);
	const [countries, setCountries] = useState([]);
	const [keyCount, setKeyCount] = useState(0);
	const [entrieDepartureForm, setEntrieDepartureForm] = useState([]);
	const [partNumbersSearch, setPartNumbersSearch] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [selectedClient, setSelectedClient] = useState(0);
	const [defaultEntrie, setDefaultEntrie] = useState({});
	const [states, setStates] = useState([]);
	const [regimes, setRegimes] = useState([]);
	useEffect(() => {
		const getStates = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/estados'
			});
			const data = rows.map(obj => {
				return { display: obj.Estado, value: String(obj.Id_Estado) };
			});

			setStates(data);
		};
		const getRegimes = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/regimenes'
			});
			const data = rows.map(obj => {
				return { display: obj.Nombre_Regimen, value: String(obj.Id_Regimen) };
			});

			setRegimes(data);
		};
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getProviders = async () => {
			let { proveedores: providers } = await axiosConfig({
				method: 'get',
				url: '/data-system/proveedores'
			});
			providers = providers.map(obj => {
				return { display: obj.Nombre_Proveedor, value: String(obj.Id_Proveedor) };
			});
			setProviders(providers);
		};
		const getCarriers = async () => {
			let { transportistas: carriers } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			carriers = carriers.map(obj => {
				return { display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) };
			});
			setCarriers(carriers);
		};
		const getLumpsUnit = async () => {
			let { unidades } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/bultos'
			});
			const data = unidades.map(obj => {
				return { display: obj.Unidad_Bulto, value: String(obj.Id_Unidad_Bulto) };
			});
			setLumpsUnit(data);
		};
		const getMeasureUnits = async () => {
			let { unidades } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/medida'
			});
			const data = unidades.map(obj => {
				return { display: obj.Unidad_Medida, value: String(obj.Id_Unidad_Medida) };
			});
			setMeasureUnits(data);
		};
		const getCountries = async () => {
			let { paises: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/paises'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Esp, value: String(obj.Id_Pais) };
			});

			setCountries(data);
		};
		const getEntrie = async () => {
			const entrieInfo = await axiosConfig({
				method: 'get',
				url: '/data-system/entradas/getEntrada',
				params: {
					idEntrada: state.entrieId
				}
			});

			setSelectedClient(entrieInfo.entrada_cliente.Id_Cliente);

			setDefaultEntrie({
				entrieId: entrieInfo.Id_Entrada,
				date: moment.utc(entrieInfo.Fecha).format('YYYY-MM-DD'),
				impoExpo: entrieInfo.Impo_Expo,
				client: String(entrieInfo.entrada_cliente.Id_Cliente),
				carrier: String(entrieInfo.transportista_entrada.Id_Transportista),
				provider: String(entrieInfo.proveedor_entrada.Id_Proveedor),
				reference: entrieInfo.Referencia,
				box: entrieInfo.Numero_Caja,
				stamp: entrieInfo.Sello,
				bill: entrieInfo.Factura,
				tracking: entrieInfo.Tracking,
				po: entrieInfo.PO,
				observations: entrieInfo.Observaciones
			});

			let departures = {};
			entrieInfo.partida_entrada.map(async (partida, index) => {
				departures = {
					...departures,
					[`departureId${index}`]: partida.Id_Partida_Entrada,
					[`piecesQuantity${index}`]: partida.Cantidad_Piezas,
					[`lumpsQuantity${index}`]: partida.Cantidad_Bultos,
					[`lumpsUnit${index}`]: String(partida.Id_Unidad_Bulto),
					[`netWeight${index}`]: partida.Peso_Neto,
					[`grossWeight${index}`]: partida.Peso_Bruto,
					[`IMMEX${index}`]: partida.IMMEX,
					[`measureUnit${index}`]: String(partida.Id_Unidad_Medida),
					[`po${index}`]: partida.PO,
					[`country${index}`]: String(partida.Id_Pais),
					[`fraction${index}`]: partida.Fraccion,
					[`location${index}`]: partida.Locacion,
					[`comments${index}`]: partida.Comentarios,
					[`regime${index}`]: String(partida.Id_Regimen),
					[`state${index}`]: String(partida.Id_Estado),
					[`stateName${index}`]: partida.estado_partida.Estado,
					[`stateColor${index}`]: partida.estado_partida.Color
				};

				filterPartNumbers(
					partida.numero_parte.Numero_Parte,
					index,
					entrieInfo.entrada_cliente.Id_Cliente
				);

				departures = {
					...departures,
					[`partNumber${index}`]: String(partida.numero_parte.Id_Parte)
				};
				return null;
			});

			setDefaultEntrie(prev => {
				return {
					...prev,
					...departures
				};
			});

			const newDepartureForm = entrieDepartureForm;
			entrieInfo.partida_entrada.map((obj, index) => {
				newDepartureForm.push({
					id: index
				});
				return null;
			});
			setEntrieDepartureForm(newDepartureForm);
			setKeyCount(newDepartureForm.length);
		};

		if (state) {
			getStates();
			getRegimes();
			getClients();
			getProviders();
			getCarriers();
			getLumpsUnit();
			getEntrie();
			getMeasureUnits();
			getCountries();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitEntrie = async (form, values) => {};

	const filterPartNumbers = async (search = '', index, client = selectedClient) => {
		await axiosConfig({
			method: 'get',
			url: '/data-system/partes',
			params: {
				idCliente: client,
				search,
				paginaActual: 1,
				limit: 20
			}
		}).then(({ partes }) => {
			const newPartNumbersSearch = partNumbersSearch;
			newPartNumbersSearch[index] = partes.map(obj => {
				return {
					display: `${obj.Numero_Parte}-${obj.numero_parte_cliente.Nombre_Cliente}`,
					value: String(obj.Id_Parte)
				};
			});

			setPartNumbersSearch(Object.assign([], newPartNumbersSearch));
		});
	};
	const getPartNumber = async partNumberId => {
		const { Numero_Parte } = await axiosConfig({
			method: 'get',
			url: '/data-system/partes/getParte',
			params: {
				idParte: partNumberId
			}
		});
		return Numero_Parte;
	};

	if (submitted) {
		return <Redirect to="/Inventario/Entradas" />;
	}
	if (state) {
		if (!defaultEntrie) {
			return <div>Cargando</div>;
		}
		return (
			<div>
				<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
					<div className="flex flex-col w-full p-4 mb-4 items-center">
						<p className="text-2xl mt-2">Detalles de Entrada</p>
						<WafoForm values={defaultEntrie} formId={'newEntrieForm'} onSubmit={submitEntrie}>
							<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0 ">
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormInput
										validations={{
											required: {
												value: true,
												message: `El campo Fecha es requerido`
											}
										}}
										customClass={'form-group-lg'}
										type="date"
										name="date"
										label="Fecha"
										extraProps={{
											disabled: true
										}}
									/>

									<WafoFormSelect
										name="impoExpo"
										defaultValue="Seleccione una Opcion"
										label="Impo/Expo"
										customClass="form-group-lg"
										options={[
											{ display: 'Impo', value: 'Impo' },
											{ display: 'Expo', value: 'Expo' }
										]}
										validations={{
											required: {
												value: true,
												message: 'El campo Impo/Expo es requerido'
											}
										}}
										extraProps={{
											disabled: true
										}}
									/>
								</div>
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormSelect
										name="client"
										defaultValue="Seleccione una Opcion"
										label="Cliente"
										customClass="form-group-lg"
										options={clients}
										validations={{
											required: {
												value: true,
												message: 'El campo Cliente es requerido'
											}
										}}
										extraProps={{
											disabled: true
										}}
									/>
									<WafoFormSelect
										name="carrier"
										defaultValue="Seleccione una Opcion"
										label="Transportista"
										customClass="form-group-lg"
										options={carriers}
										validations={{
											required: {
												value: true,
												message: 'El campo Transportista es requerido'
											}
										}}
										extraProps={{
											disabled: true
										}}
									/>
								</div>
							</div>
							<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0">
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormSelect
										name="provider"
										defaultValue="Seleccione una Opcion"
										label="Proveedor"
										customClass="form-group-lg"
										options={providers}
										extraProps={{
											disabled: true
										}}
									/>
									<WafoFormInput
										extraProps={{
											maxLength: 30,
											disabled: true
										}}
										customClass={'form-group-lg'}
										placeholder="Referencia"
										type="text"
										name="reference"
										label="Referencia"
									/>
								</div>
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormInput
										extraProps={{
											maxLength: 20,
											disabled: true
										}}
										customClass={'form-group-lg'}
										placeholder="# de Caja"
										type="text"
										name="box"
										label="# de Caja"
									/>
									<WafoFormInput
										extraProps={{
											maxLength: 20,
											disabled: true
										}}
										customClass={'form-group-lg'}
										placeholder="Sello"
										type="text"
										name="stamp"
										label="Sello"
									/>
								</div>
							</div>
							<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0">
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormInput
										customClass={'form-group-lg'}
										placeholder="Factura"
										type="text"
										name="bill"
										label="Factura"
										extraProps={{
											maxLength: 50,
											disabled: true
										}}
									/>
									<WafoFormInput
										extraProps={{
											maxLength: 50,
											disabled: true
										}}
										customClass={'form-group-lg'}
										placeholder="Tracking"
										type="text"
										name="tracking"
										label="Tracking"
									/>
								</div>
								<div className="flex flex-col md:flex-row w-full m-0 p-0">
									<WafoFormInput
										extraProps={{
											maxLength: 20,
											disabled: true
										}}
										customClass={'w-full md:w-2/4'}
										placeholder="PO"
										type="text"
										name="po"
										label="PO"
									/>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row xl:flex-row w-2/4  pr-4 md:pr-0">
								<WafoFormTextArea
									name="observations"
									customClass="form-group-lg"
									placeholder="Observaciones."
									label="Observaciones"
									extraProps={{ rows: 3, disabled: true }}
								/>
							</div>
							{entrieDepartureForm.map((partNumber, index) => {
								console.log(defaultEntrie);
								return (
									<div key={partNumber.id} className="flex flex-col mt-2">
										<div className="flex flex-row items-center">
											<p className="text-gray-700 text-lg mt-2">Partida {+index + 1}</p>
											<button
												className={`button ml-2 mt-1 h-5`}
												style={{ backgroundColor: defaultEntrie[`stateColor${partNumber.id}`] }}
												onClick={e => {
													e.preventDefault();
												}}
											>
												{defaultEntrie[`stateName${partNumber.id}`]}
											</button>
										</div>
										<div className="flex flex-col ">
											<div className="flex flex-col md:flex-row  pr-4 md:pr-0">
												<WafoFormSelect
													extraProps={{
														disabled: true
													}}
													name={`partNumber${partNumber.id}`}
													options={partNumbersSearch[index]}
													defaultValue="Seleccione una Opcion"
													label="# Parte"
													customClass="form-group-lg"
												/>

												<WafoFormInput
													customClass={'form-group-lg'}
													placeholder="Cantidad Piezas"
													type="number"
													name={`piecesQuantity${partNumber.id}`}
													label="Cant. Piezas"
													extraProps={{
														min: 0,
														step: 'any',
														disabled: true
													}}
												/>
												<WafoFormSelect
													name={`measureUnit${partNumber.id}`}
													defaultValue="Seleccione una Opcion"
													label="Unidad Medida"
													customClass="form-group-lg"
													options={measureUnits}
													extraProps={{
														disabled: true
													}}
												/>
												<WafoFormInput
													customClass={'form-group-lg'}
													placeholder="PO"
													type="text"
													name={`po${partNumber.id}`}
													label="PO"
													extraProps={{
														maxLength: 20,
														disabled: true
													}}
												/>
												<WafoFormSelect
													name={`country${partNumber.id}`}
													defaultValue="Seleccione una Opcion"
													label="Pais Origen"
													customClass="form-group-lg"
													options={countries}
													extraProps={{
														disabled: true
													}}
												/>
												<WafoFormInput
													name={`fraction${partNumber.id}`}
													label="Fraccion"
													placeholder="Fraccion"
													customClass="form-group-lg"
													extraProps={{
														maxLength: 10,
														disabled: true
													}}
												/>
											</div>
											<div className="flex flex-col md:flex-row  pr-4 md:pr-0">
												<WafoFormInput
													extraProps={{
														min: 0,
														disabled: true
													}}
													customClass={'form-group-lg'}
													placeholder="Cantidad Bultos"
													type="number"
													name={`lumpsQuantity${partNumber.id}`}
													label="Cant. Bultos"
												/>
												<WafoFormSelect
													name={`lumpsUnit${partNumber.id}`}
													defaultValue="Seleccione una Opcion"
													label="Unidad Bultos"
													customClass="form-group-lg"
													options={lumpsUnit}
													extraProps={{
														disabled: true
													}}
												/>
												<WafoFormInput
													customClass={'form-group-lg'}
													placeholder="Peso Neto"
													type="number"
													name={`netWeight${partNumber.id}`}
													label="Peso Neto"
													extraProps={{
														min: 0,
														step: 'any',
														disabled: true
													}}
												/>
												<WafoFormInput
													customClass={'form-group-lg'}
													placeholder="Peso Bruto"
													type="number"
													name={`grossWeight${partNumber.id}`}
													label="Peso Bruto"
													extraProps={{
														min: 0,
														step: 'any',
														disabled: true
													}}
												/>
												<WafoFormInput
													extraProps={{
														maxLength: 45,
														disabled: true
													}}
													customClass={'form-group-lg'}
													placeholder="Locacion"
													name={`location${partNumber.id}`}
													label="Locacion"
												/>
												<WafoFormSelect
													name={`regime${partNumber.id}`}
													defaultValue="Seleccione una Opcion"
													label="Regimen"
													customClass="form-group-lg"
													options={regimes}
													validations={{
														required: {
															value: true,
															message: `El campo Regimen es requerido`
														}
													}}
													extraProps={{
														disabled: true
													}}
												/>
												<WafoFormSelect
													name={`state${partNumber.id}`}
													defaultValue="Seleccione una Opcion"
													label="Estado"
													customClass="form-group-lg"
													options={states}
													extraProps={{
														disabled: true
													}}
												/>
												<WafoFormInput
													extraProps={{
														maxLength: 10,
														disabled: true
													}}
													customClass={'form-group-lg'}
													placeholder="IMMEX"
													name={`IMMEX${partNumber.id}`}
													label="IMMEX"
												/>
											</div>
										</div>
										<WafoFormTextArea
											name={`comments${partNumber.id}`}
											customClass="w-full md:w-2/4"
											placeholder="Comentarios."
											label="Comentarios"
											extraProps={{ rows: 3, disabled: true }}
										/>
									</div>
								);
							})}
						</WafoForm>
					</div>
				</div>
			</div>
		);
	}
	return <Redirect to="/Inventario/Entradas" />;
};

export default DetallesEntrada;
