import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import Modal from '#components/Modal';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormAutocomplete } from '@wafo/forms';
import useDebounce from '#components/hooks/useDebounce';

const Reportes = () => {
	const [showModal, setShowModal] = useState(false);
	const [clients, setClients] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [partNumbers, setPartNumbers] = useState([]);
	const [reportType, setReportType] = useState();
	const [query, setQuery] = useState('');
	const [startDate, setStartDate] = useState();
	const debouncedQuery = useDebounce(query, 300);

	useEffect(() => {
		const getWarehouses = async () => {
			let { almacenes: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Almacen, value: String(obj.Id_Almacen) };
			});

			setWarehouses(data);
		};
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		getClients();
		getWarehouses();
	}, []);

	const updateList = React.useCallback(async search => {
		try {
			const { partes } = await axiosConfig({
				method: 'get',
				url: '/data-system/partes',
				params: {
					search
				}
			});
			setPartNumbers(partes);
		} catch (error) {
			//
		}
	}, []);

	React.useEffect(() => {
		updateList(debouncedQuery);
	}, [updateList, debouncedQuery]);

	const getReport = (form, values) => {
		if (form.valid) {
			setShowModal(false);
			let params = {
				idCliente: values.client,
				fechaInicio: values.startDate,
				fechaFinal: values.endDate
			};

			let clientName;
			if (reportType === 1 || reportType === 2) {
				clientName =
					clients[
						clients
							.map(x => {
								return x.value;
							})
							.indexOf(values.client)
					].display;
			}

			switch (reportType) {
				case 1:
					axiosConfig({
						url: '/data-system/entradas/reportes/periodo',
						method: 'GET',
						params,
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `Reporte-Entradas ${clientName}.xlsx`);
						document.body.appendChild(link);
						link.click();
					});
					break;
				case 2:
					axiosConfig({
						url: '/data-system/salidas/reportes/periodo',
						method: 'GET',
						params,
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `Reporte-Salidas-Periodo ${clientName}.xlsx`);
						document.body.appendChild(link);
						link.click();
					});
					break;
				case 3:
					axiosConfig({
						url: '/data-system/salidas/reportes/kc',
						method: 'GET',
						params,
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `Reporte-Salidas-KC.xlsx`);
						document.body.appendChild(link);
						link.click();
					});
					break;
				case 4:
					axiosConfig({
						url: '/data-system/salidas/reportes/iconex',
						method: 'GET',
						params,
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `Reporte-Salidas-Iconex.xlsx`);
						document.body.appendChild(link);
						link.click();
					});
					break;

				case 5:
					axiosConfig({
						url: '/data-system/partes/reporte',
						method: 'GET',
						params: {
							idParte: values.partNumber.Id_Parte
						},
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `Reporte Parte ${values.partNumber.Numero_Parte}.xlsx`);
						document.body.appendChild(link);
						link.click();
					});
					break;
				case 6:
					axiosConfig({
						url: '/data-system/almacenes/reporte',
						method: 'GET',
						params: {
							idCliente: values.client,
							idAlmacen: values.warehouse
						},
						responseType: 'blob'
					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute(
							'download',
							`Reporte-Inventario ${clientName} Almacen ${values.warehouse}.xlsx`
						);
						document.body.appendChild(link);
						link.click();
					});
					break;
				default:
					break;
			}
		}
	};

	const getStartDate = event => {
		setStartDate(event.target.value);
	};
	function renderModal() {
		if (showModal) {
			if (reportType === 6) {
				const newClients = Object.assign(clients, []);
				if (
					!newClients
						.map(x => {
							return x.value;
						})
						.includes('0')
				) {
					newClients.push({ display: 'Todos', value: '0' });
				}
				const newWarehouses = Object.assign(warehouses, []);
				if (
					!newWarehouses
						.map(x => {
							return x.value;
						})
						.includes('0')
				) {
					newWarehouses.push({ display: 'Todos', value: '0' });
				}
				return (
					<Modal>
						<WafoForm formId={'reportInfo'} onSubmit={getReport}>
							<WafoFormSelect
								name="client"
								defaultValue="Seleccione una Opcion"
								label="Cliente"
								options={newClients}
								validations={{
									required: {
										value: true,
										message: 'El campo Cliente es requerido'
									}
								}}
							/>
							<WafoFormSelect
								name="warehouse"
								defaultValue="Seleccione una Opcion"
								label="Almacen"
								options={warehouses}
								validations={{
									required: {
										value: true,
										message: 'El campo Almacen es requerido'
									}
								}}
							/>

							<div className="flex flex-row pl-3 justify-around mt-2">
								<button
									className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="ml-1 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									type="submit"
								>
									Confirmar
								</button>
							</div>
						</WafoForm>
					</Modal>
				);
			} else if (reportType !== 5) {
				return (
					<Modal>
						<WafoForm formId={'reportInfo'} onSubmit={getReport}>
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Fecha Inicial es requerido`
									}
								}}
								type="date"
								name="startDate"
								label="Fecha Inicial"
								onChangeCallback={getStartDate}
							/>
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Fecha Final es requerido`
									}
								}}
								type="date"
								name="endDate"
								label="Fecha Final"
								extraProps={{
									min: startDate
								}}
							/>
							{reportType === 1 || reportType === 2 ? (
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									options={clients}
									validations={{
										required: {
											value: true,
											message: 'El campo Cliente es requerido'
										}
									}}
								/>
							) : (
								''
							)}

							<div className="flex flex-row pl-3 justify-around mt-2">
								<button
									className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="ml-1 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									type="submit"
								>
									Confirmar
								</button>
							</div>
						</WafoForm>
					</Modal>
				);
			} else {
				return (
					<Modal>
						<WafoForm formId={'reportInfo'} onSubmit={getReport} locale="es">
							<WafoFormAutocomplete
								name="partNumber"
								label="Numero de Parte"
								placeholder="Seleccione un # de Parte"
								items={partNumbers}
								filterItems={items => items}
								renderInput={item => `${item.Numero_Parte}`}
								renderItem={item => <p className="autocomplete-item">{item.Numero_Parte}</p>}
								validations={{ required: true }}
								extraProps={{
									autoComplete: 'off'
								}}
								onQueryChange={value => setQuery(value)}
								handleChange
							/>

							<div className="flex flex-row pl-3 justify-around mt-2">
								<button
									className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="ml-1 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									type="submit"
								>
									Confirmar
								</button>
							</div>
						</WafoForm>
					</Modal>
				);
			}
		}
	}
	return (
		<div className="flex flex-col">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
				<p className="text-xl mt-2">Entradas</p>
			</div>
			<div className="bg-gray-100 shadow-md p-4">
				<button
					className="button w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(1);
						setShowModal(true);
					}}
				>
					Por Periodo
				</button>
			</div>
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
				<p className="text-xl mt-2">Salidas</p>
			</div>
			<div className="bg-gray-100 shadow-md p-4">
				<button
					className="button  w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(2);
						setShowModal(true);
					}}
				>
					Por Periodo
				</button>
				<button
					className="button  w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(3);
						setShowModal(true);
					}}
				>
					KC
				</button>
				<button
					className="button  w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(4);
						setShowModal(true);
					}}
				>
					Iconex
				</button>
			</div>
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
				<p className="text-xl mt-2"># de Parte</p>
			</div>
			<div className="bg-gray-100 shadow-md p-4">
				<button
					className="button  w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(5);
						setShowModal(true);
					}}
				>
					Generar reporte
				</button>
			</div>
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
				<p className="text-xl mt-2">Almacenes</p>
			</div>
			<div className="bg-gray-100 shadow-md p-4">
				<button
					className="button  w-48 mx-1 py-1 bg-green-600"
					onClick={() => {
						setReportType(6);
						setShowModal(true);
					}}
				>
					Generar reporte
				</button>
			</div>

			{renderModal()}
		</div>
	);
};

export default Reportes;
