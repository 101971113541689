import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt, FaCheckCircle, FaArrowCircleRight } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import moment from 'moment';

const ListPreEntradas = () => {
	const [totalRows, setTotalRows] = useState();
	const [preEntriesPerPage, setPreEntriesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [search, setSearch] = useState('');
	const titles = ['Pre Entrada', 'Fecha', 'Cliente', 'Bultos', 'Caja', 'Tracking', 'Opciones'];
	const [preEntries, setPreEntries] = useState([]);
	const [currentPreEntrie, setCurrentPreEntrie] = useState();
	const [showModal, setShowModal] = useState(false);
	const alert = useAlert();

	useEffect(() => {
		getPreEntries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, currentPage, preEntriesPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [preEntriesPerPage, search]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / preEntriesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, preEntriesPerPage]);

	const getPreEntries = async () => {
		let { count, rows } = await axiosConfig({
			method: 'get',
			url: '/data-system/entradas/preentradas',
			params: {
				limit: preEntriesPerPage,
				paginaActual: currentPage,
				search,
				deshabilitados: true
			}
		});
		if (count > 0) {
			setTotalRows(count);
		} else {
			setTotalRows(1);
		}
		setPreEntries(rows);
	};

	const disablePreEntrie = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Entradas/PreEntradas/Habilitar',
				params: {
					idPreEntrada: currentPreEntrie.Id_Pre_Entrada
				}
			});

			if (data === 'OK') {
				alert.show(
					`La Pre Entrada con Tracking: ${currentPreEntrie.Tracking} fue eliminado correctamente.`,
					{
						type: 'success'
					}
				);
				getPreEntries();
			}
		} catch (error) {
			alert.show(
				`Hubo un problema ${
					currentPreEntrie.Disabled === 1 ? 'habilitando' : 'deshabilitando'
				} la pre entrada.`,
				{
					type: 'error'
				}
			);
		}
		setShowModal(false);
	};

	const downloadPreEntrieDetails = async (preEntrieId, entrieNumber) => {
		const params = {
			idPreEntrada: +preEntrieId
		};
		axiosConfig({
			url: '/data-system/entradas/preentradas/reporte',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Pre Entrada:  ${entrieNumber}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	function renderModal() {
		if (showModal) {
			return (
				<Modal>
					<p className="text-xl text-black">
						­¿Desea {currentPreEntrie.Disabled === 1 ? 'Habilitar' : 'Deshabilitar'} esta Pre
						Entrada con Tracking {currentPreEntrie.Tracking}?:
					</p>
					<div className="flex flex-row mt-2">
						<button
							className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
							onClick={() => setShowModal(false)}
						>
							Cancelar
						</button>

						<button
							className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
							onClick={() => disablePreEntrie()}
						>
							Confirmar
						</button>
					</div>
				</Modal>
			);
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Pre Entradas</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar tracking, numero de caja"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={preEntriesPerPage}
							onChange={event => setPreEntriesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/PreEntradas/Nueva"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar PreEntrada</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{preEntries.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{preEntries.map(preEntrie => {
								const utcDate = moment.utc(preEntrie.Fecha).format();
								const localDate = moment(utcDate)
									.local()
									.format('YYYY-MM-DD');
								return (
									<tr key={preEntrie.Id_Pre_Entrada}>
										<td>
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadPreEntrieDetails(
														preEntrie.Id_Pre_Entrada,
														preEntrie.Numero_Entrada
													);
												}}
											>
												{preEntrie.Numero_Entrada}
											</button>
										</td>
										<td>{localDate}</td>
										<td>{preEntrie.preentrada_cliente.Nombre_Cliente}</td>
										<td>{preEntrie.Cantidad_Bultos}</td>
										<td>{preEntrie.Numero_Caja}</td>
										<td>{preEntrie.Tracking}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/PreEntradas/Editar',
														state: { preEntrieId: preEntrie.Id_Pre_Entrada }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
												<button
													className={`mt-1 lg:mt-0 lg:mx-1 w-8 h-8 button ${
														+preEntrie.Disabled === 1 ? 'bg-green-700' : 'bg-red-700'
													}`}
													onClick={() => {
														setCurrentPreEntrie(preEntrie);
														setShowModal(true);
													}}
												>
													{+preEntrie.Disabled === 1 ? <FaCheckCircle /> : <FaTrashAlt />}
												</button>
												<Link
													to={{
														pathname: '/Data-System/Entradas/Nueva',
														state: { preEntrieId: preEntrie.Id_Pre_Entrada }
													}}
												>
													<button className="button bg-indigo-700 w-8 h-8">
														<FaArrowCircleRight />
													</button>
												</Link>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListPreEntradas;
