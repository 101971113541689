import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaFile, FaEdit, FaCheckCircle, FaTrashAlt } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import moment from 'moment';
const ListSalidas = () => {
	const titles = [
		'Salida',
		'Fecha',
		'Cliente',
		'Factura',
		'Pedimento',
		'Referencia',
		'Habilitada/Deshabilitada',
		'Packing List',
		'Archivo',
		'Eliminar'
	];
	const [outputs, setOutputs] = useState([]);
	const [search, setSearch] = useState('');
	const [totalRows, setTotalRows] = useState();
	const [outputsPerParge, setOutputsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentOutput, setCurrentOutput] = useState({});
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState(0);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const alert = useAlert();

	useEffect(() => {
		setCurrentPage(1);
	}, [outputsPerParge, search]);

	useEffect(() => {
		getOutputs();
		getClients();
	}, [search, currentPage, outputsPerParge, client, startDate, endDate]);
	const getOutputs = async () => {
		const { result, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/salidas',
			params: {
				search: search,
				limit: outputsPerParge,
				paginaActual: currentPage,
				cliente: client,
				fechaInicio: startDate ? moment(startDate).toISOString() : undefined,
				fechaFinal: endDate ? moment(endDate).toISOString() : undefined
			}
		});
		if (result) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}
			setOutputs(result);
		}
	};
	const getClients = async () => {
		let { result: data } = await axiosConfig({
			method: 'get',
			url: '/data-system/clientes',
			params: {
				paginaActual: 1,
				limit: 100,
				deshabilitados: true
			}
		});
		setClients(data);
	};

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / outputsPerParge); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, outputsPerParge]);
	const downloadPackingList = async outputId => {
		axiosConfig({
			url: '/data-system/salidas/packinglist',
			method: 'GET',
			params: {
				Id_Salida: outputId
			},
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `packingList.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	const disableOutput = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Salidas/Deshabilitar',
				data: {
					Id_Salida: currentOutput.Id_Salida
				}
			});

			if (data === 'OK') {
				alert.show(`La Salida ${currentOutput.Salida} fue deshabilitada correctamente.`, {
					type: 'success'
				});
				getOutputs();
			}
		} catch (error) {
			alert.show('Hubo un problema deshabilitando la salida, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};
	const enableOutput = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Salidas/Habilitar',
				data: {
					Id_Salida: currentOutput.Id_Salida
				}
			});

			if (data === 'OK') {
				alert.show(`La Salida ${currentOutput.Salida} fue habilitada correctamente.`, {
					type: 'success'
				});
				getOutputs();
			}
		} catch (error) {
			alert.show('Hubo un problema habilitando la salida, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (+currentOutput.Disabled === 1) {
				return (
					<Modal>
						<p className="text-xl text-black">­¿Desea Habilitar Esta Salida?:</p>
						<p className="text-xl text-black">{`${currentOutput.Salida}${currentOutput.regimen_salida.Nombre_Regimen}`}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => enableOutput()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			} else {
				return (
					<Modal>
						<p className="text-xl text-black">­¿Desea Deshabilitar Esta Salida?:</p>
						<p className="text-xl text-black">{`${currentOutput.Salida}${currentOutput.regimen_salida.Nombre_Regimen}`}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => disableOutput()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			}
		}
	}

	const downloadOutputDetails = async (outputId, output) => {
		const params = {
			idSalida: +outputId
		};
		axiosConfig({
			url: '/data-system/salidas/reportes',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Salida ${output}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};
	const downloadOutputArchive = async (outputId, output) => {
		const params = {
			idSalida: +outputId
		};
		axiosConfig({
			url: '/data-system/salidas/archivo',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Salida ${output}.zip`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-col"></div>
				<div className="flex flex-col md:flex-row w-full items-center">
					<p className="text-xl mt-2">Salidas</p>
					<input
						className="ml-2 w-full bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar salida, factura, pedimento, referencia"
					/>

					<select
						className="mt-1 w-full md:w-1/3 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						onChange={event => setClient(event.target.value)}
						defaultValue="default"
					>
						<option value="default" disabled={true}>
							Seleccione Cliente
						</option>
						<option value="0">Todos</option>
						{clients.map(obj => {
							return (
								<option key={obj.Id_Cliente} value={obj.Id_Cliente}>
									{obj.Nombre_Cliente}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-col lg:flex-row w-full">
					<div className="flex flex-col md:flex-row w-full text-center">
						<p className="justify-center">Filtrar por fecha</p>
						<div className="flex flex-col sm:flex-row w-full justify-start">
							<label className="md:w-2/4 lg:w-1/4">
								<input
									className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
									id="search"
									type="date"
									value={startDate}
									onChange={event => setStartDate(event.target.value)}
								/>
							</label>
							<label className="md:w-2/4 lg:w-1/4 ml-1">
								<input
									className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
									id="search"
									type="date"
									value={endDate}
									min={startDate}
									onChange={event => setEndDate(event.target.value)}
								/>
							</label>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row">
						<div className="flex flex-row h-12">
							<div className="ml-4 flex flex-row py-1">
								<button
									className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
									onClick={() => {
										setCurrentPage(currentPage - 1);
									}}
									disabled={currentPage === 1 ? 'disabled' : ''}
								>
									&laquo;
								</button>
								<select
									value={currentPage}
									name="currentPage"
									id="currentPage"
									onChange={event => setCurrentPage(parseInt(event.target.value))}
									className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									{pageNumbers.map(p => {
										return (
											<option key={p} value={p}>
												{p}
											</option>
										);
									})}
								</select>
								<button
									className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
									onClick={() => {
										setCurrentPage(currentPage + 1);
									}}
									disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
								>
									&raquo;
								</button>
							</div>
							<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2 flex flex-row">
								<p className="mt-1">Mostar</p>
								<select
									value={outputsPerParge}
									onChange={event => setOutputsPerPage(parseInt(event.target.value))}
									className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
								</select>
							</label>
						</div>
						<Link
							className="mt-1"
							to="/Data-System/Salidas/Nueva"
							style={{ textDecoration: 'none' }}
						>
							<button className="button btn-lg bg-green-600 ml-3">Agregar Salida</button>
						</Link>
					</div>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{outputs.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{outputs.map(output => {
								return (
									<tr key={output.Salida}>
										<td>
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadOutputDetails(
														output.Id_Salida,
														`${output.Salida}${output.regimen_salida.Nombre_Regimen}`
													);
												}}
											>
												{`${output.Salida}${output.regimen_salida.Nombre_Regimen}`}
											</button>
										</td>
										<td>{output.Fecha}</td>
										<td>{output.orden_salida.orden_cliente.Nombre_Cliente}</td>
										<td>{output.Factura}</td>
										<td>{output.Pedimento}</td>
										<td>{output.Referencia}</td>
										<td>{+output.Disabled === 0 ? 'Habilitada' : 'Deshabilitada'}</td>
										<td>
											{+output.Packing_List === 1 ? (
												<button
													className="button btn-large bg-red-700 flex flex-row items-center h-10"
													onClick={() => downloadPackingList(output.Id_Salida)}
												>
													Descargar
													<FaFile className="ml-1" />
												</button>
											) : (
												'-'
											)}
										</td>
										<td>
											<button
												className="button btn-large bg-teal-700 flex flex-row items-center h-10"
												onClick={event => {
													event.preventDefault();
													downloadOutputArchive(
														output.Id_Salida,
														`${output.Salida}${output.regimen_salida.Nombre_Regimen}`
													);
												}}
											>
												Descargar
												<FaFile className="ml-1" />
											</button>
										</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Salidas/Editar',
														state: { outputId: output.Id_Salida }
													}}
												>
													<button className="button bg-blue-700 w-7 h-8">
														<FaEdit />
													</button>
												</Link>
												{+output.Disabled === 1 ? (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-green-700"
														onClick={() => {
															setCurrentOutput(output);
															setShowModal(true);
														}}
													>
														<FaCheckCircle />
													</button>
												) : (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
														onClick={() => {
															setCurrentOutput(output);
															setShowModal(true);
														}}
													>
														<FaTrashAlt />
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListSalidas;
