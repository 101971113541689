import React, { useState } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddUnidadMedidaForm = ({ onSubmitSuccess, onReturn }) => {
	const alert = useAlert();
	const submitUnit = async (form, values) => {
		if (form.valid) {
			try {
				const infoUnidad = {
					unidad: values.unit
				};
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Unidades/Medida/Nueva',
					data: infoUnidad
				});

				if (data === 'OK') {
					alert.show(`La Unidad ${values.unit} fue agregada correctamente.`, {
						type: 'success'
					});
					onSubmitSuccess();
				}
			} catch (error) {
				alert.show('Hubo un problema agregando la unidad, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Unidad de Medida</p>
				</div>
				<WafoForm formId="newUnitForm" onSubmit={submitUnit}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full pr-2">
							<WafoFormInput
								name="unit"
								label="Unidad de Medida"
								placeholder="Unidad"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Unidad de Medida es requerido'
									}
								}}
							/>
						</div>

						<div className="flex flex-row">
							<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
								Agregar Unidad
							</button>

							<button
								className="ml-2 button btn-lg bg-red-700 mt-3 mb-2"
								onClick={event => {
									event.preventDefault();
									onReturn();
								}}
							>
								Cancelar
							</button>
						</div>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddUnidadMedidaForm;
