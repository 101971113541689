import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListTransportistas from './ListTransportistas';
import AddTransportista from './AddTransportista';
import EditTransportista from './EditTransportista';
import PrivateRoute from '#components/router/PrivateRoute';

const Proveedores = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Transportistas" component={ListTransportistas} />
			<Route
				exact
				path="/Data-System/Catalogos/Transportistas/Nuevo"
				component={AddTransportista}
			/>
			<Route
				exact
				path="/Data-System/Catalogos/Transportistas/Editar"
				component={EditTransportista}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/Transportistas" />} />
		</Switch>
	);
};

export default Proveedores;
