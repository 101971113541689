import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormTextArea } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditEstado = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultState, setDefaultState] = useState();
	const alert = useAlert();
	const [selectedColor, setSelectedColor] = useState('#0413DC');

	useEffect(() => {
		const getDefaultState = async () => {
			const stateInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Estados/getEstado',
				params: { idEstado: state.stateId }
			});

			setDefaultState({
				stateId: stateInfo.Id_Estado,
				state: stateInfo.Estado,
				priority: stateInfo.Prioridad,
				description: stateInfo.Descripcion,
				color: stateInfo.Color
			});

			setSelectedColor(stateInfo.Color);
		};
		if (state) {
			getDefaultState();
		}
	}, [state]);

	const submitState = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Estados/Editar',
					data: {
						idEstado: values.stateId,
						estado: values.state,
						prioridad: values.priority,
						descripcion: values.description,
						color: values.color
					}
				});

				if (data === 'OK') {
					alert.show(`El estado ${values.state} fue editado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando el estado.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Estados" />;
	}
	if (state) {
		if (!defaultState) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Estado</p>
					</div>
					<WafoForm values={defaultState} formId="newProviderForm" onSubmit={submitState}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center pr-2 lg:pr-0">
							<div className="flex flex-col md:flex-row w-full">
								<WafoFormInput
									name="stateId"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="state"
									label="Estado"
									placeholder="Estado"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Estado es requerido'
										}
									}}
									extraProps={{
										maxLength: 20
									}}
								/>
								<WafoFormInput
									name="priority"
									label="Prioridad"
									placeholder="Prioridad"
									validations={{
										required: {
											value: true,
											message: 'El campo Prioridad es requerido'
										}
									}}
									extraProps={{
										min: 0,
										max: 100
									}}
									type="number"
								/>
								<WafoFormInput
									name="color"
									label="Color"
									placeholder="Color"
									validations={{
										required: {
											value: true,
											message: 'El campo Color es requerido'
										}
									}}
									value={selectedColor}
									onChangeCallback={e => {
										setSelectedColor(e.target.value);
									}}
									type="color"
								/>
								<button
									className={`button bg-blue-600 ml-2 mt-12 h-10`}
									style={{ backgroundColor: selectedColor }}
									onClick={e => {
										e.preventDefault();
									}}
								></button>
							</div>

							<div className="flex flex-col md:flex-row w-full">
								<WafoFormTextArea
									name="description"
									customClass="form-group-lg"
									placeholder="Descripcion."
									label="Descripcion"
									extraProps={{ rows: 3 }}
									validations={{
										required: {
											value: true,
											message: 'El campo Descripcion es requerido'
										}
									}}
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-2 mb-2">Editar Estado</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/Estados" />;
};

export default EditEstado;
