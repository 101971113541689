import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormTextArea } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddAlmacen = () => {
	const [submitted, setSubmitted] = useState(false);
	const alert = useAlert();

	const submitWarehouse = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Almacenes/Nuevo',
					data: {
						nombre: values.name,
						descripcion: values.description,
						direccion: values.address
					}
				});

				if (data === 'OK') {
					alert.show(`El Almacen ${values.warehouseId} fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema agregando el Almacen, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Almacenes" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Almacen</p>
				</div>
				<WafoForm formId="addWarehouseForm" onSubmit={submitWarehouse}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormInput
								name="name"
								label="Nombre Almacen"
								placeholder="Nombre Almacen"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Nombre Almacen es requerido'
									}
								}}
							/>
							<WafoFormInput
								name="address"
								label="Direccion"
								placeholder="Direccion"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Direccion es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormTextArea
								name="description"
								customClass="form-group-lg"
								placeholder="Descripcion."
								label="Descripcion"
								extraProps={{ rows: 3 }}
								validations={{
									required: {
										value: true,
										message: 'El campo Descripcion es requerido'
									}
								}}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
							Agregar Almacen
						</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddAlmacen;
