import React, { useEffect, useRef, useState } from 'react';
import { FaRegFileExcel, FaRegFileImage, FaRegFilePdf } from 'react-icons/fa';
const CustomMultipleFileInput = ({
	name,
	valid,
	touched,
	meta,
	handleInputChange,
	multiple = false
}) => {
	const [value, setValue] = useState('');
	const [files, setFiles] = useState([]);
	useEffect(() => {
		if (value) {
			let newFiles = Object.values(value);
			const prevFiles = Object.assign([], files);
			newFiles.map(x => {
				prevFiles.push(x);
			});

			setFiles(prevFiles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, name]);

	useEffect(() => {
		if (handleInputChange) {
			handleInputChange({
				name,
				value: files
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files, name]);

	const deleteFile = index => {
		const prevFiles = Object.assign([], files);
		prevFiles.splice(index, 1);
		setFiles(prevFiles);
	};

	return (
		<div className="ml-2 mt-2 w-full self-start">
			<p className="text-gray-700 text-lg">{meta.label}</p>

			<label>
				<p className="flex-grow form-control text-xl">Seleccionar Archivos</p>
				<input
					className={`flex-grow form-control hidden ${touched && !valid ? 'error' : ''}`}
					onChange={e => {
						setValue(e.target.files);
					}}
					placeholder={meta.label}
					name={name}
					type="file"
					accept={meta.accept}
					multiple={multiple}
				/>
			</label>
			<div>
				{!valid && touched ? (
					<p className="text-red-700 text-xs">El campo {meta.label} es requerido</p>
				) : null}
			</div>

			<div
				className={`mt-1 flex flex-row w-full bg-gray-200 pl-2 ${files.length > 0 ? 'py-1' : null}`}
			>
				{files.map((file, index) => {
					let imageRegex = new RegExp(/\bimage.*\b/);
					if (file && file.type === 'application/pdf') {
						return (
							<FaRegFilePdf
								key={index}
								className="text-3xl text-blue-600 hover:text-red-600"
								title={file.name}
								onClick={e => {
									e.preventDefault();
									deleteFile(index);
								}}
							/>
						);
					} else if (
						file &&
						file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					) {
						return (
							<FaRegFileExcel
								key={index}
								className="text-3xl text-blue-600 hover:text-red-600"
								title={file.name}
								onClick={e => {
									e.preventDefault();
									deleteFile(index);
								}}
							/>
						);
					} else if (file && imageRegex.test(file.type)) {
						return (
							<FaRegFileImage
								key={index}
								className="text-3xl text-blue-600 hover:text-red-600"
								title={file.name}
								onClick={e => {
									e.preventDefault();
									deleteFile(index);
								}}
							/>
						);
					}
				})}
			</div>
		</div>
	);
};
export default CustomMultipleFileInput;
