import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormTextArea, WafoFormSelect } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const RelacionAlertas = () => {
	const alert = useAlert();
	const titles = ['', 'Titulo', 'Descripcion', 'Condicion de Envio', 'Metodo de Envio'];
	const [clients, setClients] = useState([]);
	const [alerts, setAlerts] = useState([]);
	const [selectedAlerts, setSelectedAlerts] = useState([]);
	const [selectedClient, setSelectedClient] = useState([]);
	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Usuario) };
			});

			setClients(data);
		};

		getAlerts();
		getClients();
	}, []);

	const getAlerts = async () => {
		let { rows, count } = await axiosConfig({
			method: 'get',
			url: '/data-system/alertas'
		});
		setAlerts(rows);
	};

	const getAlertsRelationShips = async userId => {
		let result = await axiosConfig({
			method: 'get',
			url: '/data-system/alertas/relaciones',
			params: {
				idUsuario: userId
			}
		});

		if (result) {
			const newAlerts = result.map(x => {
				return { alertId: x.Id_Alerta };
			});
			setSelectedAlerts(newAlerts);
		}
	};
	const onClientChange = event => {
		setSelectedClient(Number(event.target.value));
		setSelectedAlerts([]);
		getAlertsRelationShips(Number(event.target.value));
	};

	const handleCheckBox = (isRowSelected, alertId) => {
		if (isRowSelected) {
			selectedAlerts.map((obj, index) => {
				if (obj.alertId === alertId) {
					selectedAlerts.splice(index, 1);
				}
			});
		} else {
			selectedAlerts.push({
				alertId
			});
		}

		getAlerts();
	};

	const saveAlertsRelationships = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'post',
				url: '/data-system/alertas/relaciones/guardar',
				data: {
					idUsuario: selectedClient,
					alertas: selectedAlerts.map(obj => {
						return {
							idAlerta: obj.alertId
						};
					})
				}
			});

			if (data === 'OK') {
				alert.show(`Se guardaron las alertas del cliente.`, {
					type: 'success'
				});
			}
		} catch (error) {
			alert.show('Hubo un problema agregando al cliente, intentelo mas tarde.', {
				type: 'error'
			});
		}
	};

	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Relacion Alertas y Usuarios</p>
				</div>

				<div className="flex flex-row justify-center">
					<div>
						<select
							className="mt-1 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="cliente"
							defaultValue="default"
							onChange={event => onClientChange(event)}
						>
							<option value="default" disabled={true}>
								Seleccione Cliente
							</option>
							{clients.map(obj => {
								return (
									<option key={obj.value} value={obj.value}>
										{obj.display}
									</option>
								);
							})}
						</select>
					</div>
					<div>
						<button
							className="ml-4 h-10 button btn-lg bg-green-600"
							onClick={e => {
								e.preventDefault();
								saveAlertsRelationships();
							}}
						>
							Guardar
						</button>
					</div>
				</div>

				<div className="overflow-y-auto">
					<table className="text-left mt-2 w-full border-collapse table">
						<thead>
							<tr>
								{titles.map((title, index) => (
									<th className="text-gray-dark" key={index}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{alerts.map((obj, index) => {
								let isRowSelected = false;

								selectedAlerts.map(x => {
									if (x.alertId === obj.Id_Alerta) {
										isRowSelected = true;
									}
								});

								let rowStyle = '';
								if (isRowSelected) {
									rowStyle = 'selected';
								}

								return (
									<tr className={rowStyle} key={index}>
										<td>
											<input
												id={`selectRow${obj.Id_Alerta}`}
												type="checkbox"
												onChange={event => {
													event.preventDefault();
													handleCheckBox(isRowSelected, obj.Id_Alerta);
												}}
												title={isRowSelected ? 'Deseleccionar' : 'Seleccionar'}
												checked={isRowSelected}
											/>
										</td>
										<td>{obj.Titulo}</td>
										<td>{obj.Descripcion}</td>
										<td>{obj.Condicion_Envio === 1 ? 'Entradas' : 'Salidas'}</td>
										<td>
											{obj.Metodo_Envio === 1
												? 'Correo'
												: obj.Metodo_Envio === 2
												? 'Notificacion'
												: 'Notificacion y Correo'}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default RelacionAlertas;
