import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddTransportistaForm } from '#forms';

const AddTransportista = () => {
	const history = useHistory();
	const onSubmitSuccess = () => {
		history.push('/data-system/catalogos/transportistas');
	};

	return <AddTransportistaForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />;
};

export default AddTransportista;
