import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditLocacion = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultLocation, setDefaultLocation] = useState({});
	const alert = useAlert();

	useEffect(() => {
		console.log(state);
		const getLocation = async () => {
			const locationInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Inventario/Cajas/Locaciones/getLocacion',
				params: { idLocacion: state.locationId }
			});

			setDefaultLocation({
				locationId: locationInfo.Id_Locacion,
				location: locationInfo.Locacion
			});
		};
		if (state) {
			getLocation();
		}
	}, [state]);

	const submitLocation = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Inventario/Cajas/Locaciones/Editar',
					data: {
						idLocacion: values.locationId,
						locacion: values.location
					}
				});
				if (data === 'OK') {
					alert.show(`La locacion ${values.location} fue editada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando la locacion.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Locaciones" />;
	}
	if (state) {
		if (!defaultLocation) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Locacion</p>
					</div>
					<WafoForm values={defaultLocation} formId="editProviderForm" onSubmit={submitLocation}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row pr-2 md:pr-0">
								<WafoFormInput
									name="locationId"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="location"
									label="Locacion"
									placeholder="Locacion"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Locacion es requerido'
										}
									}}
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-3 mb-2">Editar Locacion</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/Locaciones" />;
};

export default EditLocacion;
