import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt, FaCheckCircle } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import { getVerifiedToken } from '#utils/authService';

const ListAlmacenes = () => {
	const [totalRows, setTotalRows] = useState();
	const [warehousesPerPage, setWarehousesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [search, setSearch] = useState('');
	const titles = ['Almacen', 'Direccion', 'Descripcion', 'Opciones'];
	const [warehouses, setWarehouses] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentWarehouse, setCurrentWarehouse] = useState({});
	const alert = useAlert();
	const [userInfo, setUserInfo] = useState();

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
		getWarehouses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, currentPage, warehousesPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [warehousesPerPage, search]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / warehousesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, warehousesPerPage]);

	const getWarehouses = async () => {
		let { almacenes, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/almacenes',
			params: {
				limit: warehousesPerPage,
				paginaActual: currentPage,
				search,
				deshabilitados: true
			}
		});
		if (FilasTotales > 0) {
			setTotalRows(FilasTotales);
		} else {
			setTotalRows(1);
		}
		setWarehouses(almacenes);
	};
	const disableWarehouse = async () => {
		try {
			const { data, message } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Almacenes/Deshabilitar',
				params: {
					idAlmacen: currentWarehouse.Id_Almacen
				}
			});

			if (message) {
				alert.show('No puedes deshabilitar un almacen que contiene piezas.', {
					type: 'error'
				});
			} else if (data === 'OK') {
				alert.show(`El Almacen ${currentWarehouse.Id_Almacen} fue deshabilitado correctamente.`, {
					type: 'success'
				});
				getWarehouses();
			}
		} catch (error) {
			alert.show('Hubo un problema deshabilitando el almacen, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};
	const enableWarehouse = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Almacenes/Habilitar',
				params: {
					idAlmacen: currentWarehouse.Id_Almacen
				}
			});

			if (data === 'OK') {
				alert.show(`El Almacen ${currentWarehouse.Id_Almacen} fue habilitado correctamente.`, {
					type: 'success'
				});
				getWarehouses();
			}
		} catch (error) {
			alert.show('Hubo un problema habilitando el almacen, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (userInfo.Id_Tipo_Usuario === 3) {
				if (+currentWarehouse.Disabled === 1) {
					return (
						<Modal>
							<p className="text-xl text-black">­¿Desea Habilitar Este Almacen?:</p>
							<p className="text-xl text-black">{currentWarehouse.Id_Almacen}</p>
							<div className="flex flex-row mt-2">
								<button
									className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									onClick={() => enableWarehouse()}
								>
									Confirmar
								</button>
							</div>
						</Modal>
					);
				} else {
					return (
						<Modal>
							<p className="text-xl text-black">­¿Desea Deshabilitar Este Almacen?:</p>
							<p className="text-xl text-black">{currentWarehouse.Id_Almacen}</p>
							<div className="flex flex-row mt-2">
								<button
									className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									onClick={() => disableWarehouse()}
								>
									Confirmar
								</button>
							</div>
						</Modal>
					);
				}
			} else {
				setShowModal(false);
				alert.show('Solo los administradores pueden habilitar/deshabilitar almacenes.', {
					type: 'error'
				});
			}
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Almacenes</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar por direccion o descripcion"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={warehousesPerPage}
							onChange={event => setWarehousesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Almacenes/Nuevo"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Almacen</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{warehouses.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{warehouses.map(warehouse => {
								return (
									<tr key={warehouse.Id_Almacen}>
										<td>{warehouse.Nombre_Almacen}</td>
										<td>{warehouse.Direccion}</td>
										<td>{warehouse.Descripcion}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Almacenes/Editar',
														state: { warehouseId: warehouse.Id_Almacen }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
												{+warehouse.Disabled === 1 ? (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-green-700"
														onClick={() => {
															setCurrentWarehouse(warehouse);
															setShowModal(true);
														}}
													>
														<FaCheckCircle />
													</button>
												) : (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
														onClick={() => {
															setCurrentWarehouse(warehouse);
															setShowModal(true);
														}}
													>
														<FaTrashAlt />
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListAlmacenes;
