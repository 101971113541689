import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditProveedor = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultProvider, setDefaultProvider] = useState({});
	const alert = useAlert();

	useEffect(() => {
		const getProvider = async () => {
			const providerInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Proveedores/getProveedor',
				params: { idProveedor: state.providerId }
			});

			setDefaultProvider({
				idProveedor: providerInfo.Id_Proveedor,
				proveedor: providerInfo.Nombre_Proveedor
			});
		};
		if (state) {
			getProvider();
		}
	}, [state]);

	const submitProvider = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Proveedores/Editar',
					data: values
				});

				if (data === 'OK') {
					alert.show(`El Proveedor ${values.proveedor} fue editado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando al proveedor, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Proveedores" />;
	}
	if (state) {
		if (!defaultProvider) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Proveedor</p>
					</div>
					<WafoForm values={defaultProvider} formId="editProviderForm" onSubmit={submitProvider}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row pr-2 md:pr-0">
								<WafoFormInput
									name="idProveedor"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="proveedor"
									label="Nombre"
									placeholder="Nombre"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Nombre es requerido'
										}
									}}
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-3 mb-2">Editar Proveedor</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/Proveedores" />;
};

export default EditProveedor;
