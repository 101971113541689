import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListAlmacenes from './ListAlmacenes';
import AddAlmacen from './AddAlmacen';
import EditAlmacen from './EditAlmacen';
import PrivateRoute from '#components/router/PrivateRoute';
const Almacenes = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Almacenes" component={ListAlmacenes} />
			<Route exact path="/Data-System/Catalogos/Almacenes/Nuevo" component={AddAlmacen} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Almacenes/Editar"
				component={EditAlmacen}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Almacenes/Clientes" />} />
		</Switch>
	);
};

export default Almacenes;
