import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logout, getToken, getVerifiedToken } from '#utils/authService';
import { MdNotificationsActive, MdNotifications } from 'react-icons/md';
import { RiCloseCircleLine } from 'react-icons/ri';
import axiosConfig from '#utils/axiosConfig';
import moment from 'moment';

const Layout = ({ children }) => {
	const [showNotifications, setShowNotifications] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const route = window.location.pathname.split('/');
	const { cliente, usuario } = getToken(true);
	const capitalize = s => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	useEffect(() => {
		const getNotifications = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/alertas/notificaciones',
				params: {
					page: 1,
					limit: 100
				}
			});
			setNotifications(rows);
		};
		if (cliente) {
			getNotifications();
		}
		setShowNotifications(false);
	}, [children]);
	return (
		<div className="w-full h-max-full px-8 mb-3" style={{ height: '95vh' }}>
			{/* Notification Box */}
			{showNotifications ? (
				<div className=" w-1/4 h-32 absolute top-0 right-0 mt-20 mr-8 z-20 ">
					<div
						className={`flex flex-col justify-center rounded bg-gray-100 shadow-lg border border-gray-300 ${
							notifications.length >= 2 ? ' h-64' : 'h-32'
						}`}
					>
						<div className="flex flex-col max-h-full">
							<div className="flex flex-row justify-between mb-2">
								<Link
									style={{ textDecoration: 'none' }}
									to="/Inventario/Notificaciones"
									className="text-decoration-none text-xl hover:text-gray-800 text-gray-800 mt-1 px-4"
									onClick={() => setShowNotifications(false)}
								>
									Notificaciones
								</Link>
								<button
									className="text-decoration-none text-2xl text-red-700"
									onClick={event => {
										event.preventDefault();
										setShowNotifications(prev => {
											return !prev;
										});
									}}
								>
									<RiCloseCircleLine />
								</button>
							</div>

							<div className="overflow-y-auto max-h-full">
								{notifications.length > 0 ? (
									notifications.map(notification => {
										const utcDate = moment.utc(notification.Fecha).format();
										const localDate = moment(utcDate)
											.local()
											.format('YYYY-MM-DD hh:mm a');
										return (
											<div
												className="text-blue-700 px-4 py-3 border-t border-b border-gray-300"
												key={notification.Id_Notificacion}
											>
												<p className="text-sm">{notification.Texto}</p>
												<p className="text-xs">{localDate}</p>
											</div>
										);
									})
								) : (
									<div className="text-blue-700 px-4 py-3 border-t border-b border-gray-300">
										<p className="text-sm">No tienes nuevas notificaciones</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			) : null}

			<div className="shadow-md flex justify-between bg-gray-100 h-16 items-center mt-2">
				<Link
					style={{ textDecoration: 'none' }}
					to="/"
					className="text-decoration-none text-2xl hover:text-gray-800 text-gray-800 px-4"
				>
					Principal
				</Link>

				<Link
					style={{ textDecoration: 'none' }}
					to="/"
					className="text-decoration-none text-2xl hover:text-gray-800 text-gray-800 px-4"
					onClick={event => event.preventDefault()}
				>
					Global Ecex - {cliente ? cliente.Nombre_Cliente : 'Data System'}
				</Link>

				<div className="flex flex-row">
					{cliente ? (
						<button
							className="text-decoration-none text-2xl hover:text-gray-800 text-gray-800 px-4"
							onClick={event => {
								event.preventDefault();
								setShowNotifications(prev => {
									return !prev;
								});
							}}
						>
							{notifications.length > 0 ? (
								<MdNotificationsActive className="text-red-500 hover:text-red-600" />
							) : (
								<MdNotifications className="hover:text-gray-900 text-gray-700" />
							)}
						</button>
					) : null}
					<Link
						style={{ textDecoration: 'none' }}
						to="/"
						onClick={() => {
							logout();
						}}
						className="text-decoration-none text-2xl hover:text-gray-800 text-gray-800 px-4"
					>
						Salir
					</Link>
				</div>
			</div>

			<div className="shadow-md pl-2 flex bg-gray-100 h-10 items-center mt-2">
				{route.map((path, index) => {
					if (index > 0) {
						let direction = '';

						for (let j = 0; j <= index; j++) {
							direction += `${route[j]}`;
							if (j !== index) {
								direction += '/';
							}
						}

						path = path.toLowerCase();
						path = capitalize(path);
						return (
							<div key={index}>
								<Link to={direction}>
									<span className="text-base">{path}</span>
								</Link>
								<span className="text-base">{index + 1 === route.length ? '' : '/'}</span>
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
			{children}

			<div className="my-1" style={{ height: '1vh' }}></div>
		</div>
	);
};

export default Layout;
