import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
const ListInventario = () => {
	const titles = [
		'Entrada',
		'Almacen',
		'Fecha',
		'Numero_de_parte',
		'Cant. Piezas',
		'Cant. En Transito',
		'Bultos',
		'Bultos En Transito',
		'PesoNeto',
		'Locacion',
		'Descripcion Ing',
		'Descripcion Esp',
		'PO',
		'Pais',
		'Fraccion',
		'Marca',
		'Modelo',
		'Serie'
	];
	const [inventory, setInventory] = useState([]);
	const [totalRows, setTotalRows] = useState();
	const [partsPerPage, setPartsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [clients, setClients] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [formValues, setFormValues] = useState();

	useEffect(() => {
		const getWarehouses = async () => {
			let { almacenes } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});

			let data = almacenes.map(obj => {
				return { display: obj.Nombre_Almacen, value: String(obj.Id_Almacen) };
			});

			data.push({ display: `Todos`, value: '0' });

			setWarehouses(data);
		};
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		getWarehouses();
		getClients();
	}, []);
	useEffect(() => {
		setCurrentPage(1);
	}, [partsPerPage]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / partsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, partsPerPage]);

	useEffect(() => {
		if (formValues) {
			getInventory();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, partsPerPage]);

	useEffect(() => {
		getInventory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValues]);

	const getInventory = async () => {
		if (formValues) {
			const { search = '', client, daysInterval, warehouse } = formValues;

			const { result, FilasTotales } = await axiosConfig({
				method: 'get',
				url: '/data-system/inventario',
				params: {
					limit: partsPerPage,
					paginaActual: currentPage,
					idCliente: +client,
					intervaloDias: daysInterval,
					almacen: warehouse,
					search
				}
			});
			if (result) {
				if (FilasTotales > 0) {
					setTotalRows(FilasTotales);
				} else {
					setTotalRows(1);
				}
				setInventory(result);
			}
		}
	};

	const downloadInventoryReport = async () => {
		if (formValues) {
			const { search = '', client, daysInterval, warehouse } = formValues;
			const params = {
				idCliente: +client,
				intervaloDias: daysInterval,
				search,
				almacen: warehouse
			};

			const clientName =
				clients[
					clients
						.map(x => {
							return x.value;
						})
						.indexOf(client)
				].display;
			axiosConfig({
				url: '/data-system/inventario/reporte',
				method: 'GET',
				params,
				responseType: 'blob'
			}).then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `Reporte-Inventario ${clientName}.xlsx`);
				document.body.appendChild(link);
				link.click();
			});
		}
	};
	const searchInventory = (form, values) => {
		if (form.valid) {
			setFormValues(values);
		}
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col lg:flex-row items-center px-3 bg-gray-100 shadow-md pb-2">
				<WafoForm
					values={{ daysInterval: 90 }}
					formId={'searchInventory'}
					onSubmit={searchInventory}
				>
					<div className="flex flex-row items-center w-full">
						<div className="flex flex-row w-full h-24 w-full">
							<WafoFormInput
								label="Buscador"
								name="search"
								customClass="form-group-lg"
								type="text"
								placeholder="Buscar numero de parte"
							/>
							<WafoFormSelect
								name="client"
								defaultValue="Seleccione una Opcion"
								label="Cliente"
								customClass="form-group-lg"
								options={clients}
								validations={{
									required: {
										value: true,
										message: 'El campo Cliente es requerido'
									}
								}}
							/>
							<WafoFormSelect
								name="warehouse"
								defaultValue="Seleccione una Opcion"
								label="Almacen"
								customClass="form-group-lg"
								options={warehouses}
								validations={{
									required: {
										value: true,
										message: 'El campo Almacen es requerido'
									}
								}}
							/>

							<WafoFormInput
								label="Intervalo"
								name="daysInterval"
								customClass="form-group-lg"
								type="number"
								placeholder="Intervalo de Dias"
								validations={{
									required: {
										value: true,
										message: 'El campo Intervalo es requerido'
									}
								}}
								extraProps={{
									min: 0
								}}
							/>
						</div>
						<button className="button bg-green-600 ml-3 mt-10 w-64 h-8" type="submit">
							Mostrar Inventario
						</button>
						<button
							className="button bg-green-600 ml-3 mt-10 w-64 h-8"
							onClick={event => {
								event.preventDefault();
								downloadInventoryReport();
							}}
						>
							Descargar
						</button>
					</div>
				</WafoForm>
			</div>

			<div className="flex flex-col lg:flex-row items-center px-3 bg-gray-100 mt-2 shadow-md">
				<div className="flex flex-row">
					<p className="text-xl py-3 mt-2">Inventario</p>
					<div className="ml-4 flex flex-row py-4">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
					<label className="ml-2 mt-3 py-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={partsPerPage}
							onChange={event => setPartsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-4"
						to="/Data-System/Inventario/Cajas"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Inventario Cajas</button>
					</Link>
					<Link
						className="mt-4"
						to="/Data-System/Inventario/Movimientos"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Mover Inventario</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{inventory.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{inventory.map(
								(
									{
										Peso_Neto,
										Locacion,
										PO,
										Fraccion,
										partida_entrada,
										partida_inventario,
										numero_parte,
										unidad_partida,
										pais_partida
									},
									index
								) => {
									const warehouseIndex = warehouses
										.map(x => {
											return x.value;
										})
										.indexOf(String(partida_inventario.Id_Almacen));

									const warehouseName = warehouses[warehouseIndex].display;
									return (
										<tr key={index}>
											<td>{partida_entrada.Entrada}</td>
											<td>{warehouseName}</td>
											<td>{partida_entrada.Fecha}</td>
											<td>{numero_parte.Numero_Parte}</td>
											<td>{`${partida_inventario.Cantidad_Piezas}  ${unidad_partida.Unidad_Medida}`}</td>
											<td>{`${partida_inventario.Piezas_Transito}  ${unidad_partida.Unidad_Medida}`}</td>
											<td>{partida_inventario.Cantidad_Bultos}</td>
											<td>{partida_inventario.Bultos_Transito}</td>
											<td>{Peso_Neto}</td>
											<td>{Locacion}</td>
											<td>{numero_parte.Descripcion_Ing}</td>
											<td>{numero_parte.Descripcion_Esp}</td>
											<td>{PO}</td>
											<td>{`${pais_partida.Nombre_Esp}-${pais_partida.Alfa3}`}</td>
											<td>{Fraccion}</td>
											<td>{numero_parte.Marca}</td>
											<td>{numero_parte.Modelo}</td>
											<td>{numero_parte.Serie}</td>
										</tr>
									);
								}
							)}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default ListInventario;
