import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

const ListPaises = () => {
	const [totalRows, setTotalRows] = useState();
	const [countriesPerPage, setCountriesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [search, setSearch] = useState('');
	const titles = ['Pais Esp', 'Pais Ing', 'Alfa3', 'Alfa2', 'Opciones'];
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		getCountries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, currentPage, countriesPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [countriesPerPage, search]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / countriesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, countriesPerPage]);

	const getCountries = async () => {
		let { paises, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/paises',
			params: {
				limit: countriesPerPage,
				paginaActual: currentPage,
				search,
				deshabilitados: true
			}
		});
		if (FilasTotales > 0) {
			setTotalRows(FilasTotales);
		} else {
			setTotalRows(1);
		}
		setCountries(paises);
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Paises</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar pais"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={countriesPerPage}
							onChange={event => setCountriesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Paises/Nuevo"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Pais</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{countries.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{countries.map(country => {
								return (
									<tr key={country.Id_Pais}>
										<td>{country.Nombre_Esp}</td>
										<td>{country.Nombre_Ing}</td>
										<td>{country.Alfa3}</td>
										<td>{country.Alfa2}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Paises/Editar',
														state: { countryId: country.Id_Pais }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default ListPaises;
