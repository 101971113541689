import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import AddClienteForm from '#forms/formClientes/AddCliente';

const AddCliente = () => {
	const history = useHistory();
	const onSubmitSuccess = () => {
		history.push('/data-system/catalogos/clientes');
	};

	return <AddClienteForm onSubmitSuccess={onSubmitSuccess} />;
};

export default AddCliente;
