import React from 'react';
import './App.css';
import Login from '#scenes/Login';
import Inventario from '#inventory/Inventario';
import PrivateRoute from '#components/router/PrivateRoute';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import DataSystem from '#scenes/data-system/Data-System';

function App() {
	document.title = 'Ecex - Inventarios';
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Login} />
				<PrivateRoute path="/Inventario" component={Inventario} allowed={[1]} />
				<PrivateRoute path="/Data-System" component={DataSystem} allowed={[2, 3]} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
