import React from 'react';
import MenuCard from '#components/MenuCard';
import {
	FaTruckMoving,
	FaCubes,
	FaShoppingCart,
	FaClipboardList,
	FaSignOutAlt,
	FaWarehouse,
	FaShippingFast
} from 'react-icons/fa';
import { TiChartPie } from 'react-icons/ti';

const Main = () => {
	return (
		<div className="w-full px-32">
			<div className="flex flex-col lg:flex-row justify-center">
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Entradas" url="/Inventario/Entradas">
						<FaTruckMoving />
					</MenuCard>
					<MenuCard title="Inventario" url="/Inventario/Detalles">
						<FaCubes />
					</MenuCard>
				</div>
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Capacidad" url="/Inventario/Capacidad">
						<TiChartPie />
					</MenuCard>
					<MenuCard title="Orden carga +" url="/Inventario/OrdenCargaPlus">
						<FaShoppingCart />
					</MenuCard>
				</div>
			</div>
			<div className="flex flex-col lg:flex-row justify-center">
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Ordenes carga" url="/Inventario/Ordenes">
						<FaClipboardList />
					</MenuCard>
					<MenuCard title="Salidas" url="/Inventario/Salidas">
						<FaSignOutAlt />
					</MenuCard>
				</div>
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Cajas" url="/Inventario/Cajas">
						<FaWarehouse />
					</MenuCard>
					<MenuCard title="Logistica" url="/Inventario/Logistica">
						<FaShippingFast />
					</MenuCard>
				</div>
			</div>
		</div>
	);
};

export default Main;
