import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddUsuario = () => {
	const [submitted, setSubmitted] = useState(false);
	const [userTypes, setUserTypes] = useState([]);
	const alert = useAlert();

	useEffect(() => {
		const getUserTypes = async () => {
			let data = await axiosConfig({
				method: 'get',
				url: '/Data-System/Usuarios/Tipos'
			});

			data = data.map(obj => {
				return { display: obj.Tipo_Usuario, value: String(obj.Id_Tipo_Usuario) };
			});

			setUserTypes(data);
		};
		getUserTypes();
	}, []);
	const submitUser = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Usuarios/Nuevo',
					data: {
						username: values.username,
						password: values.password,
						tipoUsuario: values.userType
					}
				});

				if (data === 'OK') {
					alert.show(`El Usuario ${values.username} fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				if (error === 'Usuarios/UsuarioExists') {
					alert.show('El nombre de usuario ya existe, intente uno diferente.', {
						type: 'error'
					});
				} else {
					alert.show('Hubo un problema agregando al usuario, intentelo mas tarde.', {
						type: 'error'
					});
				}
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Usuarios" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Usuario</p>
				</div>
				<WafoForm formId="newUserForm" onSubmit={submitUser}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full pr-2">
							{' '}
							<WafoFormInput
								name="username"
								label="Usuario"
								placeholder="Usuario"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Usuario es requerido'
									}
								}}
							/>
							<WafoFormInput
								name="password"
								label="Contraseña"
								placeholder="Contraseña"
								type="password"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Contraseña es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full pr-2">
							<WafoFormSelect
								name="userType"
								label="Tipo Usuario"
								placeholder="Tipo Usuario"
								defaultValue="Seleccione una Opcion"
								options={userTypes}
								validations={{
									required: {
										value: true,
										message: 'El campo Tipo Usuario es requerido'
									}
								}}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-3 mb-2">Agregar Usuario</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddUsuario;
