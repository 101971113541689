import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListAlertas from './ListAlertas';
import AddAlerta from './AddAlerta';
import EditAlerta from './EditAlerta';
import RelacionAlertas from './RelacionAlertas';
import PrivateRoute from '#components/router/PrivateRoute';
const Almacenes = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Alertas" component={ListAlertas} />
			<Route exact path="/Data-System/Catalogos/Alertas/Nueva" component={AddAlerta} />
			<Route exact path="/Data-System/Catalogos/Alertas/Relacion" component={RelacionAlertas} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Alertas/Editar"
				component={EditAlerta}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Alertas" />} />
		</Switch>
	);
};

export default Almacenes;
