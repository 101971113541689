import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Bar } from 'react-chartjs-2';

const Tiempo = () => {
	const titles = ['Area', 'Promedio en Minutos', 'Promedio en Horas', 'Promedio en Dias'];
	const chartLabels = [
		'Pre Entradas a Entradas',
		'Entradas a Ordenes',
		'Ordenes a Salidas',
		'Flujo Completo'
	];
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [clientsData, setMetricsData] = useState([]);
	const [chartData, setChartData] = useState();
	const alert = useAlert();

	useEffect(() => {
		const getTimeMetrics = async () => {
			let result = await axiosConfig({
				method: 'get',
				url: '/data-system/metricas/tiempo',
				params: {
					fechaInicio: startDate,
					fechaFinal: endDate
				}
			});

			setMetricsData(result);

			let chartData = {
				labels: chartLabels.map(x => {
					return x;
				}),
				datasets: [
					{
						label: 'Promedio en Minutos',
						backgroundColor: '#9b59b6',
						hoverBackgroundColor: '#8e44ad',
						data: result.map(x => {
							return x.promedioMinutos;
						})
					},
					{
						label: 'Promedio en Horas',
						backgroundColor: '#2ecc71',
						hoverBackgroundColor: '#27ae60',
						data: result.map(x => {
							return x.promedioHoras;
						})
					},
					{
						label: 'Promedio en Dias',
						backgroundColor: '#3498db',
						hoverBackgroundColor: '#2980b9',
						data: result.map(x => {
							return x.promedioDias;
						})
					}
				]
			};
			setChartData(chartData);
		};

		if (startDate && endDate) {
			getTimeMetrics();
		}
	}, [startDate, endDate]);

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md justify-between">
				<div className="flex flex-col md:flex-row w-full text-center">
					<p className="justify-center">Filtrar por fecha</p>
					<div className="flex flex-col sm:flex-row w-full justify-start">
						<label className="md:w-2/4 w-full">
							<input
								className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
								id="search"
								type="date"
								onChange={event => setStartDate(event.target.value)}
							/>
						</label>
						<label className="md:w-2/4 w-full ml-1">
							<input
								className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
								id="search"
								type="date"
								min={startDate}
								onChange={event => setEndDate(event.target.value)}
							/>
						</label>
					</div>
				</div>
			</div>
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md justify-between mt-3">
				<p className="text-xl mt-2 w-full">Metricas Tiempo Procesos</p>
			</div>
			<div className="flex flex-col m-w-full justify-between mt-1">
				<div className="bg-gray-100 shadow-md mr-4 w-full self-start">
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{clientsData.map((metrics, index) => {
								return (
									<tr key={index}>
										<td>{metrics.zona}</td>
										<td>{metrics.promedioMinutos}</td>
										<td>{metrics.promedioHoras}</td>
										<td>{metrics.promedioDias}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="bg-gray-100 shadow-md mr-4 mt-2 w-full self-start">
					{chartData ? <Bar data={chartData} height={100} /> : null}
				</div>
			</div>
		</div>
	);
};

export default Tiempo;
