import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Rendimiento from './Rendimiento';
import Tiempo from './Tiempo';
const Metricas = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Metricas/Rendimiento" component={Rendimiento} />
			<Route exact path="/Data-System/Metricas/Tiempo" component={Tiempo} />
			<Route render={() => <Redirect to="/Data-System/Metricas/Rendimiento" />} />
		</Switch>
	);
};

export default Metricas;
