import React, { useState } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddLocacion = () => {
	const [submitted, setSubmitted] = useState(false);
	const alert = useAlert();
	const submitLocation = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Inventario/Cajas/Locaciones/Nueva',
					data: {
						locacion: values.location
					}
				});

				if (data === 'OK') {
					alert.show(`La locacion ${values.location} fue agregada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema agregando la locacion.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Locaciones" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Locacion</p>
				</div>
				<WafoForm formId="newProviderForm" onSubmit={submitLocation}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center pr-2 lg:pr-0">
						<WafoFormInput
							name="location"
							label="Locacion"
							placeholder="Locacion"
							customClass="form-group-lg"
							validations={{
								required: {
									value: true,
									message: 'El campo Locacion es requerido'
								}
							}}
						/>

						<button className="button btn-lg bg-green-700 mt-2 mb-2">Agregar Locacion</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddLocacion;
