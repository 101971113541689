import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt, FaCheckCircle } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import Modal from '#components/Modal';
import { getVerifiedToken } from '#utils/authService';

const ListRegimenes = () => {
	const [totalRows, setTotalRows] = useState();
	const [regimesPerPage, setRegimesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [search, setSearch] = useState('');
	const titles = ['Regimen', 'Dias', 'Opciones'];
	const [regimes, setRegimes] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentRegime, setCurrentRegime] = useState({});
	const alert = useAlert();
	const [userInfo, setUserInfo] = useState();

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
		getRegimes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, currentPage, regimesPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [regimesPerPage, search]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / regimesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, regimesPerPage]);

	const getRegimes = async () => {
		let { count, rows } = await axiosConfig({
			method: 'get',
			url: '/data-system/regimenes',
			params: {
				limit: regimesPerPage,
				page: currentPage,
				deshabilitados: true
			}
		});
		if (count > 0) {
			setTotalRows(count);
		} else {
			setTotalRows(1);
		}
		setRegimes(rows);
	};

	const disableRegime = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Regimenes/Habilitar',
				params: {
					idRegimen: currentRegime.Id_Regimen
				}
			});

			if (data === 'OK') {
				alert.show(`El Regimen ${currentRegime.Nombre_Regimen} fue deshabilitado correctamente.`, {
					type: 'success'
				});
				getRegimes();
			}
		} catch (error) {
			alert.show('Hubo un problema al deshabilitar el regimen.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (userInfo.Id_Tipo_Usuario === 3) {
				return (
					<Modal>
						<p className="text-xl text-black">
							­¿Desea {currentRegime.Disabled === 1 ? 'Habilitar' : 'Deshabilitar'} Este Regimen?:
						</p>
						<p className="text-xl text-black">{currentRegime.Nombre_Regimen}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => disableRegime()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			} else {
				setShowModal(false);
				alert.show('Solo los administradores pueden habilitar/deshabilitar regimenes.', {
					type: 'error'
				});
			}
		}
	}
	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Regimenes</p>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={regimesPerPage}
							onChange={event => setRegimesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Regimenes/Nuevo"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Regimen</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{regimes.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{regimes.map(regime => {
								return (
									<tr key={regime.Id_Regimen}>
										<td>{regime.Nombre_Regimen}</td>
										<td>{regime.Dias}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Regimenes/Editar',
														state: { regimeId: regime.Id_Regimen }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
												<button
													className={`mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button ${
														+regime.Disabled === 1 ? 'bg-green-700' : 'bg-red-700'
													}`}
													onClick={() => {
														setCurrentRegime(regime);
														setShowModal(true);
													}}
												>
													{+regime.Disabled === 1 ? <FaCheckCircle /> : <FaTrashAlt />}
												</button>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListRegimenes;
