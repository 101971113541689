import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './main.css';
import './tailwind.css';
import App from './App';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';
import * as serviceWorker from './serviceWorker';
import CustomAlert from '#components/alerts/CustomAlert';
const alertOptions = {
	position: positions.TOP_RIGHT,
	timeout: 5000,
	transition: transitions.FADE
};

ReactDOM.render(
	<AlertProvider template={CustomAlert} {...alertOptions}>
		<App />
	</AlertProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
