import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListSalidas from './ListSalidas';
import AddSalida from './AddSalida';
import EditSalida from './EditSalida';
import PrivateRoute from '#components/router/PrivateRoute';
const Salidas = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Salidas" component={ListSalidas} />
			<Route exact path="/Data-System/Salidas/Nueva" component={AddSalida} />
			<PrivateRoute exact path="/Data-System/Salidas/Editar" component={EditSalida} allowed={[3]} />
			<Route render={() => <Redirect to="/Data-System/Salidas" />} />
		</Switch>
	);
};

export default Salidas;
