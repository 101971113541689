import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListUnidadesMedida from './ListUnidadesMedida';
import AddUnidadMedida from './AddUnidadMedida';
import EditUnidadMedida from './EditUnidadMedida';
import PrivateRoute from '#components/router/PrivateRoute';

const UnidadesMedida = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/UnidadesMedida" component={ListUnidadesMedida} />
			<Route exact path="/Data-System/Catalogos/UnidadesMedida/Nueva" component={AddUnidadMedida} />
			<Route
				exact
				path="/Data-System/Catalogos/UnidadesMedida/Editar"
				component={EditUnidadMedida}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/UnidadesMedida" />} />
		</Switch>
	);
};

export default UnidadesMedida;
