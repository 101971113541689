import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormTextArea, WafoFormSelect } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditAlerta = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultAlert, setDefaultAlert] = useState({});
	const alert = useAlert();
	const conditionOptions = [
		{ display: 'Entradas', value: '1' },
		{ display: 'Salidas', value: '2' },
		{ display: 'Regimenes', value: '3' }
	];
	const methodOptions = [
		{ display: 'Correo', value: '1' },
		{ display: 'Notificacion', value: '2' },
		{ display: 'Ambas', value: '3' }
	];

	useEffect(() => {
		const getAlert = async () => {
			const alertInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Alertas/getAlerta',
				params: { idAlerta: state.alertId }
			});

			setDefaultAlert({
				id: alertInfo.Id_Alerta,
				title: alertInfo.Titulo,
				description: alertInfo.Descripcion,
				condition: alertInfo.Condicion_Envio,
				method: alertInfo.Metodo_Envio
			});
		};
		if (state) {
			getAlert();
		}
	}, []);
	const submitAlert = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Alertas/Editar',
					data: {
						idAlerta: values.id,
						titulo: values.title,
						descripcion: values.description,
						condicion: values.condition,
						metodo: values.method
					}
				});

				if (data === 'OK') {
					alert.show(`La Alerta ${values.title} fue editada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando la Alerta.', {
					type: 'error'
				});
			}
		}
	};

	if (!state || submitted) {
		return <Redirect to="/Data-System/Catalogos/Alertas" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Alerta</p>
				</div>
				<WafoForm formId="addWarehouseForm" values={defaultAlert} onSubmit={submitAlert}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormInput
								name="id"
								label="Id"
								placeholder="Id"
								extraProps={{
									disabled: 'true'
								}}
							/>
							<WafoFormInput
								name="title"
								label="Titulo"
								placeholder="Titulo"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Titulo es requerido'
									}
								}}
							/>
							<WafoFormTextArea
								name="description"
								customClass="form-group-lg"
								placeholder="Descripcion."
								label="Descripcion"
								extraProps={{ rows: 3 }}
								validations={{
									required: {
										value: true,
										message: 'El campo Descripcion es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormSelect
								name="condition"
								label="Condicion de Envio"
								placeholder="Condicion de Envio"
								defaultValue="Seleccione una Opcion"
								options={conditionOptions}
								validations={{
									required: {
										value: true,
										message: 'El campo Condicion de Envio es requerido'
									}
								}}
							/>
							<WafoFormSelect
								name="method"
								label="Metodo de Envio"
								placeholder="Metodo de Envio"
								defaultValue="Seleccione una Opcion"
								options={methodOptions}
								validations={{
									required: {
										value: true,
										message: 'El campo Metodo de Envio es requerido'
									}
								}}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
							Editar Alerta
						</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default EditAlerta;
