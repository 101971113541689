import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListPreEntradas from './ListPreEntradas';
import AddPreEntrada from './AddPreEntrada';
import EditPreEntrada from './EditPreEntrada';

const PreEntradas = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/PreEntradas" component={ListPreEntradas} />
			<Route exact path="/Data-System/PreEntradas/Nueva" component={AddPreEntrada} />
			<Route exact path="/Data-System/PreEntradas/Editar" component={EditPreEntrada} />
			<Route render={() => <Redirect to="/Data-System/PreEntradas" />} />
		</Switch>
	);
};

export default PreEntradas;
