import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaCheckCircle, FaFilePdf, FaTrashAlt, FaEdit } from 'react-icons/fa';
import Modal from '#components/Modal';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { getVerifiedToken } from '#utils/authService';
const ListEntradas = () => {
	const titles = [
		'Entrada',
		'Fecha',
		'Dias',
		'Cliente',
		'Tracking',
		'Packing List',
		'Enviada',
		'Terminada',
		'Balance',
		'Habilitada/Deshabilitada',
		'Opciones'
	];

	const [entries, setEntries] = useState([]);
	const [search, setSearch] = useState('');
	const [totalRows, setTotalRows] = useState();
	const [entriesPerPage, setEntriesPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentEntrie, setCurrentEntrie] = useState({});
	const [showFinishedEntries, setShowFinishedEntries] = useState(0);
	const alert = useAlert();
	const [userInfo, setUserInfo] = useState();

	useEffect(() => {
		setCurrentPage(1);
	}, [entriesPerPage, search, showFinishedEntries]);

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
		getEntries();
	}, [search, currentPage, entriesPerPage, showFinishedEntries]);

	const getEntries = async () => {
		let params = {
			search: search,
			limit: entriesPerPage,
			paginaActual: currentPage
		};

		if (showFinishedEntries === 1) {
			params = {
				...params,
				terminadas: true
			};
		}
		const { result, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/entradas',
			params
		});
		if (result) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}
			setEntries(result);
		}
	};

	const downloadPackingList = async (entrieId, entrie) => {
		axiosConfig({
			url: '/data-system/entradas/packinglist',
			method: 'GET',
			params: {
				Id_Entrada: entrieId
			},
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `PackingList Entrada ${entrie}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};
	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / entriesPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, entriesPerPage]);

	const disableEntrie = async () => {
		try {
			const { data, message } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Entradas/Deshabilitar',
				data: {
					Id_Entrada: currentEntrie.Id_Entrada
				}
			});

			if (message) {
				alert.show(
					'No puedes deshabilitar esta entrada porque ya esta siendo usada en una orden de carga.',
					{
						type: 'error'
					}
				);
			} else if (data === 'OK') {
				alert.show(`La Entrada ${currentEntrie.Entrada} fue deshabilitada correctamente.`, {
					type: 'success'
				});
				getEntries();
			}
		} catch (error) {
			alert.show('Hubo un problema deshabilitando la entrada, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};
	const enableEntrie = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Entradas/Habilitar',
				data: {
					Id_Entrada: currentEntrie.Id_Entrada
				}
			});

			if (data === 'OK') {
				alert.show(`La Entrada ${currentEntrie.Entrada} fue habilitada correctamente.`, {
					type: 'success'
				});
				getEntries();
			}
		} catch (error) {
			alert.show('Hubo un problema habilitando la entrada, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (userInfo.Id_Tipo_Usuario === 3) {
				if (+currentEntrie.Disabled === 1) {
					return (
						<Modal>
							<p className="text-xl text-black">­¿Desea Habilitar Esta Entrada?:</p>
							<p className="text-xl text-black">{currentEntrie.Entrada}</p>
							<div className="flex flex-row mt-2">
								<button
									className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									onClick={() => enableEntrie()}
								>
									Confirmar
								</button>
							</div>
						</Modal>
					);
				} else {
					return (
						<Modal>
							<p className="text-xl text-black">­¿Desea Deshabilitar Esta Entrada?:</p>
							<p className="text-xl text-black">{currentEntrie.Entrada}</p>
							<div className="flex flex-row mt-2">
								<button
									className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
									onClick={() => setShowModal(false)}
								>
									Cancelar
								</button>

								<button
									className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
									onClick={() => disableEntrie()}
								>
									Confirmar
								</button>
							</div>
						</Modal>
					);
				}
			} else {
				setShowModal(false);
				alert.show('Solo los administradores pueden habilitar/deshabilitar entradas.', {
					type: 'error'
				});
			}
		}
	}

	const downloadEntrieDetails = async (entrieId, entrie) => {
		const params = {
			idEntrada: +entrieId
		};
		axiosConfig({
			url: '/data-system/entradas/reportes',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Entrada ${entrie}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col lg:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Entradas</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar entrada, referencia o tracking"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={entriesPerPage}
							onChange={event => setEntriesPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Terminadas
						<select
							value={showFinishedEntries}
							onChange={event => setShowFinishedEntries(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</label>
					<Link className="mt-1" to="/Data-System/PreEntradas" style={{ textDecoration: 'none' }}>
						<button className="button btn-lg bg-green-600 ml-3">Pre Entradas</button>
					</Link>
					<Link
						className="mt-1"
						to="/Data-System/Entradas/Nueva"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Entrada</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{entries.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{entries.map(entrie => {
								let entrieState = {};
								entrie.partida_entrada.map(departure => {
									if (!entrieState.Prioridad) {
										entrieState = departure.estado_partida;
									} else if (departure.estado_partida.Prioridad > entrieState.Prioridad) {
										entrieState = departure.estado_partida;
									}
								});
								return (
									<tr key={entrie.Entrada}>
										<td className="flex flex-col">
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadEntrieDetails(entrie.Id_Entrada, entrie.Entrada);
												}}
											>
												{entrie.Entrada}
											</button>
											<button
												className={`button bg-blue-600 w-full`}
												style={{ backgroundColor: entrieState.Color }}
												onClick={e => {
													e.preventDefault();
												}}
											>
												{entrieState.Estado}
											</button>
										</td>
										<td>{entrie.Fecha}</td>
										<td>{entrie.Dias}</td>
										<td>{entrie.entrada_cliente.Nombre_Cliente}</td>
										<td>{entrie.Tracking}</td>
										<td>
											{+entrie.Packing_List === 1 ? (
												<button
													className="button btn-large bg-red-700 flex flex-row items-center h-10"
													onClick={() => downloadPackingList(entrie.Id_Entrada, entrie.Entrada)}
												>
													Descargar
													<FaFilePdf className="ml-1" />
												</button>
											) : (
												'-'
											)}
										</td>
										<td>
											{entrie.Enviada === 1 ? <FaCheckCircle style={{ color: 'green' }} /> : '-'}
										</td>
										<td>
											{entrie.Terminada === 1 ? <FaCheckCircle style={{ color: 'green' }} /> : '-'}
										</td>
										<td>N/A</td>
										<td>{+entrie.Disabled === 0 ? 'Habilitada' : 'Deshabilitada'}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Entradas/Editar',
														state: { entrieId: entrie.Id_Entrada }
													}}
												>
													<button className="button bg-blue-700 w-7 h-8">
														<FaEdit />
													</button>
												</Link>
												{+entrie.Disabled === 1 ? (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-green-700"
														onClick={() => {
															setCurrentEntrie(entrie);
															setShowModal(true);
														}}
													>
														<FaCheckCircle />
													</button>
												) : (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
														onClick={() => {
															setCurrentEntrie(entrie);
															setShowModal(true);
														}}
													>
														<FaTrashAlt />
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListEntradas;
