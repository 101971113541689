import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';

const EditPreEntrada = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [defaultPreEntrie, setDefaultPreEntrie] = useState({});

	useEffect(() => {
		const getPreEntrie = async () => {
			const preEntrieInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Entradas/PreEntradas/getPreEntrada',
				params: { idPreEntrada: state.preEntrieId }
			});

			setDefaultPreEntrie({
				preEntrieId: preEntrieInfo.Id_Pre_Entrada,
				date: moment.utc(preEntrieInfo.Fecha).format('YYYY-MM-DD'),
				lumpsQuantity: preEntrieInfo.Cantidad_Bultos,
				client: String(preEntrieInfo.Id_Cliente),
				box: preEntrieInfo.Numero_Caja,
				tracking: preEntrieInfo.Tracking
			});
		};

		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};

		if (state) {
			getPreEntrie();
			getClients();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const submitPreEntrie = async (form, values) => {
		if (form.valid) {
			try {
				const { message } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Entradas/PreEntradas/Editar',
					data: {
						idPreEntrada: values.preEntrieId,
						cliente: values.client,
						bultos: values.lumpsQuantity,
						caja: values.box,
						tracking: values.tracking,
						fecha: moment(form.date.value).toISOString()
					}
				});

				if (message) {
					alert.show(`La PreEntrada ${message} fue editada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando la pre entrada.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/PreEntradas" />;
	}

	if (state) {
		if (!defaultPreEntrie) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Agregar Pre Entrada</p>
					</div>
					<WafoForm formId="newPreEntrieForm" onSubmit={submitPreEntrie} values={defaultPreEntrie}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row w-full pr-2">
								<WafoFormInput
									name="preEntrieId"
									label="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Fecha es requerido`
										}
									}}
									customClass={'form-group-lg'}
									type="date"
									name="date"
									label="Fecha"
								/>
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									customClass="form-group-lg"
									options={clients}
									validations={{
										required: {
											value: true,
											message: 'El campo Cliente es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full pr-2">
								<WafoFormInput
									name="tracking"
									label="Tracking"
									placeholder="Tracking"
									customClass="form-group-lg"
									extraProps={{
										maxLength: 50
									}}
								/>
								<WafoFormInput
									name="box"
									label="Numero Caja"
									placeholder="Numero Caja"
									customClass="form-group-lg"
									extraProps={{
										maxLength: 20
									}}
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Cantidad Bultos es requerido`
										}
									}}
									extraProps={{
										min: 0
									}}
									customClass={'form-group-lg'}
									placeholder="Cantidad Bultos"
									type="number"
									name="lumpsQuantity"
									label="Cant. Bultos"
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
								Editar PreEntrada
							</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
};

export default EditPreEntrada;
