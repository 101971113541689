import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Main from './pages/Main';
import Entradas from './pages/Entradas';
import DetallesEntrada from './pages/DetallesEntrada';
import DetallesSalida from './pages/DetallesSalida';
import DetallesOrden from './pages/DetallesOrden';
import Detalles from './pages/Detalles';
import Capacidad from './pages/Capacidad';
import OrdenCargaPlus from './pages/OrdenCargaPlus';
import Ordenes from './pages/Ordenes';
import Salidas from './pages/Salidas';
import Cajas from './pages/Cajas';
import Logistica from './pages/Logistica';
import Notificaciones from './pages/Notificaciones';
import Layout from '#shared/Layout';

const Inventario = () => {
	return (
		<Layout>
			<Switch>
				<Route exact path="/Inventario" render={() => <Redirect to="/Inventario/Main" />} />
				<Route exact path="/Inventario/Main" component={Main} />
				<Route exact path="/Inventario/Entradas" component={Entradas} />
				<Route exact path="/Inventario/Entradas/Detalles" component={DetallesEntrada} />
				<Route exact path="/Inventario/Detalles" component={Detalles} />
				<Route exact path="/Inventario/Capacidad" component={Capacidad} />
				<Route exact path="/Inventario/OrdenCargaPlus" component={OrdenCargaPlus} />
				<Route exact path="/Inventario/Ordenes" component={Ordenes} />
				<Route exact path="/Inventario/Ordenes/Detalles" component={DetallesOrden} />
				<Route exact path="/Inventario/Salidas" component={Salidas} />
				<Route exact path="/Inventario/Salidas/Detalles" component={DetallesSalida} />
				<Route exact path="/Inventario/Cajas" component={Cajas} />
				<Route exact path="/Inventario/Logistica" component={Logistica} />
				<Route exact path="/Inventario/Notificaciones" component={Notificaciones} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</Layout>
	);
};

export default Inventario;
