import React, { useEffect, useState } from 'react';
import MenuCard from '#components/MenuCard';
import {
	FaTruckMoving,
	FaUsers,
	FaUserShield,
	FaTruckLoading,
	FaFlag,
	FaWeightHanging,
	FaTape,
	FaPallet,
	FaWarehouse,
	FaBook,
	FaExclamationTriangle
} from 'react-icons/fa';
import { MdLocationOn, MdAddAlert } from 'react-icons/md';
import { GoGear } from 'react-icons/go';
import { getVerifiedToken } from '#utils/authService';

const Main = () => {
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
			console.log(decoded.usuario);
		};
		getUserInfo();
	}, []);
	return (
		<div className="w-full px-4 md:px-8 lg:px-32">
			<div className="flex flex-col md:flex-row justify-center">
				<MenuCard title="Numeros de Parte" url="/Data-System/Catalogos/NumerosParte">
					<GoGear />
				</MenuCard>
				<MenuCard title="Transportistas" url="/Data-System/Catalogos/Transportistas">
					<FaTruckMoving />
				</MenuCard>
				<MenuCard title="Proveedores" url="/Data-System/Catalogos/Proveedores">
					<FaTruckLoading />
				</MenuCard>
			</div>
			<div className="flex flex-col md:flex-row justify-center">
				<MenuCard title="Paises" url="/Data-System/Catalogos/Paises">
					<FaFlag />
				</MenuCard>
				<MenuCard title="Clientes" url="/Data-System/Catalogos/Clientes">
					<FaUsers />
				</MenuCard>
				<MenuCard title="Unidades Medida" url="/Data-System/Catalogos/UnidadesMedida">
					<FaTape />
				</MenuCard>
			</div>
			<div className="flex flex-col md:flex-row justify-center">
				<MenuCard title="Locaciones" url="/Data-System/Catalogos/Locaciones">
					<MdLocationOn />
				</MenuCard>
				<MenuCard title="Unidades Bulto" url="/Data-System/Catalogos/UnidadesBulto">
					<FaPallet />
				</MenuCard>
				<MenuCard title="Estados" url="/Data-System/Catalogos/Estados">
					<FaExclamationTriangle />
				</MenuCard>
			</div>
			<div className="flex flex-col md:flex-row justify-center">
				<MenuCard title="Almacenes" url="/Data-System/Catalogos/Almacenes">
					<FaWarehouse />
				</MenuCard>
				<MenuCard title="Regimenes" url="/Data-System/Catalogos/Regimenes">
					<FaBook />
				</MenuCard>
				{userInfo && userInfo.Id_Tipo_Usuario === 3 ? (
					<MenuCard title="Usuarios" url="/Data-System/Catalogos/Usuarios">
						<FaUserShield />
					</MenuCard>
				) : (
					''
				)}
			</div>
			<div className="flex flex-col md:flex-row justify-center w-full md:w-1/3">
				{userInfo && userInfo.Id_Tipo_Usuario === 3 ? (
					<MenuCard title="Alertas" url="/Data-System/Catalogos/Alertas">
						<MdAddAlert />
					</MenuCard>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default Main;
