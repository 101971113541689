import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
const ListNotificaciones = () => {
	const titles = ['Descripcion', 'Fecha', 'Modulo', 'Estado', ''];
	const [notifications, setNotifications] = useState([]);
	const [totalRows, setTotalRows] = useState();
	const [notificationsPerPage, setNotificationsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const alert = useAlert();

	useEffect(() => {
		setCurrentPage(1);
	}, [notificationsPerPage]);

	useEffect(() => {
		getNotifications();
	}, [currentPage, notificationsPerPage]);

	const getNotifications = async () => {
		let { rows, count } = await axiosConfig({
			method: 'get',
			url: '/data-system/alertas/notificaciones',
			params: {
				page: currentPage,
				limit: notificationsPerPage,
				leidas: true
			}
		});
		if (rows) {
			if (count > 0) {
				setTotalRows(count);
			} else {
				setTotalRows(1);
			}
			setNotifications(rows);
		}
	};

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / notificationsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, notificationsPerPage]);

	const markNotificationAsRead = async notificationId => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/data-system/alertas/notificaciones/leer',
				params: {
					idNotificacion: notificationId
				}
			});

			if (data === 'OK') {
				alert.show(`La notificacion fue marcada como leida.`, {
					type: 'success'
				});
				getNotifications();
			}
		} catch (error) {
			alert.show('Hubo un problema marcando la notificacion como leida.', {
				type: 'error'
			});
		}
	};
	const markAllNotificationAsRead = async notificationId => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/data-system/alertas/notificaciones/leerTodas'
			});

			if (data === 'OK') {
				alert.show(`Las notificaciones fueron marcadas como leidas.`, {
					type: 'success'
				});
				getNotifications();
			}
		} catch (error) {
			alert.show('Hubo un problema marcando las notificaciones como leidas.', {
				type: 'error'
			});
		}
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col lg:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Notificaciones</p>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={notificationsPerPage}
							onChange={event => setNotificationsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<div className="mt-1">
						<button
							className="button btn-lg bg-green-600 ml-3"
							onClick={event => {
								event.preventDefault();
								markAllNotificationAsRead();
							}}
						>
							Marcar como leidas
						</button>
					</div>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{notifications.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{notifications.map(notification => {
								const utcDate = moment.utc(notification.Fecha).format();
								const localDate = moment(utcDate)
									.local()
									.format('YYYY-MM-DD hh:mm a');

								return (
									<tr key={notification.Id_Notificacion}>
										<td>
											{Number(notification.Modulo) === 1 ? (
												<Link
													to={{
														pathname: '/Inventario/Entradas/Detalles',
														state: { entrieId: notification.Id_Entrada_Salida }
													}}
													title="Ir a Detalles de Entrada"
												>
													{notification.Texto}
												</Link>
											) : Number(notification.Modulo) === 2 ? (
												<Link
													to={{
														pathname: '/Inventario/Salidas/Detalles',
														state: { outputId: notification.Id_Entrada_Salida }
													}}
													title="Ir a Detalles de Salida"
												>
													{notification.Texto}
												</Link>
											) : (
												notification.Texto
											)}
										</td>
										<td>{localDate}</td>
										<td>
											{Number(notification.Modulo) === 1
												? 'Entradas'
												: Number(notification.Modulo) === 2
												? 'Salidas'
												: 'Advertencia Regimenes'}
										</td>
										<td>{notification.Leida === 0 ? 'Sin Leer' : 'Leida'}</td>
										<td>
											<button
												disabled={notification.Leida === 1}
												className={`mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button ${
													+notification.Leida === 1 ? 'bg-green-700' : 'bg-red-700'
												}`}
												title="Marcar como Leida"
												onClick={() => {
													markNotificationAsRead(notification.Id_Notificacion);
												}}
											>
												<FaCheckCircle />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default ListNotificaciones;
