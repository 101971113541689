import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditNumeroParte = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [clients, setClients] = useState([]);
	const [units, setUnits] = useState([]);
	const [countries, setCountries] = useState([]);
	const [defaultPartNumber, setDefaultPartNumber] = useState([]);
	const alert = useAlert();

	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getUnits = async () => {
			let { unidades } = await axiosConfig({
				method: 'get',
				url: '/data-system/partes/unidades'
			});
			const data = unidades.map(obj => {
				return { display: obj.Unidad_Medida, value: String(obj.Id_Unidad_Medida) };
			});

			setUnits(data);
		};
		const getCountries = async () => {
			let { paises: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/paises'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Esp, value: String(obj.Id_Pais) };
			});

			setCountries(data);
		};
		const getPartNumber = async () => {
			const partNumberInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Partes/getParte',
				params: { idParte: state.partNumberId }
			});

			setDefaultPartNumber({
				partNumberId: partNumberInfo.Id_Parte,
				partNumber: partNumberInfo.Numero_Parte,
				client: String(partNumberInfo.numero_parte_cliente.Id_Cliente),
				unit: String(partNumberInfo.Id_Unidad_Medida),
				unitWeight: partNumberInfo.Peso_Unidad,
				descriptionEng: partNumberInfo.Descripcion_Ing,
				descriptionSp: partNumberInfo.Descripcion_Esp,
				country: String(partNumberInfo.pais_numero_parte.Id_Pais),
				fraction: partNumberInfo.Fraccion,
				brand: partNumberInfo.Marca,
				model: partNumberInfo.Modelo,
				serie: partNumberInfo.Serie,
				IMMEX: partNumberInfo.IMMEX,
				comments: partNumberInfo.Comentarios
			});
		};

		if (state) {
			getPartNumber();
			getClients();
			getCountries();
			getUnits();
		}
	}, [state]);
	const submitPartNumber = async (form, values) => {
		if (form.valid) {
			try {
				const partNumberInfo = {
					idParte: values.partNumberId,
					numeroParte: values.partNumber,
					idCliente: values.client,
					idUnidad: values.unit,
					peso: values.unitWeight,
					descripcionIng: values.descriptionEng,
					descripcionEsp: values.descriptionSp,
					idPais: values.country,
					fraccion: values.fraction,
					marca: values.brand,
					modelo: values.model,
					serie: values.serie,
					IMMEX: values.IMMEX,
					comentarios: values.comments
				};
				// console.log(partNumberInfo);
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Partes/Editar',
					data: partNumberInfo
				});
				if (data === 'OK') {
					alert.show(`El # Parte ${values.partNumber} fue editado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando el numero de parte, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/NumerosParte" />;
	}
	if (state) {
		if (!defaultPartNumber) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Numero de Parte</p>
					</div>
					<WafoForm
						values={defaultPartNumber}
						formId="newPartNumberForm"
						onSubmit={submitPartNumber}
					>
						<div className="flex flex-col mx-auto w-full items-center pr-2 lg:pr-0">
							<div className="flex flex-col xl:flex-row w-full">
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormInput name="partNumberId" label="Id" placeholder="Id" />
									<WafoFormInput
										name="partNumber"
										label="# Parte"
										placeholder="# Parte"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo # Parte es requerido'
											}
										}}
									/>
									<WafoFormSelect
										name="client"
										defaultValue="Seleccione una Opcion"
										label="Cliente"
										customClass="form-group-lg"
										options={clients}
										validations={{
											required: {
												value: true,
												message: 'El campo Cliente es requerido'
											}
										}}
									/>
								</div>
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormSelect
										name="unit"
										defaultValue="Seleccione una Opcion"
										label="U.M"
										customClass="form-group-lg"
										options={units}
										type="number"
										extraProps={{
											min: 0
										}}
										validations={{
											required: {
												value: true,
												message: 'El campo U.M es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="unitWeight"
										label="Peso Unitario"
										placeholder="Peso Unitario"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo Peso Unitario es requerido'
											}
										}}
									/>
								</div>
							</div>
							<div className="flex flex-col xl:flex-row w-full">
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormInput
										name="descriptionSp"
										label="Descripcion Esp"
										placeholder="Descripcion Esp"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo Descripcion Esp es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="descriptionEng"
										label="Descripcion Ing"
										placeholder="Descripcion Ing"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo Descripcion Ing es requerido'
											}
										}}
									/>
								</div>
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormSelect
										name="country"
										defaultValue="Seleccione una Opcion"
										label="Pais Origen"
										customClass="form-group-lg"
										options={countries}
										validations={{
											required: {
												value: true,
												message: 'El campo Pais Origen es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="fraction"
										label="Fraccion"
										placeholder="Fraccion"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo Fraccion es requerido'
											},
											regex: {
												value: /\d{6,10}/,
												message: 'Fraccion invalida'
											}
										}}
										extraProps={{
											maxLength: 10
										}}
									/>
								</div>
							</div>
							<div className="flex flex-col xl:flex-row w-full">
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormInput
										name="IMMEX"
										label="IMMEX"
										placeholder="IMMEX"
										customClass="form-group-lg"
										validations={{
											required: {
												value: true,
												message: 'El campo IMMEX es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="brand"
										label="Marca"
										placeholder="Marca"
										customClass="form-group-lg"
									/>
								</div>
								<div className="flex flex-col lg:flex-row w-full">
									<WafoFormInput
										name="model"
										label="Modelo"
										placeholder="Modelo"
										customClass="form-group-lg"
									/>
									<WafoFormInput
										name="serie"
										label="Serie"
										placeholder="Serie"
										customClass="form-group-lg"
									/>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row w-full lg:w-2/4">
								<WafoFormTextArea
									name="comments"
									customClass="form-group-lg"
									placeholder="Comentarios"
									label="Comentarios"
									extraProps={{ rows: 3 }}
								/>
							</div>
							<button className="button btn-lg bg-green-700 mt-2 mb-2">
								Editar Numero de Parte
							</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/NumerosParte" />;
};

export default EditNumeroParte;
