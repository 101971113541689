import React, { useEffect } from 'react';
import { useState } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';
import {
	WafoForm,
	WafoFormInput,
	WafoFormSelect,
	WafoFormTextArea,
	WafoFormAutocomplete
} from '@wafo/forms';
const Movimientos = () => {
	const titles = [
		'',
		'',
		'Almacen',
		'Numero De Parte',
		'Cant. Piezas',
		'Cant. En Transito',
		'Bultos',
		'Bultos En Transito',
		'PO',
		'Descripcion',
		'Fraccion',
		'Pais',
		'Marca',
		'Modelo',
		'Serie'
	];
	const alert = useAlert();
	const [inventory, setInventory] = useState([]);
	const [searchParams, setSearchParams] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState();
	const [pageNumbers, setPageNumbers] = useState([]);
	const [partsPerPage, setPartsPerPage] = useState(20);
	const [selectedRows, setSelectedRows] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [regimen, setRegimen] = useState('1');
	const [notes, setNotes] = useState();
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState(0);
	const [carriers, setCarriers] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [warehouse, setWarehouse] = useState(0);
	const [newWarehouse, setNewWarehouse] = useState(0);
	const [entries, setEntries] = useState([]);
	const [users, setUsers] = useState([]);
	const [forklift, setForklift] = useState();

	useEffect(() => {
		const getWarehouses = async () => {
			let { almacenes: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});
			setWarehouses(data);
		};
		getWarehouses();
	}, []);
	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});

			setClients(data);
		};
		const getWarehouses = async () => {
			let { almacenes: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});
			setWarehouses(data);
		};

		getWarehouses();
		getInventory();
		getClients();
	}, [searchParams, partsPerPage, currentPage, setSelectedRows, client, warehouse]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / partsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, partsPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [partsPerPage, searchParams]);

	const getInventory = async () => {
		const { result, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/ordenes/detalles',
			params: {
				search: searchParams,
				rowsPorPagina: partsPerPage,
				paginaActual: currentPage,
				idCliente: client,
				almacen: warehouse
			}
		});
		if (result) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}

			const newEntries = [];
			result.map(x => {
				if (
					!newEntries
						.map(x => {
							return x.entrie;
						})
						.includes(x.partida_entrada.Entrada)
				) {
					newEntries.push({
						entrie: x.partida_entrada.Entrada,
						reference: x.partida_entrada.Referencia,
						checked: false,
						show: true
					});
				}
			});
			setEntries(newEntries);
			setInventory(result);
		}
	};
	const handleCheckBox = (isRowSelected, departureId, warehouseId, partNumber, entrie) => {
		if (isRowSelected) {
			const indexOfSelectedRow = selectedRows.map((obj, index) => {
				if (obj.departureId === departureId && obj.warehouseId === warehouseId) {
					return index;
				}
			});
			selectedRows.splice(indexOfSelectedRow, 1);
		} else {
			const piecesQuantity = document.getElementById(`piecesQuantity${departureId}-${warehouseId}`)
				.value;
			const lumpsQuantity = document.getElementById(`lumpsQuantity${departureId}-${warehouseId}`)
				.value;

			if (isAValidRow(piecesQuantity, lumpsQuantity, departureId, warehouseId)) {
				selectedRows.push({
					departureId,
					warehouseId,
					lumpsQuantity,
					piecesQuantity,
					partNumber,
					entrie
				});
			}
		}

		getInventory();
	};

	const isAValidRow = (piecesQuantity, lumpsQuantity, departureId, warehouseId) => {
		if (piecesQuantity && lumpsQuantity) {
			let originalRow = {};
			inventory.some(obj => {
				if (
					obj.Id_Partida_Entrada === departureId &&
					obj.partida_inventario.Id_Almacen === warehouseId
				) {
					originalRow = obj;
					return true;
				}
			});
			if (
				lumpsQuantity > +originalRow.partida_inventario.Cantidad_Bultos ||
				piecesQuantity > +originalRow.partida_inventario.Cantidad_Piezas
			) {
				alert.show(
					`La cantidad de piezas debe ser mayor o igual a 1 y menor o igual a  ${originalRow.partida_inventario.Cantidad_Piezas} y
				la cantidad de bultos debe ser mayor o igual a 1 y menor o igual a  ${originalRow.partida_inventario.Cantidad_Piezas}`,
					{ type: 'error' }
				);

				return false;
			}
			return true;
		} else {
			return false;
		}
	};

	const showDeparturesConfirmation = () => {
		if (selectedRows.length > 0) {
			if (newWarehouse > 0) {
				setShowModal(!showModal);
			} else {
				alert.show('Debes escoger un almacen.', {
					type: 'error'
				});
			}
		} else {
			alert.show('Debes escoger partidas a mover.', {
				type: 'error'
			});
		}
	};
	const moveDepartures = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/data-system/inventario/mover',
				data: {
					partidas: selectedRows.map(obj => {
						return {
							idPartida: obj.departureId,
							cantidad: obj.piecesQuantity,
							bultos: obj.lumpsQuantity,
							idAlmacen: obj.warehouseId
						};
					}),
					idCliente: client,
					almacen: newWarehouse
				}
			});

			if (data === 'OK') {
				alert.show(`Las partidas fueron movidas correctamente.`, {
					type: 'success'
				});

				setSelectedRows([]);
				setShowModal(false);
				getInventory();
			} else {
				throw new Error();
			}
		} catch (error) {
			alert.show('Hubo un problema al mover las partidas.', {
				type: 'error'
			});
		}
	};
	const handleSelectEntrie = ({ target }, entrie) => {
		if (target.checked) {
			selectEntrie(entrie);
		} else {
			unSelectEntrie(entrie);
		}
	};
	const selectEntrie = async entrie => {
		const result = await axiosConfig({
			method: 'get',
			url: '/data-system/entradas/partidas',
			params: {
				numeroEntrada: entrie,
				almacen: warehouse
			}
		});

		console.log(result);
		const newEntries = Object.assign([], entries);

		newEntries.map(obj => {
			if (obj.entrie === entrie) {
				obj.checked = true;
			}
		});

		setEntries(newEntries);

		const selectedDepartureId = selectedRows.map(x => {
			return x.departureId;
		});

		result.map(row => {
			if (!selectedDepartureId.includes(row.Id_Partida_Entrada)) {
				selectedRows.push({
					departureId: row.Id_Partida_Entrada,
					piecesQuantity: row.partida_inventario.Cantidad_Piezas,
					lumpsQuantity: row.partida_inventario.Cantidad_Bultos,
					entrie,
					partNumber: row.numero_parte.Numero_Parte,
					warehouseId: row.partida_inventario.Id_Almacen
				});
			}
		});

		setInventory(prev => {
			const newInventory = [];
			Object.assign(newInventory, prev);
			return newInventory;
		});
	};

	const unSelectEntrie = async entrie => {
		const indexOfSameEntrie = selectedRows.map(x => {
			if (x.entrie === entrie) {
				return x.departureId;
			}
		});

		indexOfSameEntrie.map(x => {
			if (x) {
				const indexOfId = selectedRows
					.map(obj => {
						return obj.departureId;
					})
					.indexOf(x);
				selectedRows.splice(indexOfId, 1);
			}
		});

		const newEntries = Object.assign([], entries);

		newEntries.map(obj => {
			if (obj.entrie === entrie) {
				obj.checked = false;
			}
		});

		setEntries(newEntries);

		setInventory(prev => {
			const newInventory = [];
			Object.assign(newInventory, prev);
			return newInventory;
		});
	};
	const ShowUnShowEntrie = async entrie => {
		const newEntries = Object.assign([], entries);

		newEntries.map(obj => {
			if (obj.entrie === entrie) {
				obj.show = !obj.show;
			}
		});

		setEntries(newEntries);
	};

	function renderModal() {
		if (showModal) {
			return (
				<Modal>
					<div>
						<p className="text-xl text-black">¿Desea mover las siguientes partidas?:</p>
						<table className="text-left w-full border-collapse table">
							<thead>
								<tr>
									<th className="text-gray-dark">Entrada</th>
									<th className="text-gray-dark">Almacen</th>
									<th className="text-gray-dark"># de Parte</th>
									<th className="text-gray-dark">Cantidad Piezas</th>
									<th className="text-gray-dark">Cantidad Bultos</th>
								</tr>
							</thead>
							<tbody>
								{selectedRows.map((row, index) => {
									const warehouseIndex = warehouses
										.map(x => {
											return x.Id_Almacen;
										})
										.indexOf(row.warehouseId);

									const warehouseName = warehouses[warehouseIndex].Nombre_Almacen;

									return (
										<tr key={index}>
											<td>{row.entrie}</td>
											<td>{warehouseName}</td>
											<td>{row.partNumber}</td>
											<td>{row.piecesQuantity}</td>
											<td>{row.lumpsQuantity}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => moveDepartures()}
							>
								Confirmar
							</button>
						</div>
					</div>
				</Modal>
			);
		}
	}
	return (
		<div className="mt-3 flex flex-col m-h-full h-full">
			<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row w-full mt-2 md:mt-0">
					<p className="text-xl mt-2">Inventario</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						id="search"
						type="text"
						value={searchParams}
						onChange={event => setSearchParams(event.target.value)}
						placeholder="Buscar numero de parte"
					/>

					<select
						onChange={event => setClient(event.target.value)}
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						defaultValue="default"
						disabled={selectedRows.length > 0}
					>
						<option key="default" value="default" disabled>
							Seleccione Opcion
						</option>
						{clients.map(cli => {
							return (
								<option key={cli.Id_Cliente} value={cli.Id_Cliente}>
									{cli.Nombre_Cliente}
								</option>
							);
						})}
					</select>
					<select
						className="mt-1 w-full md:w-1/3 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						onChange={event => setWarehouse(event.target.value)}
						defaultValue="default"
						disabled={selectedRows.length > 0}
					>
						<option value="default">Seleccione un Almacen</option>
						{warehouses.map(obj => {
							return (
								<option key={obj.Id_Almacen} value={obj.Id_Almacen}>
									{obj.Nombre_Almacen}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-row w-full">
					<div className="ml-4 flex flex-row my-2">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostar
						<select
							value={partsPerPage}
							onChange={event => setPartsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
				</div>
			</div>

			<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md mt-2 py-3">
				<div className="flex flex-row mt-2 items-center">
					<div className="w-3/4">
						<select
							className="mt-1 w-full md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							onChange={event => setNewWarehouse(event.target.value)}
							defaultValue="default"
						>
							<option value="default">Seleccione un Almacen</option>
							{warehouses.map(obj => {
								return (
									<option key={obj.Id_Almacen} value={obj.Id_Almacen}>
										{obj.Nombre_Almacen}
									</option>
								);
							})}
						</select>
					</div>

					<div className="ml-2 w-1/4">
						<button
							className="ml-2 h-10 bg-green-500 hover:bg-green-600 py-2 px-4 rounded"
							onClick={() => showDeparturesConfirmation()}
						>
							<p className="text-white text-base">Terminar</p>
						</button>
					</div>
				</div>
			</div>

			{inventory.length === 0 ? (
				<h4>Sin Resultados</h4>
			) : (
				entries.map(entrie => {
					return (
						<div className="flex flex-col shadow-md mt-3" key={entrie.entrie}>
							<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md mt-3">
								<p className="text-xl mt-2">
									Entrada {entrie.entrie} - Referencia {entrie.reference}
									<input
										className="ml-1"
										type="checkbox"
										value={entrie.entrie}
										onChange={event => handleSelectEntrie(event, entrie.entrie)}
										defaultChecked={entrie.checked}
									/>
								</p>

								<button
									className={`ml-2 button w-24 h-8 ${entrie.show ? 'bg-red-600' : 'bg-blue-600'} `}
									onClick={() => ShowUnShowEntrie(entrie.entrie)}
								>
									{entrie.show ? 'Ocultar' : 'Mostrar'}
								</button>
							</div>

							{entrie.show ? (
								<div className="overflow-y-auto">
									<table className="text-left mt-2 w-full border-collapse table">
										<thead>
											<tr>
												{titles.map((title, index) => (
													<th className="text-gray-dark" key={index}>
														{title}
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{inventory.map(piece => {
												if (piece.partida_entrada.Entrada === entrie.entrie) {
													let isRowSelected = false;
													let indexOfSelectedRow;
													selectedRows.map((obj, index) => {
														if (
															obj.departureId === piece.Id_Partida_Entrada &&
															obj.warehouseId === piece.partida_inventario.Id_Almacen
														) {
															isRowSelected = true;
															indexOfSelectedRow = index;
														}
													});
													let rowStyle = '';
													if (isRowSelected) {
														rowStyle = 'selected';
													}

													const warehouseIndex = warehouses
														.map(x => {
															return x.Id_Almacen;
														})
														.indexOf(piece.partida_inventario.Id_Almacen);

													const warehouseName = warehouses[warehouseIndex].Nombre_Almacen;

													return (
														<tr className={rowStyle} key={piece.Id_Partida_Entrada}>
															<td>
																<input
																	id={`selectRow${piece.Id_Partida_Entrada}-${piece.partida_inventario.Id_Almacen}`}
																	type="checkbox"
																	onChange={event => {
																		event.preventDefault();
																		handleCheckBox(
																			isRowSelected,
																			piece.Id_Partida_Entrada,
																			piece.partida_inventario.Id_Almacen,
																			piece.numero_parte.Numero_Parte,
																			piece.partida_entrada.Entrada
																		);
																	}}
																	title={isRowSelected ? 'Deseleccionar' : 'Seleccionar'}
																	checked={isRowSelected}
																/>
															</td>
															<td>
																{piece.partida_entrada.Revisada === 1 ? (
																	<FaCheckCircle style={{ color: 'green' }} title="Revisado" />
																) : (
																	<AiOutlineCloseCircle style={{ color: 'red' }} title="Revisado" />
																)}
															</td>
															<td>{warehouseName}</td>
															<td>{piece.numero_parte.Numero_Parte}</td>
															<td>
																<form
																	className="form-inline"
																	onSubmit={event => event.preventDefault()}
																>
																	<div className="form-group w-32">
																		<input
																			id={`piecesQuantity${piece.Id_Partida_Entrada}-${piece.partida_inventario.Id_Almacen}`}
																			className="form-control form-control-sm mr-1"
																			type="number"
																			placeholder={piece.partida_inventario.Cantidad_Piezas}
																			defaultValue={
																				isRowSelected
																					? selectedRows[indexOfSelectedRow].piecesQuantity
																					: piece.partida_inventario.Cantidad_Piezas
																			}
																			max={piece.partida_inventario.Cantidad_Piezas}
																			min="0"
																			step="1"
																			disabled={isRowSelected}
																			required
																		/>
																		<p className="text-xs">{` Max ${piece.partida_inventario.Cantidad_Piezas}${piece.unidad_partida.Unidad_Medida}`}</p>
																	</div>
																</form>
															</td>
															<td>{piece.partida_inventario.Piezas_Transito}</td>
															<td>
																<form
																	className="form-inline"
																	onSubmit={event => event.preventDefault()}
																>
																	<div className="form-group w-32">
																		<input
																			id={`lumpsQuantity${piece.Id_Partida_Entrada}-${piece.partida_inventario.Id_Almacen}`}
																			className="form-control form-control-sm mr-1"
																			type="number"
																			placeholder={piece.partida_inventario.Cantidad_Bultos}
																			defaultValue={
																				isRowSelected
																					? selectedRows[indexOfSelectedRow].lumpsQuantity
																					: piece.partida_inventario.Cantidad_Bultos
																			}
																			max={piece.partida_inventario.Cantidad_Bultos}
																			min="0"
																			step="any"
																			disabled={isRowSelected}
																			required
																		/>
																		<p className="text-xs">{`Max ${piece.partida_inventario.Cantidad_Bultos}`}</p>
																	</div>
																</form>
															</td>
															<td>{piece.partida_inventario.Bultos_Transito}</td>
															<td>{piece.PO}</td>
															<td>{piece.numero_parte.Descripcion_Esp}</td>
															<td>{piece.Fraccion}</td>
															<td>{`${piece.pais_partida.Nombre_Esp}-${piece.pais_partida.Alfa3}`}</td>
															<td>{piece.numero_parte.Marca}</td>
															<td>{piece.numero_parte.Modelo}</td>
															<td>{piece.numero_parte.Serie}</td>
														</tr>
													);
												}
											})}
										</tbody>
									</table>
								</div>
							) : (
								''
							)}
						</div>
					);
				})
			)}
			{renderModal()}
		</div>
	);
};

export default Movimientos;
