import React, { useEffect, useState } from 'react';
import {
	WafoForm,
	WafoFormInput,
	WafoFormSelect,
	WafoFormTextArea,
	WafoFormAutocomplete
} from '@wafo/forms';
import { CustomMultipleFileInput, CustomFileInput } from '#components/form/controls';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';
import {
	AddTransportistaForm,
	AddProveedorForm,
	AddUnidadBultoForm,
	AddUnidadMedidaForm
} from '#forms';
import Modal from '#components/Modal';

const AddSalida = () => {
	//TODO: Agregar checkbox de revisado en form
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [regimes, setRegimes] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [order, setOrder] = useState([]);
	const [client, setClient] = useState();
	const [orders, setOrders] = useState([]);
	const [preOutput, setPreOutput] = useState({});
	const [users, setUsers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedAddOption, setSelectedAddOption] = useState();
	useEffect(() => {
		const getUsers = async () => {
			let { usuarios } = await axiosConfig({
				method: 'get',
				url: '/data-system/usuarios',
				params: {
					deshabilitados: false
				}
			});

			const data = usuarios.map(obj => {
				return { display: obj.Username, value: String(obj.Id_Usuario) };
			});
			setUsers(data);
		};
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getRegimes = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/regimenes'
			});
			const data = rows.map(obj => {
				return { display: obj.Nombre_Regimen, value: String(obj.Id_Regimen) };
			});

			setRegimes(data);
		};
		const getCarriers = async () => {
			let { transportistas: carriers } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			carriers = carriers.map(obj => {
				return { display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) };
			});
			setCarriers(carriers);
		};
		getClients();
		getCarriers();
		getRegimes();
		getUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getCarriers = async () => {
			let { transportistas: carriers } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			carriers = carriers.map(obj => {
				return { display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) };
			});
			setCarriers(carriers);
		};
		getCarriers();
	}, [showModal]);

	const submitOutput = async (form, values) => {
		if (form.valid) {
			if (order.length > 0) {
				try {
					const output = {
						regimen: values.regime,
						fecha: moment(form.date.value).toISOString(),
						numeroOrden: values.order.Orden,
						transportista: values.carrier,
						caja: values.box,
						sello: values.stamp,
						observaciones: values.observations,
						factura: values.bill,
						pedimento: values.petition,
						referencia: values.reference,
						placas: values.plates,
						recibido: values.receivedBy,
						montacarga: values.forklift,
						tipoOperacion: values.impoExpo,
						cliente: values.client
					};
					const formData = new FormData();
					formData.append('packingList', values.packingList);

					values.files.map(file => {
						formData.append('archivos', file);
					});

					formData.append('data', JSON.stringify(output));
					const { message } = await axiosConfig({
						method: 'post',
						url: '/data-system/salidas/nueva',
						data: formData
					});

					if (message) {
						alert.show(`La salida ${message} fue agregada correctamente.`, {
							type: 'success'
						});
						setSubmitted(true);
					} else {
						throw new Error();
					}
				} catch (error) {
					alert.show('Hubo un problema al agregar la salida, intentelo mas tarde.', {
						type: 'error'
					});
				}
			} else {
				alert.show('La orden de carga no puede estar vacia', {
					type: 'error'
				});
			}
		}
	};

	const onClientChange = event => {
		setClient(event.target.value);
		getOrders(event.target.value);
	};

	const getOrders = async clientId => {
		let data = await axiosConfig({
			method: 'get',
			url: '/data-system/ordenes/ordenesCliente',
			params: {
				idCliente: clientId,
				obtenerOrdenesEnUso: false
			}
		});
		setOrders(data);
	};

	const onOrderChange = async item => {
		if (item && client) {
			let { orden, preSalida } = await axiosConfig({
				method: 'get',
				url: '/data-system/ordenes/getOrden',
				params: {
					numeroOrden: item.Orden,
					idCliente: client
				}
			});

			if (preSalida) {
				setPreOutput({
					stamp: preSalida.Sello,
					bill: preSalida.Factura,
					box: preSalida.Numero_Caja,
					carrier: String(preSalida.Id_Transportista)
				});
			}
			setOrder(orden);
		}
	};

	function renderModal() {
		const onSubmitSuccess = () => {
			setShowModal(false);
		};
		if (showModal) {
			switch (selectedAddOption) {
				case 1:
					return (
						<Modal useDesign={false}>
							<AddTransportistaForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />
						</Modal>
					);
			}
		}
	}

	if (submitted) {
		return <Redirect to="/Data-System/Salidas" />;
	}
	return (
		<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center w-full">
					<p className="text-2xl mt-2">Agregar Salida</p>
				</div>
				<WafoForm formId={'newOutputForm'} onSubmit={submitOutput} values={preOutput}>
					<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0 ">
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Fecha es requerido`
									}
								}}
								customClass={'form-group-lg'}
								type="date"
								name="date"
								label="Fecha"
							/>
							<WafoFormSelect
								name="regime"
								defaultValue="Seleccione una Opcion"
								label="Regimen"
								customClass="form-group-lg"
								options={regimes}
								validations={{
									required: {
										value: true,
										message: 'El campo Regimen es requerido'
									}
								}}
							/>

							<WafoFormSelect
								name="impoExpo"
								defaultValue="Seleccione una Opcion"
								label="Tipo Operacion"
								customClass="form-group-lg"
								options={[
									{ display: 'Impo', value: 'Impo' },
									{ display: 'Expo', value: 'Expo' },
									{ display: 'Distribucion', value: 'Distribucion' }
								]}
								validations={{
									required: {
										value: true,
										message: 'El campo Operacion es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormSelect
								name="forklift"
								defaultValue="Seleccione una Opcion"
								label="Montacarga"
								customClass="form-group-lg"
								options={users}
								validations={{
									required: {
										value: true,
										message: 'El campo Montacarga es requerido'
									}
								}}
							/>
							<WafoFormSelect
								name="client"
								defaultValue="Seleccione una Opcion"
								label="Cliente"
								customClass="form-group-lg"
								options={clients}
								validations={{
									required: {
										value: true,
										message: 'El campo Cliente es requerido'
									}
								}}
								onChangeCallback={onClientChange}
							/>
							<WafoFormSelect
								name="carrier"
								defaultValue="Seleccione una Opcion"
								label="Transportista"
								customClass="form-group-lg"
								options={carriers}
								validations={{
									required: {
										value: true,
										message: 'El campo Transportista es requerido'
									}
								}}
							/>
							<button
								className={`button bg-blue-600 ml-2 mt-12 h-10`}
								onClick={e => {
									e.preventDefault();
									setSelectedAddOption(1);
									setShowModal(true);
								}}
							>
								+
							</button>
						</div>
					</div>
					<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo # de Caja es requerido`
									}
								}}
								extraProps={{
									maxLength: 20
								}}
								customClass={'form-group-lg'}
								placeholder="# de Caja"
								type="text"
								name="box"
								label="# de Caja"
							/>

							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Sello es requerido`
									}
								}}
								extraProps={{
									maxLength: 30
								}}
								customClass={'form-group-lg'}
								placeholder="Sello"
								type="text"
								name="stamp"
								label="Sello"
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Referencia es requerido`
									}
								}}
								extraProps={{
									maxLength: 50
								}}
								customClass={'form-group-lg'}
								placeholder="Referencia"
								type="text"
								name="reference"
								label="Referencia"
							/>
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Factura es requerido`
									}
								}}
								extraProps={{
									maxLength: 50
								}}
								customClass={'form-group-lg'}
								placeholder="Factura"
								type="text"
								name="bill"
								label="Factura"
							/>
						</div>
					</div>
					<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Pedimento es requerido`
									}
								}}
								extraProps={{
									maxLength: 10
								}}
								customClass={'form-group-lg'}
								placeholder="Pedimento"
								name="petition"
								label="Pedimento"
							/>

							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Recibido es requerido`
									}
								}}
								customClass={'form-group-lg'}
								placeholder="Recibido Por"
								name="receivedBy"
								label="Recibido Por"
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full m-0 p-0">
							<WafoFormInput
								validations={{
									required: {
										value: true,
										message: `El campo Placas es requerido`
									}
								}}
								extraProps={{
									maxLength: 50
								}}
								customClass={'form-group-lg'}
								placeholder="Placas"
								name="plates"
								label="Placas"
							/>

							<WafoFormAutocomplete
								validations={{
									required: {
										value: true,
										message: `El campo Orden Carga es requerido`
									}
								}}
								customClass={'form-group-lg'}
								placeholder="Orden Carga"
								name="order"
								label="Orden Carga"
								items={orders}
								renderInput={item => item.Orden}
								renderItem={item => (
									<p>
										<span>{item.Orden}</span>
									</p>
								)}
								filterItems={(items, query) => {
									return items.filter(
										item => item.Orden.toLowerCase().indexOf(query.toLowerCase()) !== -1
									);
								}}
								onSelectCallback={onOrderChange}
								handleChange
							/>
						</div>
					</div>
					<div className="w-3/4 flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
						<CustomFileInput
							name="packingList"
							meta={{ label: 'Packing List', accept: 'application/pdf' }}
							handleChange
						/>
						<CustomMultipleFileInput
							name="files"
							meta={{ label: 'Archivos', accept: '.doc,.pdf,image/*,.xlsx' }}
							handleChange
							multiple={true}
						/>
						<WafoFormTextArea
							name="observations"
							customClass="form-group-lg"
							placeholder="Observaciones."
							label="Observaciones"
							extraProps={{ rows: 3 }}
						/>
					</div>
					<div className="w-full flex flex-row">
						<button className="ml-2 mt-3 button btn-lg bg-red-600" type="reset">
							Limpiar
						</button>
						<button className="ml-2 mt-3 button btn-lg bg-green-600" type="submit">
							Agregar Salida
						</button>
					</div>
				</WafoForm>

				{order.length > 0 ? <p className="text-xl mt-2">Informacion orden de carga</p> : ''}
				{order.map(
					({
						orden_partida,
						orden_partida: {
							partida_orden,
							partida_orden: { numero_parte }
						}
					}) => {
						return (
							<div className="flex flex-col w-full p-4 mb-4" key={orden_partida.Id_Partida_Entrada}>
								<div className="flex flex-col lg:flex-row w-full">
									<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
										<div>
											<p className="ml-2">Numero Parte</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={numero_parte.Numero_Parte}
											/>
										</div>
										<div>
											<p className="ml-2">Piezas</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={orden_partida.Cantidad_Piezas}
											/>
										</div>
										<div>
											<p className="ml-2">UM Pieza</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={partida_orden.unidad_partida.Unidad_Medida}
											/>
										</div>
									</div>
									<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
										<div>
											<p className="ml-2">Bultos</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={String(orden_partida.Cantidad_Bultos)}
											/>
										</div>
										<div>
											<p className="ml-2">UM Bulto</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={partida_orden.unidad_bulto.Unidad_Bulto}
											/>
										</div>
										<div>
											<p className="ml-2">Peso Neto</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={partida_orden.Peso_Neto}
											/>
										</div>
									</div>
									<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
										<div>
											<p className="ml-2">Peso Bruto</p>
											<input
												disabled
												className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
												type="text"
												defaultValue={partida_orden.Peso_Bruto}
											/>
										</div>
									</div>
								</div>
							</div>
						);
					}
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default AddSalida;

{
}
