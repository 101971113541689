import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';

const Logistica = () => {
	const titles = ['Caja', 'Cliente', 'Transportista', 'Factura', 'Estado', 'Observaciones'];
	const [logistics, setLogistics] = useState([]);

	useEffect(() => {
		const getLogistics = async () => {
			const result = await axiosConfig({
				method: 'get',
				url: '/inventario/logistica'
			});
			setLogistics(result);
		};
		getLogistics();
	}, []);

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Logistica</p>
			</div>
			<table className="text-left mt-2 w-full border-collapse table">
				<thead>
					<tr>
						{titles.map(title => (
							<th className="text-gray-dark" key={title}>
								{title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{logistics.map(box => {
						return (
							<tr key={box.Id_Caja}>
								<td>{box.Numero_Caja}</td>
								<td>{box.cliente_logistica.Nombre_Cliente}</td>
								<td>{box.transportista_logistica.Nombre_Transportista}</td>
								<td>{box.Factura}</td>
								<td>{box.estado_logistica.Estado}</td>
								<td>{box.Observaciones}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Logistica;
