import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListInventario from './ListInventario';
import Movimientos from './Movimientos';
import InventarioCajas from './InventarioCajas';
import AddCaja from './AddCaja';
const Salidas = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Inventario" component={ListInventario} />
			<Route exact path="/Data-System/Inventario/Movimientos" component={Movimientos} />
			<Route exact path="/Data-System/Inventario/Cajas" component={InventarioCajas} />
			<Route exact path="/Data-System/Inventario/Cajas/Nueva" component={AddCaja} />
			<Route render={() => <Redirect to="/Data-System/Inventario" />} />
		</Switch>
	);
};

export default Salidas;
