import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput, WafoFormTextArea } from '@wafo/forms';
import { useAlert } from 'react-alert';

const EnviarEmail = ({ onSubmitSuccess, onReturn, clientId, entrieId }) => {
	const alert = useAlert();
	const [clientEmailsForm, setClientEmailsForm] = useState([]);
	const [keyCount, setKeyCount] = useState(0);
	const [clientEmails, setClientEmails] = useState([]);
	const [defaultValues, setDefaultValues] = useState([]);

	useEffect(() => {
		const getCliente = async () => {
			const clientInfo = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes/getCliente',
				params: { idCliente: clientId }
			});

			let newClientEmails = {};
			const newClientEmailsForm = [];
			let newClientsEmailsObject = {};
			clientInfo.email_cliente.map(({ Email, Id_Email }, index) => {
				newClientsEmailsObject = {
					...newClientsEmailsObject,
					[`emailCliente${index}`]: Email,
					[`idEmail${index}`]: Id_Email
				};
				const clientEmail = {
					id: index
				};
				newClientEmails = {
					...newClientEmails,
					[`emailCliente${index}`]: Email
				};
				newClientEmailsForm.push(clientEmail);
				addClientEmail();
				return null;
			});
			// setDefaultValues(prev => {
			// 	return {
			// 		...prev,
			// 		...newClientsEmailsObject
			// 	};
			// });
			setClientEmailsForm(newClientEmailsForm);
			setKeyCount(newClientEmailsForm.length);

			let emailsInput = '';
			Object.values(newClientEmails).map(
				(email, index) =>
					(emailsInput += `${email} ${
						Object.values(newClientEmails).length === index + 1 ? '' : ','
					} `)
			);

			setClientEmails(newClientEmails);

			setDefaultValues(prev => {
				return {
					...prev,
					...newClientsEmailsObject,
					emails: emailsInput
				};
			});
		};

		if (clientId) {
			getCliente();
		}
		addClientEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const submitEmails = async (form, values) => {
		if (form.valid) {
			if (clientEmailsForm.length > 0) {
				try {
					const { data } = await axiosConfig({
						method: 'post',
						url: '/data-system/entradas/enviarCorreos',
						data: {
							idEntrada: entrieId,
							emailsCliente: Object.values(clientEmails)
						}
					});

					if (data === 'OK') {
						alert.show(`Se han enviado los correos correctamente.`, {
							type: 'success'
						});
						onSubmitSuccess();
					}
				} catch (error) {
					alert.show('Hubo un problema enviando los correos.', {
						type: 'error'
					});
				}
			} else {
				alert.show('No puedes agregar un cliente sin al menos un email', {
					type: 'error'
				});
			}
		}
	};
	const getKey = () => {
		setKeyCount(keyCount + 1);
		return keyCount;
	};
	const addClientEmail = () => {
		const clientEmail = {
			id: getKey()
		};

		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.push(clientEmail);
		setClientEmailsForm(newClientEmails);
	};
	const removeClientEmail = (index, id) => {
		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.splice(index, 1);
		setClientEmailsForm(newClientEmails);
		let newEmails = Object.assign({}, clientEmails);

		delete newEmails[`emailCliente${id}`];
		setClientEmails(newEmails);

		let emailsInput = '';
		Object.values(newEmails).map(
			(email, index) =>
				(emailsInput += `${email} ${Object.values(newEmails).length === index + 1 ? '' : ','} `)
		);
		setDefaultValues({ emails: emailsInput });
	};
	const changeEmailsInput = (event, id) => {
		let newEmails = Object.assign({}, clientEmails);
		const attri = `emailCliente${id}`;
		if (newEmails[attri]) {
			newEmails[attri] = event.target.value;
		} else {
			newEmails = {
				...newEmails,
				[attri]: event.target.value
			};
		}

		setClientEmails(newEmails);

		let emailsInput = '';
		Object.values(newEmails).map(
			(email, index) =>
				(emailsInput += `${email} ${Object.values(newEmails).length === index + 1 ? '' : ','} `)
		);

		setDefaultValues({ emails: emailsInput });
	};

	return (
		<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Enviar correo</p>
				</div>
				<WafoForm formId={'newEntrieForm'} onSubmit={submitEmails} values={defaultValues}>
					<div className="w-full flex flex-col lg:flex-row w-full h-64">
						<div className="flex flex-col w-full">
							<div className="flex flex-col xl:flex-row w-full pr-4 lg:pr-0">
								<WafoFormTextArea
									name="emails"
									placeholder="Emails"
									label="Emails"
									customClass="form-group-lg"
									type="text"
									validations={{
										required: {
											value: true,
											message: 'El campo Emails es requerido'
										}
									}}
									valuesOverride={true}
									extraProps={{
										disabled: true,
										rows: 3
									}}
								/>
							</div>
							<div className="flex flex-row overflow-y-auto">
								<button className="ml-4 h-10 mt-12 button btn-lg bg-green-600" type="submit">
									Enviar Correo
								</button>
								<button
									className="ml-4 h-10 mt-12 button btn-lg bg-red-700"
									onClick={event => {
										event.preventDefault();
										onReturn();
									}}
								>
									Cancelar
								</button>
								<button
									className="ml-4 h-10 mt-12 button btn-lg bg-blue-600"
									onClick={event => {
										event.preventDefault();
										addClientEmail();
									}}
								>
									Agregar Email
								</button>
							</div>
						</div>
						<div className="flex flex-col w-full overflow-y-auto max-h-full ml-4 md:mt-0">
							{clientEmailsForm.map((email, index) => {
								return (
									<div key={email.id} className="flex flex-row w-full">
										<WafoFormInput
											name={`emailCliente${email.id}`}
											placeholder="Email"
											label="Email"
											customClass="form-group-lg"
											type="text"
											validations={{
												required: {
													value: true,
													message: 'El campo Email es requerido'
												},
												email: {
													value: true,
													message: 'Debes ingresar un Email valido'
												}
											}}
											onChangeCallback={event => {
												changeEmailsInput(event, email.id);
											}}
										/>
										<button
											className="ml-4 h-10 mt-12 button btn-md bg-red-700"
											onClick={event => {
												event.preventDefault();
												removeClientEmail(index, email.id);
											}}
										>
											Eliminar
										</button>
									</div>
								);
							})}
						</div>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default EnviarEmail;
