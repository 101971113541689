import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListPaises from './ListPaises';
import AddPais from './AddPais';
import EditPais from './EditPais';
import PrivateRoute from '#components/router/PrivateRoute';

const Paises = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Paises" component={ListPaises} />
			<Route exact path="/Data-System/Catalogos/Paises/Nuevo" component={AddPais} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Paises/Editar"
				component={EditPais}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/Paises" />} />
		</Switch>
	);
};

export default Paises;
