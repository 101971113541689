import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { CustomMultipleFileInput } from '#components/form/controls';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';

const AddCaja = () => {
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [locations, setLocations] = useState([]);
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getLocations = async () => {
			let { locaciones: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/inventario/cajas/locaciones'
			});

			result = result.map(obj => {
				return { display: obj.Locacion, value: String(obj.Id_Locacion) };
			});
			setLocations(result);
		};

		getClients();
		getLocations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const submitBox = async (form, values) => {
		if (form.valid) {
			try {
				const formData = new FormData();
				values.files.map(file => {
					formData.append('archivos', file);
				});
				// let files = Object.values(values.files);
				// files.map(file => {
				// 	formData.append('archivos', file);
				// });
				const boxInfo = {
					caja: values.box,
					locacion: values.location,
					cliente: values.client,
					rampa: values.ramp,
					fecha: moment(values.date).toISOString(),
					cargada: values.loaded,
					comentarios: values.comments
				};
				formData.append('data', JSON.stringify(boxInfo));
				const { data } = await axiosConfig({
					method: 'post',
					url: '/data-system/inventario/cajas/nueva',
					data: formData
				});
				if (data === 'OK') {
					alert.show(`La caja ${values.box} fue agregada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema al agregar la caja, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Inventario/Cajas" />;
	}
	return (
		<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Caja</p>
				</div>
				<WafoForm formId={'newBoxForm'} onSubmit={submitBox}>
					<div className="flex flex-col lg:flex-row w-full  pr-4 md:pr-0">
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormSelect
								name="location"
								defaultValue="Seleccione una Opcion"
								label="Locacion"
								customClass="form-group-lg"
								options={locations}
								validations={{
									required: {
										value: true,
										message: 'El campo Locacion es requerido'
									}
								}}
							/>
							<WafoFormSelect
								name="client"
								defaultValue="Seleccione una Opcion"
								label="Cliente"
								customClass="form-group-lg"
								options={clients}
								validations={{
									required: {
										value: true,
										message: 'El campo Cliente es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormInput
								name="ramp"
								placeholder="Rampa"
								label="Rampa"
								customClass="form-group-lg"
								type="number"
								validations={{
									required: {
										value: true,
										message: 'El campo Rampa es requerido'
									}
								}}
								extraProp={{
									min: 0
								}}
							/>
							<WafoFormInput
								name="box"
								placeholder="Caja"
								label="Caja"
								customClass="form-group-lg"
								type="text"
								validations={{
									required: {
										value: true,
										message: 'El campo Caja es requerido'
									}
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row w-full  pr-4 md:pr-0">
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormSelect
								name="loaded"
								defaultValue="Seleccione una Opcion"
								label="Cargada"
								customClass="form-group-lg"
								options={[
									{
										display: 'Vacia',
										value: '0'
									},
									{
										display: 'Cargada',
										value: '1'
									}
								]}
								validations={{
									required: {
										value: true,
										message: 'El campo Cargada es requerido'
									}
								}}
							/>
							<WafoFormInput
								name="date"
								label="Fecha"
								customClass="form-group-lg"
								type="datetime-local"
								validations={{
									required: {
										value: true,
										message: 'El campo Fecha es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full">
							<CustomMultipleFileInput
								name="files"
								meta={{ label: 'Archivos', accept: '.doc,.pdf,image/*,.xlsx' }}
								handleChange
								multiple={true}
							/>
							<WafoFormTextArea
								name="comments"
								customClass="form-group-lg"
								placeholder="Comentarios."
								label="Comentarios"
								extraProps={{ rows: 3 }}
							/>
						</div>
					</div>
					<button className="button btn-lg bg-green-700">Agregar caja</button>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddCaja;
