import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Clientes from './clientes/Clientes';
import Main from './Main';
import NumerosParte from './numeros-parte/NumerosParte';
import Proveedores from './proveedores/Proveedores';
import Transportistas from './transportistas/Transportistas';
import Usuarios from './usuarios/Usuarios';
import Paises from './paises/Paises';
import UnidadesMedida from './unidades-medida/UnidadesMedida';
import UnidadesBultos from './unidades-bultos/UnidadesBultos';
import Locaciones from './locaciones/Locaciones';
import Almacenes from './almacenes/Almacenes';
import Regimenes from './regimenes/Regimenes';
import Alertas from './alertas/Alertas';
import Estados from './estados/Estados';
import PrivateRoute from '#components/router/PrivateRoute';

const Catalogos = () => {
	return (
		<Switch>
			<Route
				exact
				path="/Data-System/Catalogos"
				render={() => <Redirect to="/Data-System/Catalogos/Main" />}
			/>
			<Route path="/Data-System/Catalogos/Main" component={Main} />
			<Route path="/Data-System/Catalogos/Clientes" component={Clientes} />
			<Route path="/Data-System/Catalogos/NumerosParte" component={NumerosParte} />
			<Route path="/Data-System/Catalogos/Proveedores" component={Proveedores} />
			<Route path="/Data-System/Catalogos/Transportistas" component={Transportistas} />
			<Route path="/Data-System/Catalogos/Paises" component={Paises} />
			<Route path="/Data-System/Catalogos/UnidadesMedida" component={UnidadesMedida} />
			<Route path="/Data-System/Catalogos/UnidadesBulto" component={UnidadesBultos} />
			<Route path="/Data-System/Catalogos/Locaciones" component={Locaciones} />
			<Route path="/Data-System/Catalogos/Almacenes" component={Almacenes} />
			<Route path="/Data-System/Catalogos/Regimenes" component={Regimenes} />
			<Route path="/Data-System/Catalogos/Alertas" component={Alertas} />
			<Route path="/Data-System/Catalogos/Estados" component={Estados} />
			<PrivateRoute path="/Data-System/Catalogos/Usuarios" component={Usuarios} allowed={[3]} />
			<Route render={() => <Redirect to="/" />} />
		</Switch>
	);
};

export default Catalogos;
