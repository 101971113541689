import React, { useState } from 'react';
import { Route, Redirect } from 'react-router';
import { getVerifiedToken } from '#utils/authService';
import { useEffect } from 'react';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
	const [access, setAccess] = useState(undefined);
	useEffect(() => {
		async function checkToken() {
			const verified = await getVerifiedToken();
			if (verified) {
				setAccess(verified.decoded);
			} else {
				setAccess(null);
			}
		}
		checkToken();
	}, []);

	if (access === undefined) {
		return <></>;
	} else if (access == null || (access && !allowed.includes(+access.usuario.Id_Tipo_Usuario))) {
		return <Redirect to="/" />;
	} else if (access) {
		return <Route {...rest} component={Component} />;
	}
};
export default PrivateRoute;
