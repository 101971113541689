import Axios from 'axios';
import { getVerifiedToken } from './authService';

const instance = Axios.create({
	timeout: 15000,
	baseURL: `${process.env.REACT_APP_API_URL}`
});

instance.interceptors.request.use(
	async config => {
		const verified = await getVerifiedToken();
		if (verified) {
			config.headers.Authorization = `Bearer ${verified.token}`;
		}
		return config;
	},
	error => Promise.reject(error)
);

instance.interceptors.response.use(
	async response => {
		if (response.headers['content-type'].indexOf('application/json') !== -1) {
			return response.data;
		}
		return response;
	},
	error => {
		if (error.response) {
			if (error.response.data.type) {
				const errorMessage = error.response.data.type;
				return Promise.reject(errorMessage);
			}
			return Promise.reject(error.response.data);
		} else if (error.request) {
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
);

export default instance;
