import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListClientes from './ListClientes';
import AddCliente from './AddCliente';
import EditCliente from './EditCliente';
import PrivateRoute from '#components/router/PrivateRoute';
const Clientes = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Clientes" component={ListClientes} />
			<Route exact path="/Data-System/Catalogos/Clientes/Nuevo" component={AddCliente} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/Clientes/Editar"
				component={EditCliente}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/Clientes" />} />
		</Switch>
	);
};

export default Clientes;
