import React from 'react';
import { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt, FaCheckCircle } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';

const ListAlertas = () => {
	const [totalRows, setTotalRows] = useState();
	const [alertsPerPage, setAlertsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [search, setSearch] = useState('');
	const titles = ['Titulo', 'Descripcion', 'Condicion de Envio', 'Metodo de Envio', 'Opciones'];
	const [alerts, setAlerts] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentAlert, setCurrentAlert] = useState({});
	const alert = useAlert();

	useEffect(() => {
		getAlerts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, currentPage, alertsPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [alertsPerPage, search]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / alertsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, alertsPerPage]);

	const getAlerts = async () => {
		let { rows, count } = await axiosConfig({
			method: 'get',
			url: '/data-system/alertas',
			params: {
				limit: alertsPerPage,
				paginaActual: currentPage,
				search,
				deshabilitados: true
			}
		});
		if (count > 0) {
			setTotalRows(count);
		} else {
			setTotalRows(1);
		}
		setAlerts(rows);
	};
	const enableDisableAlert = async () => {
		try {
			const { data, message } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Alertas/Habilitar',
				params: {
					idAlerta: currentAlert.Id_Alerta
				}
			});
			if (data === 'OK') {
				alert.show(
					`La alerta ${currentAlert.Titulo} fue ${
						currentAlert.Disabled === 0 ? 'deshabilitada' : 'habilitada'
					} correctamente.`,
					{
						type: 'success'
					}
				);
				getAlerts();
			}
		} catch (error) {
			alert.show(
				`Hubo un problema ${
					currentAlert.Disabled === 0 ? 'deshabilitando' : 'habilitando'
				} la alerta.`,
				{
					type: 'error'
				}
			);
		}
		setShowModal(false);
	};
	function renderModal() {
		if (showModal) {
			return (
				<Modal>
					<p className="text-xl text-black">
						­¿Desea {+currentAlert.Disabled === 1 ? 'Habilitar' : 'Deshabilitar'} Esta Alerta?:
					</p>
					<p className="text-xl text-black">{currentAlert.Titulo}</p>
					<div className="flex flex-row mt-2">
						<button
							className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
							onClick={() => setShowModal(false)}
						>
							Cancelar
						</button>

						<button
							className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
							onClick={() => enableDisableAlert()}
						>
							Confirmar
						</button>
					</div>
				</Modal>
			);
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full w-full md:w-3/4 md:mx-auto">
			<div className="flex flex-col xl:flex-row items-center px-3 bg-gray-100 shadow-md mt-2">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Alertas</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar por titulo o descripcion"
					/>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={alertsPerPage}
							onChange={event => setAlertsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Alertas/Nueva"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Agregar Alerta</button>
					</Link>
					<Link
						className="mt-1"
						to="/Data-System/Catalogos/Alertas/Relacion"
						style={{ textDecoration: 'none' }}
					>
						<button className="button btn-lg bg-green-600 ml-3">Editar alertas de usuarios</button>
					</Link>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{alerts.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{alerts.map(obj => {
								return (
									<tr key={obj.Id_Alerta}>
										<td>{obj.Titulo}</td>
										<td>{obj.Descripcion}</td>
										<td>{obj.Condicion_Envio === 1 ? 'Entradas' : 'Salidas'}</td>
										<td>
											{obj.Metodo_Envio === 1
												? 'Correo'
												: obj.Metodo_Envio === 2
												? 'Notificacion'
												: 'Notificacion y Correo'}
										</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Catalogos/Alertas/Editar',
														state: { alertId: obj.Id_Alerta }
													}}
												>
													<button className="button bg-blue-700 w-8 h-8">
														<FaEdit />
													</button>
												</Link>
												{+obj.Disabled === 1 ? (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-green-700"
														onClick={() => {
															setCurrentAlert(obj);
															setShowModal(true);
														}}
													>
														<FaCheckCircle />
													</button>
												) : (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
														onClick={() => {
															setCurrentAlert(obj);
															setShowModal(true);
														}}
													>
														<FaTrashAlt />
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListAlertas;
