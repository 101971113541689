import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Bar } from 'react-chartjs-2';

const Rendimiento = () => {
	const titles = ['Pre Entradas', 'Entradas', 'Ordenes', 'Salidas'];
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [usersPerfomance, setUsersPerfomance] = useState([]);
	const [usersChartData, setUsersChartData] = useState();
	const [clientsPerfomance, setClientsPerfomance] = useState([]);
	const [clientsChartData, setClientsChartData] = useState();
	const [showUsersPerfomance, setShowUsersPerfomance] = useState(true);
	const [showClientsPerfomance, setShowClientsPerfomance] = useState(false);
	const alert = useAlert();

	useEffect(() => {
		const getUsersPerfomance = async () => {
			let result = await axiosConfig({
				method: 'get',
				url: '/data-system/metricas/rendimiento/usuarios',
				params: {
					fechaInicio: startDate,
					fechaFinal: endDate
				}
			});

			setUsersPerfomance(result);

			let chartData = {
				labels: result.map(x => {
					return x.Usuario;
				}),
				datasets: [
					{
						label: 'Pre Entradas',
						backgroundColor: '#1abc9c',
						hoverBackgroundColor: '#16a085',
						data: result.map(x => {
							return x.Cantidad_Pre_Entradas;
						})
					},
					{
						label: 'Entradas',
						backgroundColor: '#2ecc71',
						hoverBackgroundColor: '#27ae60',
						data: result.map(x => {
							return x.Cantidad_Entradas;
						})
					},
					{
						label: 'Ordenes',
						backgroundColor: '#3498db',
						hoverBackgroundColor: '#2980b9',
						data: result.map(x => {
							return x.Cantidad_Ordenes;
						})
					},
					{
						label: 'Salidas',
						backgroundColor: '#9b59b6',
						hoverBackgroundColor: '#8e44ad',

						data: result.map(x => {
							return x.Cantidad_Salidas;
						})
					}
				]
			};

			setUsersChartData(chartData);
		};
		const getClientsPerfomance = async () => {
			let result = await axiosConfig({
				method: 'get',
				url: '/data-system/metricas/rendimiento/clientes',
				params: {
					fechaInicio: startDate,
					fechaFinal: endDate
				}
			});

			setClientsPerfomance(result);

			let chartData = {
				labels: result.map(x => {
					return x.Cliente;
				}),
				datasets: [
					{
						label: 'Pre Entradas',
						backgroundColor: '#1abc9c',
						hoverBackgroundColor: '#16a085',
						data: result.map(x => {
							return x.Cantidad_Pre_Entradas;
						})
					},
					{
						label: 'Entradas',
						backgroundColor: '#2ecc71',
						hoverBackgroundColor: '#27ae60',
						data: result.map(x => {
							return x.Cantidad_Entradas;
						})
					},
					{
						label: 'Ordenes',
						backgroundColor: '#3498db',
						hoverBackgroundColor: '#2980b9',
						data: result.map(x => {
							return x.Cantidad_Ordenes;
						})
					},
					{
						label: 'Salidas',
						backgroundColor: '#9b59b6',
						hoverBackgroundColor: '#8e44ad',

						data: result.map(x => {
							return x.Cantidad_Salidas;
						})
					}
				]
			};
			setClientsChartData(chartData);
		};

		if (startDate && endDate) {
			getUsersPerfomance();
			getClientsPerfomance();
		}
	}, [startDate, endDate]);

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md justify-between">
				<div className="flex flex-col md:flex-row w-full text-center">
					<p className="justify-center">Filtrar por fecha</p>
					<div className="flex flex-col sm:flex-row w-full justify-start">
						<label className="md:w-2/4 w-full">
							<input
								className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
								id="search"
								type="date"
								onChange={event => setStartDate(event.target.value)}
							/>
						</label>
						<label className="md:w-2/4 w-full ml-1">
							<input
								className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
								id="search"
								type="date"
								min={startDate}
								onChange={event => setEndDate(event.target.value)}
							/>
						</label>
					</div>
				</div>
			</div>

			{/* Zona Usuarios */}
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md justify-between mt-3">
				<p className="text-xl mt-2 w-full">Metricas Rendimiento Usuarios</p>

				<button
					className={`ml-2 button w-24 h-8 ${showUsersPerfomance ? 'bg-red-600' : 'bg-blue-600'} `}
					onClick={() =>
						setShowUsersPerfomance(prev => {
							return !prev;
						})
					}
				>
					{showUsersPerfomance ? 'Ocultar' : 'Mostrar'}
				</button>
			</div>

			{showUsersPerfomance ? (
				<div className="flex flex-col m-w-full justify-between mt-1">
					<div className="bg-gray-100 shadow-md mr-4 w-full self-start">
						<table className="text-left w-full border-collapse table">
							<thead>
								<tr>
									<th>Usuario</th>
									{titles.map(title => (
										<th className="text-gray-dark" key={title}>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{usersPerfomance.map((performance, index) => {
									return (
										<tr key={performance.Usuario + index}>
											<td>{performance.Usuario}</td>
											<td>{performance.Cantidad_Pre_Entradas}</td>
											<td>{performance.Cantidad_Entradas}</td>
											<td>{performance.Cantidad_Ordenes}</td>
											<td>{performance.Cantidad_Salidas}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="bg-gray-100 shadow-md mr-4 mt-2 w-full self-start">
						{usersChartData ? <Bar data={usersChartData} height={100} /> : null}
					</div>
				</div>
			) : null}

			{/* Zona Clientes */}
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md justify-between mt-3">
				<p className="text-xl mt-2 w-full">Metricas Rendimiento Clientes</p>

				<button
					className={`ml-2 button w-24 h-8 ${
						showClientsPerfomance ? 'bg-red-600' : 'bg-blue-600'
					} `}
					onClick={() =>
						setShowClientsPerfomance(prev => {
							return !prev;
						})
					}
				>
					{showClientsPerfomance ? 'Ocultar' : 'Mostrar'}
				</button>
			</div>

			{showClientsPerfomance ? (
				<div className="flex flex-col m-w-full justify-between mt-1">
					<div className="bg-gray-100 shadow-md mr-4 w-full self-start">
						<table className="text-left w-full border-collapse table">
							<thead>
								<tr>
									<th>Cliente</th>
									{titles.map(title => (
										<th className="text-gray-dark" key={title}>
											{title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{clientsPerfomance.map((performance, index) => {
									return (
										<tr key={performance.Cliente + index}>
											<td>{performance.Cliente}</td>
											<td>{performance.Cantidad_Pre_Entradas}</td>
											<td>{performance.Cantidad_Entradas}</td>
											<td>{performance.Cantidad_Ordenes}</td>
											<td>{performance.Cantidad_Salidas}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="bg-gray-100 shadow-md mr-4 mt-2 w-full self-start">
						{clientsChartData ? <Bar data={clientsChartData} height={100} /> : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Rendimiento;
