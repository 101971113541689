import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditUnidadBulto = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultUnit, setDefaultUnit] = useState({});
	const alert = useAlert();

	useEffect(() => {
		const getUnit = async () => {
			const unitInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Unidades/Bultos/GetUnidad',
				params: { idUnidad: state.unitId }
			});

			setDefaultUnit({
				unitId: unitInfo.Id_Unidad_Bulto,
				unit: unitInfo.Unidad_Bulto,
				weigth: unitInfo.Peso_Bulto
			});
		};
		if (state) {
			getUnit();
		}
	}, [state]);

	const submitUnit = async (form, values) => {
		if (form.valid) {
			try {
				const unitInfo = {
					unidad: values.unit,
					idUnidad: values.unitId,
					peso: values.weigth
				};
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Unidades/Bultos/Editar',
					data: unitInfo
				});

				if (data === 'OK') {
					alert.show(`La Unidad ${values.unit} fue editada correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando la unidad, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/UnidadesBulto" />;
	}
	if (state) {
		if (!defaultUnit) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Unidad</p>
					</div>
					<WafoForm values={defaultUnit} formId="editUnitForm" onSubmit={submitUnit}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row w-full">
								<WafoFormInput
									name="unitId"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="unit"
									label="Unidad de Medida"
									placeholder="Unidad"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Unidad de Medida es requerido'
										}
									}}
								/>
								<WafoFormInput
									name="weigth"
									label="Peso Bulto"
									placeholder="Peso"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Peso Bulto es requerido'
										}
									}}
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
								Editar Unidad
							</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/UnidadesBulto" />;
};

export default EditUnidadBulto;
