import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListProveedores from './ListProveedores';
import AddProveedor from './AddProveedor';
import EditProveedor from './EditProveedor';
import PrivateRoute from '#components/router/PrivateRoute';

const Proveedores = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Proveedores" component={ListProveedores} />
			<Route exact path="/Data-System/Catalogos/Proveedores/Nuevo" component={AddProveedor} />
			<Route exact path="/Data-System/Catalogos/Proveedores/Editar" component={EditProveedor} />
			<Route render={() => <Redirect to="/Data-System/Catalogos/Proveedores" />} />
		</Switch>
	);
};

export default Proveedores;
