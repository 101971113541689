import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';

const Ordenes = () => {
	const titles = ['Orden', 'Regimen', 'Fecha', 'Peso Neto', 'Notas', 'Detalle'];
	const [orders, setOrders] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [ordersPerPage, setOrdersPerPage] = useState(20);
	const [totalRows, setTotalRows] = useState();
	const [pageNumbers, setPageNumbers] = useState([]);

	useEffect(() => {
		const getOrders = async () => {
			const { result, FilasTotales } = await axiosConfig({
				method: 'get',
				url: '/inventario/ordenes/cargas',
				params: {
					rowsPorPagina: ordersPerPage,
					paginaActual: currentPage
				}
			});
			if (result) {
				if (FilasTotales > 0) {
					setTotalRows(FilasTotales);
				} else {
					setTotalRows(1);
				}
				setOrders(result);
			}
		};
		getOrders();
	}, [ordersPerPage, currentPage]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / ordersPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, ordersPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [ordersPerPage]);
	const downloadOrderDetails = async (orderId, orderNumber) => {
		const params = {
			idOrden: +orderId
		};
		axiosConfig({
			url: '/data-system/ordenes/reporte',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Orden ${orderNumber}.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
	};
	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Lista de ordenes de carga</p>
				<div className="ml-4 flex flex-row">
					<button
						className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage - 1);
						}}
						disabled={currentPage === 1 ? 'disabled' : ''}
					>
						&laquo;
					</button>
					<select
						value={currentPage}
						name="currentPage"
						id="currentPage"
						onChange={event => setCurrentPage(parseInt(event.target.value))}
						className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						{pageNumbers.map(p => {
							return (
								<option key={p} value={p}>
									{p}
								</option>
							);
						})}
					</select>
					<button
						className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage + 1);
						}}
						disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
					>
						&raquo;
					</button>
				</div>
				<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
					Mostrar
					<select
						value={ordersPerPage}
						onChange={event => setOrdersPerPage(parseInt(event.target.value))}
						className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
						<option value="200">200</option>
					</select>
				</label>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{orders.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{orders.map((order, index) => {
								return (
									<tr key={index}>
										<td>
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadOrderDetails(order.Id_Orden_Carga, order.Orden);
												}}
											>
												{order.Orden}
											</button>
										</td>
										<td>{order.regimen_orden.Nombre_Regimen}</td>
										<td>{order.Fecha}</td>
										<td>{order.orden_partida.partida_orden.Peso_Neto}</td>
										<td>{order.Notas}</td>
										<td>
											<Link
												to={{
													pathname: '/Inventario/Ordenes/Detalles',
													state: { orderId: order.Id_Orden_Carga }
												}}
											>
												Detalles
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Ordenes;
