import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaSquareFull, FaCheckSquare } from 'react-icons/fa';
import moment from 'moment';
import Axios from 'axios';

const Cajas = () => {
	const titles = ['Caja', 'Cargada', 'Locacion', 'Fecha', 'Dias', 'Comentarios', 'Archivos'];
	const [boxes, setBoxes] = useState([]);

	useEffect(() => {
		axiosConfig({
			method: 'get',
			url: '/inventario/cajas'
		})
			.then(data => {
				setBoxes(data);
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	const downloadFiles = async (boxId, boxNumber) => {
		const files = await axiosConfig({
			method: 'get',
			url: '/data-system/inventario/cajas/archivos',
			params: {
				idCaja: boxId
			}
		});

		const params = {
			idCaja: boxId
		};
		axiosConfig({
			url: '/data-system/inventario/cajas/archivos',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Archivos Caja ${boxNumber}.zip`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Cajas</p>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{boxes.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{boxes.map(box => (
								<tr key={box.Numero_Caja}>
									<td>{box.Numero_Caja}</td>
									<td>
										{box.Cargada === 1 ? (
											<FaCheckSquare style={{ color: 'green' }} />
										) : (
											<FaSquareFull style={{ color: 'red' }} />
										)}
									</td>
									<td>{box.locacion_caja.Locacion}</td>
									<td>{moment.utc(box.Fecha_Entrada).format('YYYY-MM-DD')}</td>
									<td>{Math.round(box.Dias)}</td>
									<td>{box.Comentarios}</td>
									<td>
										<button
											className="button btn-md bg-red-700 h-8"
											onClick={() => {
												downloadFiles(box.Id_Caja, box.Numero_Caja);
											}}
											disabled={box.archivo_caja.length > 0 ? false : true}
										>
											Descargar
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Cajas;
