import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListEntradas from './ListEntradas';
import AddEntrada from './AddEntrada';
import EditEntrada from './EditEntrada';
import PrivateRoute from '#components/router/PrivateRoute';
const Entradas = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Entradas" component={ListEntradas} />
			<Route exact path="/Data-System/Entradas/Nueva" component={AddEntrada} />
			<Route exact path="/Data-System/Entradas/Editar" component={EditEntrada} />
			<Route render={() => <Redirect to="/Data-System/Entradas" />} />
		</Switch>
	);
};

export default Entradas;
