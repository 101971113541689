import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';

const Salidas = () => {
	const titles = ['Salida', 'Fecha', 'Factura', 'Pedimento', 'Referencia', 'Despacho', 'Detalles'];
	const [outputs, setOutputs] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [outputsPerPage, setOutputsPerPage] = useState(20);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [totalRows, setTotalRows] = useState();

	useEffect(() => {
		const getOutputs = async () => {
			const { result, FilasTotales } = await axiosConfig({
				method: 'get',
				url: '/inventario/salidas',
				params: {
					rowsPorPagina: outputsPerPage,
					paginaActual: currentPage
				}
			});
			if (result) {
				if (FilasTotales > 0) {
					setTotalRows(FilasTotales);
				} else {
					setTotalRows(1);
				}
				setOutputs(result);
			}
		};
		getOutputs();
	}, [currentPage, outputsPerPage]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / outputsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, outputsPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [outputsPerPage]);

	const downloadOutputDetails = async (outputId, output) => {
		const params = {
			idSalida: +outputId
		};
		axiosConfig({
			url: '/data-system/salidas/reportes',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Salida ${output}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Salidas</p>
				<div className="ml-4 flex flex-row">
					<button
						className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage - 1);
						}}
						disabled={currentPage === 1 ? 'disabled' : ''}
					>
						&laquo;
					</button>
					<select
						value={currentPage}
						name="currentPage"
						id="currentPage"
						onChange={event => setCurrentPage(parseInt(event.target.value))}
						className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						{pageNumbers.map(p => {
							return (
								<option key={p} value={p}>
									{p}
								</option>
							);
						})}
					</select>
					<button
						className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
						onClick={() => {
							setCurrentPage(currentPage + 1);
						}}
						disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
					>
						&raquo;
					</button>
				</div>
				<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
					Mostar
					<select
						value={outputsPerPage}
						onChange={event => setOutputsPerPage(parseInt(event.target.value))}
						className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
						<option value="200">200</option>
					</select>
				</label>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{outputs.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{outputs.map((output, index) => {
								return (
									<tr key={index}>
										<td>
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadOutputDetails(
														output.Id_Salida,
														`${output.Salida}${output.regimen_salida.Nombre_Regimen}`
													);
												}}
											>
												{`${output.Salida}${output.regimen_salida.Nombre_Regimen}`}
											</button>
										</td>
										<td>{output.Fecha}</td>
										<td>{output.Factura}</td>
										<td>{output.Pedimento}</td>
										<td>{output.Referencia}</td>
										<td>{output.Despacho}</td>
										<td>
											<Link
												to={{
													pathname: '/Inventario/Salidas/Detalles',
													state: { outputId: output.Id_Salida }
												}}
											>
												Detalles
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Salidas;
