import React, { useState } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddRegimen = () => {
	const [submitted, setSubmitted] = useState(false);
	const alert = useAlert();
	const submitRegime = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Regimenes/Nuevo',
					data: {
						nombre: values.regimeName,
						dias: values.days
					}
				});

				if (data === 'OK') {
					alert.show(`El Regimen ${values.regimeName} fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema agregando al Regimen.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Regimenes" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Regimen</p>
				</div>
				<WafoForm formId="newCountryForm" onSubmit={submitRegime}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full">
							<WafoFormInput
								name="regimeName"
								label="Nombre Regimen"
								placeholder="Nombre Regimen"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Nombre Regimen es requerido'
									}
								}}
							/>
							<WafoFormInput
								name="days"
								label="Dias"
								placeholder="Dias"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Dias es requerido'
									}
								}}
								type="number"
								extraProps={{
									min: 1,
									step: 1
								}}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
							Agregar Regimen
						</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddRegimen;
