import React, { useEffect, useState } from 'react';
const CustomFileInput = ({ name, valid, touched, meta, handleInputChange }) => {
	const [value, setValue] = useState('');
	useEffect(() => {
		if (handleInputChange) {
			handleInputChange({
				name,
				value
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, name]);
	return (
		<div className="ml-2 mt-2 w-full self-start">
			<p className="text-gray-700 text-lg">{meta.label}</p>
			<input
				className={`flex-grow form-control ${touched && !valid ? 'error' : ''}`}
				onChange={e => setValue(e.target.files[0])}
				placeholder={meta.label}
				name={name}
				type="file"
				accept={meta.accept}
			/>
			<div>
				{!valid && touched ? (
					<p className="text-red-700 text-xs">El campo {meta.label} es requerido</p>
				) : null}
			</div>
		</div>
	);
};
export default CustomFileInput;
