import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListOrdenes from './ListOrdenes';
import AddOrden from './AddOrden';
import EditOrden from './EditOrden';
const Ordenes = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Ordenes" component={ListOrdenes} />
			<Route exact path="/Data-System/Ordenes/Nueva" component={AddOrden} />
			<Route exact path="/Data-System/Ordenes/Editar" component={EditOrden} />
			<Route render={() => <Redirect to="/Data-System/Ordenes" />} />
		</Switch>
	);
};

export default Ordenes;
