import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListNumerosParte from './ListNumerosParte';
import AddNumeroParte from './AddNumeroParte';
import EditNumeroParte from './EditNumeroParte';
import PrivateRoute from '#components/router/PrivateRoute';

const NumerosParte = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/NumerosParte" component={ListNumerosParte} />
			<Route exact path="/Data-System/Catalogos/NumerosParte/Nuevo" component={AddNumeroParte} />
			<PrivateRoute
				exact
				path="/Data-System/Catalogos/NumerosParte/Editar"
				component={EditNumeroParte}
				allowed={[3]}
			/>
			<Route render={() => <Redirect to="/Data-System/Catalogos/NumerosParte" />} />
		</Switch>
	);
};

export default NumerosParte;
