import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditRegimen = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultRegime, setDefaultRegime] = useState({});
	const alert = useAlert();

	useEffect(() => {
		const getRegime = async () => {
			const regimeInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Regimenes/getRegimen',
				params: { idRegimen: state.regimeId }
			});
			setDefaultRegime({
				regimeId: regimeInfo.Id_Regimen,
				regimeName: regimeInfo.Nombre_Regimen,
				days: regimeInfo.Dias
			});
		};
		if (state) {
			getRegime();
		}
	}, [state]);

	const submitRegime = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Regimenes/Editar',
					data: {
						idRegimen: values.regimeId,
						nombre: values.regimeName,
						dias: values.days
					}
				});

				if (data === 'OK') {
					alert.show(`El Regimen ${values.regimeName} fue editado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando al Regimen.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Regimenes" />;
	}
	if (state) {
		if (!defaultRegime) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Regimen</p>
					</div>
					<WafoForm values={defaultRegime} formId="editCountryForm" onSubmit={submitRegime}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row w-full">
								<WafoFormInput
									name="regimeId"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="regimeName"
									label="Nombre Regimen"
									placeholder="Nombre Regimen"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Nombre Regimen es requerido'
										}
									}}
								/>
								<WafoFormInput
									name="days"
									label="Dias"
									placeholder="Dias"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Dias es requerido'
										}
									}}
									type="number"
									extraProps={{
										min: 1,
										step: 1
									}}
								/>
							</div>
							<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
								Editar Regimen
							</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/Regimenes" />;
};

export default EditRegimen;
