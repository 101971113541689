import React, { useState } from 'react';
import { Redirect } from 'react-router';
import axiosConfig from '#utils/axiosConfig';
import { storeToken, getToken } from '#utils/authService';
import jwt from 'jsonwebtoken';

const Login = props => {
	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [authError, setAuthError] = useState('Message');
	const [showAuthError, setShowAuthError] = useState(false);

	function login() {
		axiosConfig({
			method: 'post',
			url: '/auth/login',
			data: {
				user: user,
				password: password
			}
		})
			.then(async ({ token }) => {
				storeToken(token);
				setToken(token);
			})
			.catch(err => {
				if (err === 'Auth/BadUserOrPassword') {
					setAuthError('Usuario y/o Contraseña invalidos');
				} else {
					setAuthError('Espere un momento y vuelva a intentarlo');
				}
				setShowAuthError(true);
				disableError();
			});
	}

	function disableError() {
		setTimeout(() => setShowAuthError(false), 5000);
	}

	if (token) {
		const decoded = jwt.decode(token);
		if (decoded.usuario.Id_Tipo_Usuario === 1) {
			return (
				<Redirect
					to={{
						pathname: '/Inventario',
						state: { from: props.location }
					}}
				/>
			);
		} else {
			return (
				<Redirect
					to={{
						pathname: '/Data-System',
						state: { from: props.location }
					}}
				/>
			);
		}
	}
	return (
		<div className="limiter">
			<div className="container-login100">
				<div className="wrap-login100">
					<div className="login100-form-title">
						<span className="login100-form-title-1">
							Global Ecex <p className="text-white font-bold">Inventario</p>
						</span>
					</div>

					<form
						className="login100-form"
						onSubmit={event => {
							event.preventDefault();
							login();
						}}
					>
						<div className="wrap-input100">
							<span className="label-input100">Username</span>
							<input
								className="input100"
								type="text"
								name="username"
								placeholder="Enter username"
								value={user}
								onChange={event => setUser(event.target.value)}
								required
							/>
							<span className="focus-input100"></span>
						</div>

						<div className="wrap-input100">
							<span className="label-input100">Password</span>
							<input
								className="input100"
								type="password"
								name="pass"
								placeholder="Enter password"
								value={password}
								onChange={event => setPassword(event.target.value)}
								required
							/>
							<span className="focus-input100"></span>
						</div>

						<div className="container-login100-form-btn mt-3">
							<button className="login100-form-btn">Login</button>
						</div>
					</form>
					{showAuthError ? (
						<div className="text-center py-4 lg:px-4">
							<div
								className="p-2 bg-red-800 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex"
								role="alert"
							>
								<span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
									Error
								</span>
								<span className="font-semibold mr-2 text-left flex-auto">{authError}</span>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Login;
