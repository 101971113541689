import React, { useState } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const AddPais = () => {
	const [submitted, setSubmitted] = useState(false);
	const alert = useAlert();
	const submitCountry = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Paises/Nuevo',
					data: values
				});

				if (data === 'OK') {
					alert.show(`El Pais ${values.contrySp} fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema agregando al pais, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};
	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Paises" />;
	}
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Pais</p>
				</div>
				<WafoForm formId="newCountryForm" onSubmit={submitCountry}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
						<div className="flex flex-col md:flex-row w-full pr-2">
							<WafoFormInput
								name="countrySp"
								label="Nombre Español"
								placeholder="Nombre Español"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Nombre Español es requerido'
									}
								}}
							/>
							<WafoFormInput
								name="countryIng"
								label="Nombre Ingles"
								placeholder="Nombre Ingles"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Nombre Ingles es requerido'
									}
								}}
							/>
						</div>
						<div className="flex flex-col md:flex-row w-full pr-2">
							<WafoFormInput
								name="alfa3"
								label="Alfa-3"
								placeholder="Alfa-3"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Alfa-3 es requerido'
									}
								}}
								extraProps={{ maxLength: 3 }}
							/>
							<WafoFormInput
								name="alfa2"
								label="Alfa-2"
								placeholder="Alfa-2"
								customClass="form-group-lg"
								validations={{
									required: {
										value: true,
										message: 'El campo Alfa-2 es requerido'
									}
								}}
								extraProps={{ maxLength: 2 }}
							/>
						</div>

						<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
							Agregar Pais
						</button>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddPais;
