import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal');

const Modal = ({ children, useDesign = true }) => {
	const elRef = useRef(null);
	if (!elRef.current) {
		elRef.current = document.createElement('div');
	}

	useEffect(() => {
		modalRoot.appendChild(elRef.current);
		return () => modalRoot.removeChild(elRef.current);
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	const handleClickOutside = event => {
		if (elRef.current && !elRef.current.contains(event.target)) {
			modalRoot.removeChild(elRef.current);
		}
	};

	return createPortal(
		<div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
			<div
				className={
					useDesign ? 'flex flex-col items-center p-12 bg-white shadow-2xl overflow-y-auto' : ''
				}
				style={{ maxHeight: '80vh', minWidth: '75%' }}
			>
				{children}
			</div>
		</div>,
		elRef.current
	);
};

export default Modal;
