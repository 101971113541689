import React, { useEffect, useState } from 'react';
import {
	WafoForm,
	WafoFormInput,
	WafoFormSelect,
	WafoFormTextArea,
	WafoFormAutocomplete
} from '@wafo/forms';
import { CustomFileInput } from '#components/form/controls';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';

const DetallesSalida = ({ location: { state } }) => {
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [regimes, setRegimes] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [order, setOrder] = useState([]);
	const [client, setClient] = useState();
	const [defaultOutput, setDefaultOutput] = useState({});
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getRegimes = async () => {
			let data = await axiosConfig({
				method: 'get',
				url: '/data-system/salidas/regimenes'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Regimen, value: String(obj.Id_Regimen) };
			});

			setRegimes(data);
		};
		const getCarriers = async () => {
			let { transportistas: carriers } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			carriers = carriers.map(obj => {
				return { display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) };
			});
			setCarriers(carriers);
		};
		const getOutput = async () => {
			const outputInfo = await axiosConfig({
				method: 'get',
				url: '/data-system/salidas/getSalida',
				params: {
					idSalida: state.outputId
				}
			});

			getOrders(outputInfo.orden_salida.orden_cliente.Id_Cliente);
			setDefaultOutput({
				outputId: outputInfo.Id_Salida,
				regime: String(outputInfo.regimen_salida.Id_Regimen),
				date: moment.utc(outputInfo.Fecha).format('YYYY-MM-DD'),
				order: outputInfo.orden_salida.Orden,
				carrier: String(outputInfo.transportista_salida.Id_Transportista),
				box: outputInfo.Numero_Caja,
				stamp: outputInfo.Sello,
				observations: outputInfo.Observaciones,
				bill: outputInfo.Factura,
				petition: outputInfo.Pedimento,
				reference: outputInfo.Referencia,
				plates: outputInfo.Placas,
				receivedBy: outputInfo.Recibido_Por,
				client: String(outputInfo.orden_salida.orden_cliente.Id_Cliente)
			});

			setClient(outputInfo.orden_salida.orden_cliente.Id_Cliente);

			let { orden } = await axiosConfig({
				method: 'get',
				url: '/data-system/ordenes/getOrden',
				params: {
					numeroOrden: outputInfo.orden_salida.Orden,
					idCliente: outputInfo.orden_salida.orden_cliente.Id_Cliente
				}
			});

			setOrder(orden);
		};

		if (state) {
			getClients();
			getCarriers();
			getRegimes();
			getOutput();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitOutput = async (form, values) => {
		if (form.valid) {
			if (order.length > 0) {
				try {
					const output = {
						idSalida: values.outputId,
						regimen: values.regime,
						fecha: moment(form.date.value).toISOString(),
						numeroOrden: values.order.Orden,
						transportista: values.carrier,
						caja: values.box,
						sello: values.stamp,
						observaciones: values.observations,
						factura: values.bill,
						pedimento: values.petition,
						referencia: values.reference,
						placas: values.plates,
						recibido: values.receivedBy
					};
					const formData = new FormData();
					formData.append('packingList', values.packingList);
					formData.append('data', JSON.stringify(output));
					const { data } = await axiosConfig({
						method: 'put',
						url: '/data-system/salidas/editar',
						data: formData
					});

					if (data === 'OK') {
						alert.show(`La salida fue agregada correctamente.`, {
							type: 'success'
						});
						setSubmitted(true);
					}
				} catch (error) {
					alert.show('Hubo un problema al agregar la salida, intentelo mas tarde.', {
						type: 'error'
					});
				}
			} else {
				alert.show('La orden de carga no puede estar vacia', {
					type: 'error'
				});
			}
		}
	};

	const onClientChange = event => {
		setClient(event.target.value);
		getOrders(event.target.value);
	};
	const getOrders = async clientId => {
		let data = await axiosConfig({
			method: 'get',
			url: '/data-system/ordenes/ordenesCliente',
			params: {
				idCliente: clientId
			}
		});
		setOrders(data);
	};
	const onOrderChange = async item => {
		if (item && client) {
			let { orden, preSalida } = await axiosConfig({
				method: 'get',
				url: '/data-system/ordenes/getOrden',
				params: {
					numeroOrden: item.Orden,
					idCliente: client
				}
			});

			if (preSalida) {
				setDefaultOutput({
					...defaultOutput,
					stamp: preSalida.Sello,
					bill: preSalida.Factura,
					box: preSalida.Numero_Caja,
					carrier: String(preSalida.Id_Transportista)
				});
			}
			setOrder(orden);
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Salidas" />;
	}
	if (state) {
		if (!defaultOutput) {
			return <div>Cargando</div>;
		}
		return (
			<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center w-full">
						<p className="text-2xl mt-2">Detalles de Salida</p>
					</div>
					<WafoForm values={defaultOutput} formId={'newOutputForm'} onSubmit={submitOutput}>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0 ">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									type="date"
									name="date"
									label="Fecha"
								/>
								<WafoFormSelect
									name="regime"
									defaultValue="Seleccione una Opcion"
									label="Regimen"
									customClass="form-group-lg"
									options={regimes}
									extraProps={{
										disabled: true
									}}
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									customClass="form-group-lg"
									options={clients}
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormSelect
									name="carrier"
									defaultValue="Seleccione una Opcion"
									label="Transportista"
									customClass="form-group-lg"
									options={carriers}
									extraProps={{
										disabled: true
									}}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="# de Caja"
									type="text"
									name="box"
									label="# de Caja"
								/>

								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="Sello"
									type="text"
									name="stamp"
									label="Sello"
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="Referencia"
									type="text"
									name="reference"
									label="Referencia"
								/>
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="Factura"
									type="text"
									name="bill"
									label="Factura"
								/>
							</div>
						</div>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="Pedimento"
									name="petition"
									label="Pedimento"
								/>

								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass={'form-group-lg'}
									placeholder="Recibido Por"
									name="receivedBy"
									label="Recibido Por"
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									extraProps={{
										disabled: true
									}}
									customClass="w-full md:w-2/4"
									placeholder="Placas"
									name="plates"
									label="Placas"
								/>
							</div>
						</div>
						<div className="w-2/4 flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
							<WafoFormTextArea
								name="observations"
								customClass="form-group-lg md:w-2/4"
								placeholder="Observaciones."
								label="Observaciones"
								extraProps={{ rows: 3, disabled: true }}
							/>
						</div>
					</WafoForm>

					{order.length > 0 ? (
						<p className="text-xl mt-2">Informacion orden de carga {defaultOutput.order}</p>
					) : (
						''
					)}
					{order.map(
						({
							orden_partida,
							orden_partida: {
								partida_orden,
								partida_orden: { numero_parte }
							}
						}) => {
							return (
								<div
									className="flex flex-col w-full p-4 mb-4"
									key={orden_partida.Id_Partida_Entrada}
								>
									<div className="flex flex-col lg:flex-row w-full">
										<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
											<div>
												<p className="ml-2">Numero Parte</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={numero_parte.Numero_Parte}
												/>
											</div>
											<div>
												<p className="ml-2">Piezas</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={orden_partida.Cantidad_Piezas}
												/>
											</div>
											<div>
												<p className="ml-2">UM Pieza</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={partida_orden.unidad_partida.Unidad_Medida}
												/>
											</div>
										</div>
										<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
											<div>
												<p className="ml-2">Bultos</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={String(partida_orden.Cantidad_Bultos)}
												/>
											</div>
											<div>
												<p className="ml-2">UM Bulto</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={partida_orden.unidad_bulto.Unidad_Bulto}
												/>
											</div>
											<div>
												<p className="ml-2">Peso Neto</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={partida_orden.Peso_Neto}
												/>
											</div>
										</div>
										<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
											<div>
												<p className="ml-2">Peso Bruto</p>
												<input
													disabled
													className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
													type="text"
													defaultValue={partida_orden.Peso_Bruto}
												/>
											</div>
										</div>
									</div>
								</div>
							);
						}
					)}
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Salidas/" />;
};

export default DetallesSalida;
