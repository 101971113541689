import React, { useState } from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';

const AddProveedorForm = ({ onSubmitSuccess, onReturn }) => {
	const alert = useAlert();
	const submitProvider = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'post',
					url: '/Data-System/Proveedores/Nuevo',
					data: values
				});

				if (data === 'OK') {
					alert.show(`El Proveedor ${values.proveedor} fue agregado correctamente.`, {
						type: 'success'
					});
					onSubmitSuccess();
				}
			} catch (error) {
				alert.show('Hubo un problema agregando al proveedor, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};
	return (
		<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Proveedor</p>
				</div>
				<WafoForm formId="newProviderForm" onSubmit={submitProvider}>
					<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center pr-2 lg:pr-0">
						<WafoFormInput
							name="proveedor"
							label="Nombre"
							placeholder="Nombre"
							customClass="form-group-lg"
							validations={{
								required: {
									value: true,
									message: 'El campo Nombre es requerido'
								}
							}}
						/>

						<div className="flex flex-row">
							<button className="button btn-lg bg-green-700 mt-3 mb-2" type="submit">
								Agregar Proveedor
							</button>

							<button
								className="ml-2 button btn-lg bg-red-700 mt-3 mb-2"
								onClick={event => {
									event.preventDefault();
									onReturn();
								}}
							>
								Cancelar
							</button>
						</div>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddProveedorForm;
