import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListUnidadesBultos from './ListUnidadesBultos';
import AddUnidadBulto from './AddUnidadBulto';
import EditUnidadBulto from './EditUnidadBulto';
import PrivateRoute from '#components/router/PrivateRoute';

const UnidadesMedida = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/UnidadesBulto" component={ListUnidadesBultos} />
			<Route exact path="/Data-System/Catalogos/UnidadesBulto/Nueva" component={AddUnidadBulto} />
			<Route exact path="/Data-System/Catalogos/UnidadesBulto/Editar" component={EditUnidadBulto} />
			<Route render={() => <Redirect to="/Data-System/Catalogos/UnidadesBulto" />} />
		</Switch>
	);
};

export default UnidadesMedida;
