import React, { useState } from 'react';
import { useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTrashAlt, FaEdit } from 'react-icons/fa';
import Modal from '#components/Modal';
import { useAlert } from 'react-alert';

const ListOrdenes = () => {
	const titles = ['Orden', 'Fecha', 'Cliente', 'Regimen', 'Opciones'];
	const [orders, setOrders] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [ordersPerPage, setOrdersPerPage] = useState(20);
	const [totalRows, setTotalRows] = useState();
	const [pageNumbers, setPageNumbers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [currentOrder, setCurrentOrder] = useState(false);
	const [search, setSearch] = useState();
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState(0);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const alert = useAlert();

	useEffect(() => {
		getClients();
		getOrders();
	}, [ordersPerPage, currentPage, search, client, startDate, endDate]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / ordersPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, ordersPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [ordersPerPage]);

	const getClients = async () => {
		let { result: data } = await axiosConfig({
			method: 'get',
			url: '/data-system/clientes',
			params: {
				paginaActual: 1,
				limit: 100,
				deshabilitados: true
			}
		});
		setClients(data);
	};
	const getOrders = async () => {
		const { result, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/ordenes',
			params: {
				rowsPorPagina: ordersPerPage,
				paginaActual: currentPage,
				search,
				cliente: client,
				fechaInicio: startDate,
				fechaFinal: endDate
			}
		});
		if (result) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}
			setOrders(result);
		}
	};

	const downloadOrderDetails = async (orderId, orderNumber) => {
		const params = {
			idOrden: +orderId
		};
		axiosConfig({
			url: '/data-system/ordenes/reporte',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte Orden ${orderNumber}.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
	};

	const disableOrder = async () => {
		try {
			const { data, message } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Ordenes/Deshabilitar',
				data: {
					idOrden: currentOrder.Id_Orden_Carga
				}
			});

			if (message) {
				alert.show('No puedes deshabilitar esta orden porque esta siendo usada en una salida.', {
					type: 'error'
				});
			} else if (data === 'OK') {
				alert.show(`La Orden ${currentOrder.Orden} fue deshabilitada correctamente.`, {
					type: 'success'
				});
				getOrders();
			}
		} catch (error) {
			alert.show('Hubo un problema deshabilitando la orden.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};
	const enableOrder = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'put',
				url: '/Data-System/Ordenes/Habilitar',
				data: {
					idOrden: currentOrder.Id_Orden_Carga
				}
			});

			if (data === 'OK') {
				alert.show(`La Orden ${currentOrder.Orden} fue habilitada correctamente.`, {
					type: 'success'
				});
				getOrders();
			}
		} catch (error) {
			alert.show('Hubo un problema habilitando la orden.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};

	function renderModal() {
		if (showModal) {
			if (+currentOrder.Disabled === 1) {
				return (
					<Modal>
						<p className="text-xl text-black">­¿Desea Habilitar Esta Orden?:</p>
						<p className="text-xl text-black">{`${currentOrder.Orden}`}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => enableOrder()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			} else {
				return (
					<Modal>
						<p className="text-xl text-black">­¿Desea Deshabilitar Esta Orden?:</p>
						<p className="text-xl text-black">{`${currentOrder.Orden}`}</p>
						<div className="flex flex-row mt-2">
							<button
								className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
								onClick={() => setShowModal(false)}
							>
								Cancelar
							</button>

							<button
								className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
								onClick={() => disableOrder()}
							>
								Confirmar
							</button>
						</div>
					</Modal>
				);
			}
		}
	}

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-col"></div>
				<div className="flex flex-col md:flex-row w-full items-center">
					<p className="text-xl mt-2">Ordenes</p>
					<input
						className="ml-2 w-full bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={search}
						onChange={event => setSearch(event.target.value)}
						placeholder="Buscar orden"
					/>

					<select
						className="mt-1 w-full md:w-1/3 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						onChange={event => setClient(event.target.value)}
						defaultValue="default"
					>
						<option value="default" disabled={true}>
							Seleccione Cliente
						</option>
						<option value="0">Todos</option>
						{clients.map(obj => {
							return (
								<option key={obj.Id_Cliente} value={obj.Id_Cliente}>
									{obj.Nombre_Cliente}
								</option>
							);
						})}
					</select>
				</div>
				<div className="flex flex-col lg:flex-row w-full">
					<div className="flex flex-col md:flex-row w-full text-center">
						<p className="justify-center">Filtrar por fecha</p>
						<div className="flex flex-col sm:flex-row w-full justify-start">
							<label className="md:w-2/4 lg:w-1/4">
								<input
									className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
									id="search"
									type="date"
									value={startDate}
									onChange={event => setStartDate(event.target.value)}
								/>
							</label>
							<label className="md:w-2/4 lg:w-1/4 ml-1">
								<input
									className="w-full ml-2 mt-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
									id="search"
									type="date"
									value={endDate}
									min={startDate}
									onChange={event => setEndDate(event.target.value)}
								/>
							</label>
						</div>
					</div>
					<div className="flex flex-col sm:flex-row">
						<div className="flex flex-row h-12">
							<div className="ml-4 flex flex-row py-1">
								<button
									className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
									onClick={() => {
										setCurrentPage(currentPage - 1);
									}}
									disabled={currentPage === 1 ? 'disabled' : ''}
								>
									&laquo;
								</button>
								<select
									value={currentPage}
									name="currentPage"
									id="currentPage"
									onChange={event => setCurrentPage(parseInt(event.target.value))}
									className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									{pageNumbers.map(p => {
										return (
											<option key={p} value={p}>
												{p}
											</option>
										);
									})}
								</select>
								<button
									className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
									onClick={() => {
										setCurrentPage(currentPage + 1);
									}}
									disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
								>
									&raquo;
								</button>
							</div>
							<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2 flex flex-row">
								<p className="mt-1">Mostar</p>
								<select
									value={ordersPerPage}
									onChange={event => setOrdersPerPage(parseInt(event.target.value))}
									className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
								</select>
							</label>
						</div>
						<Link
							className="mt-1"
							to="/Data-System/Ordenes/Nueva"
							style={{ textDecoration: 'none' }}
						>
							<button className="button btn-lg bg-green-600 ml-3">Agregar Orden</button>
						</Link>
					</div>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{orders.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{orders.map((order, index) => {
								return (
									<tr key={index}>
										<td>
											<button
												className="hover:text-blue-500"
												onClick={event => {
													event.preventDefault();
													downloadOrderDetails(order.Id_Orden_Carga, order.Orden);
												}}
											>
												{order.Orden}
											</button>
										</td>
										<td>{order.Fecha}</td>
										<td>{order.orden_cliente.Nombre_Cliente}</td>
										<td>{order.regimen_orden.Nombre_Regimen}</td>
										<td>
											<div className="flex flex-col lg:flex-row">
												<Link
													to={{
														pathname: '/Data-System/Ordenes/Editar',
														state: { orderId: order.Id_Orden_Carga }
													}}
												>
													<button className="button bg-blue-700 w-7 h-8">
														<FaEdit />
													</button>
												</Link>
												{+order.Disabled === 1 ? (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-green-700"
														onClick={() => {
															setCurrentOrder(order);
															setShowModal(true);
														}}
													>
														<FaCheckCircle />
													</button>
												) : (
													<button
														className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
														onClick={() => {
															setCurrentOrder(order);
															setShowModal(true);
														}}
													>
														<FaTrashAlt />
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
			{renderModal()}
		</div>
	);
};

export default ListOrdenes;
