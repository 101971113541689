import React from 'react';
import Chart from 'react-google-charts';
import { useEffect } from 'react';
import { useState } from 'react';
import axiosConfig from '#utils/axiosConfig';

const Capacidad = () => {
	const [capacity, setCapacity] = useState([
		['Descripcion', 'Cantidad'],
		['Uso', 0],
		['Disponible', 0]
	]);
	useEffect(() => {
		axiosConfig({
			url: '/inventario/capacidad'
		})
			.then(({ Capacidad_Bultos, CapacidadUso }) => {
				let available = 0;
				if (Capacidad_Bultos - CapacidadUso > 0) available = Capacidad_Bultos - CapacidadUso;
				setCapacity([
					['Descripcion', 'Cantidad'],
					['En uso', +CapacidadUso],
					['Disponible', available]
				]);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md w-full">
				<p className="text-xl mt-2">Capacidad de Almacen</p>
			</div>

			<div className="flex flex-col lg:flex-row m-w-full justify-between mt-3">
				<div className="bg-gray-100 shadow-md mr-4 w-full lg:w-2/5 self-start">
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr className="text-gray-dark">
								<th>Capacidad</th>
								<th>En uso</th>
								<th>Disponible</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{capacity[1][1] + capacity[2][1]}</td>
								<td>{capacity[1][1]}</td>
								<td>{capacity[2][1]}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="bg-gray-100 shadow-md w-full lg:w-3/5 self-start mt-2 lg:mt-0">
					<Chart
						width={'auto'}
						height={'25rem'}
						chartType="PieChart"
						loader={<div>Cargando Grafica</div>}
						data={capacity}
						options={{
							title: 'Capacidad de almacen',
							backgroundColor: { fill: 'transparent' },
							is3D: true
						}}
						rootProps={{ 'data-testid': '1' }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Capacidad;
