import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuCard({ title, url, children }) {
	return (
		<Link
			to={url}
			style={{ textDecoration: 'none' }}
			className="no-underline w-full flex flex-col flex-grow-0 border border-gray-400 shadow bg-gray-100 hover:bg-white hover:shadow-lg h-40 m-3 rounded items-center py-4"
		>
			<p className="text-gray-800 text-2xl">{title}</p>
			<p className="text-gray-900 text-6xl mt-2">{children}</p>
		</Link>
	);
}
