import React from 'react';
import Layout from '#shared/Layout';
import { Switch, Route, Redirect } from 'react-router';
import PrivateUserRoute from '#components/router/PrivateUserRoute';
import Main from './pages/Main';
import Entradas from './pages/entradas/Entradas';
import Salidas from './pages/salidas/Salidas';
import Catalogos from './pages/catalogos/Catalogos';
import Inventario from './pages/inventario/Inventario';
import Ordenes from './pages/ordenes/Ordenes';
import Reportes from './pages/Reportes';
import Logs from './pages/Logs';
import Logistica from './pages/logistica/Logistica';
import PreEntradas from './pages/preentradas/PreEntradas';
import Metricas from './pages/metricas/Metricas';
const DataSystem = () => {
	return (
		<Layout>
			<Switch>
				<Route exact path="/Data-System" render={() => <Redirect to="/Data-System/Main" />} />
				<Route path="/Data-System/Main" component={Main} />
				<Route path="/Data-System/Entradas" component={Entradas} />
				<Route path="/Data-System/Salidas" component={Salidas} />
				<Route path="/Data-System/Ordenes" component={Ordenes} />
				<Route path="/Data-System/Inventario" component={Inventario} />
				<Route path="/Data-System/Catalogos" component={Catalogos} />
				<Route path="/Data-System/Reportes" component={Reportes} />
				<Route path="/Data-System/Logs" component={Logs} />
				<Route path="/Data-System/Logistica" component={Logistica} />
				<Route path="/Data-System/PreEntradas" component={PreEntradas} />
				<PrivateUserRoute path="/Data-System/Metricas" component={Metricas} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</Layout>
	);
};

export default DataSystem;
