import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import Modal from '#components/Modal';

const ListLogistica = () => {
	const titles = [
		'Caja',
		'Cliente',
		'Transportista',
		'Factura',
		'Estado',
		'Observaciones',
		'Opciones'
	];
	const [logistics, setLogistics] = useState([]);
	const [currentBox, setCurrentBox] = useState({});
	const [showModal, setShowModal] = useState(false);
	const alert = useAlert();

	useEffect(() => {
		getLogistics();
	}, []);

	const getLogistics = async () => {
		const result = await axiosConfig({
			method: 'get',
			url: '/data-system/logistica'
		});
		setLogistics(result);
	};

	const deleteBox = async () => {
		try {
			const { data } = await axiosConfig({
				method: 'delete',
				url: '/Data-System/Logistica/Eliminar',
				data: {
					idCaja: currentBox.Id_Caja
				}
			});

			if (data === 'OK') {
				alert.show(`La Caja ${currentBox.Numero_Caja} fue eliminada correctamente.`, {
					type: 'success'
				});
				getLogistics();
			}
		} catch (error) {
			alert.show('Hubo un problema al eliminar la caja, intentelo mas tarde.', {
				type: 'error'
			});
		}
		setShowModal(false);
	};
	function renderModal() {
		if (showModal) {
			return (
				<Modal>
					<p className="text-xl text-black">­¿Desea Eliminar Esta Caja?:</p>
					<p className="text-xl text-black">{currentBox.Numero_Caja}</p>
					<div className="flex flex-row mt-2">
						<button
							className="mr-3 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
							onClick={() => setShowModal(false)}
						>
							Cancelar
						</button>

						<button
							className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
							onClick={() => deleteBox()}
						>
							Confirmar
						</button>
					</div>
				</Modal>
			);
		}
	}
	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-row items-center px-3 bg-gray-100 shadow-md">
				<p className="text-xl mt-2">Logistica</p>

				<Link to="/Data-System/Logistica/Agregar">
					<button className="ml-2 button w-48 bg-green-600 h-8">Agregar Registro</button>
				</Link>
			</div>
			<table className="text-left mt-2 w-full border-collapse table">
				<thead>
					<tr>
						{titles.map(title => (
							<th className="text-gray-dark" key={title}>
								{title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{logistics.map(box => {
						return (
							<tr key={box.Id_Caja}>
								<td>{box.Numero_Caja}</td>
								<td>{box.cliente_logistica.Nombre_Cliente}</td>
								<td>{box.transportista_logistica.Nombre_Transportista}</td>
								<td>{box.Factura}</td>
								<td>{box.estado_logistica.Estado}</td>
								<td>{box.Observaciones}</td>
								<td>
									<div className="flex flex-col lg:flex-row">
										<Link
											to={{
												pathname: '/Data-System/Logistica/Editar',
												state: { boxId: box.Id_Caja }
											}}
										>
											<button className="button bg-blue-700 w-8 h-8">
												<FaEdit />
											</button>
										</Link>

										<button
											className="mt-1 lg:mt-0 lg:ml-1 w-8 h-8 button bg-red-700"
											onClick={() => {
												setCurrentBox(box);
												setShowModal(true);
											}}
										>
											<FaTrashAlt />
										</button>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			{renderModal()}
		</div>
	);
};

export default ListLogistica;
