import React, { useEffect, useState } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
const AddLogistica = ({ location: { state } }) => {
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [states] = useState([
		{
			display: 'Rojo',
			value: '1'
		},
		{
			display: 'Amarillo',
			value: '2'
		},
		{
			display: 'Verde',
			value: '3'
		}
	]);
	const [submitted, setSubmitted] = useState(false);
	const [defaultLogistic, setDefaultLogistic] = useState({});
	useEffect(() => {
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getCarriers = async () => {
			let { transportistas: carriers } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			carriers = carriers.map(obj => {
				return { display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) };
			});
			setCarriers(carriers);
		};
		const getLogistics = async () => {
			const logisticsInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Logistica/getLogistica',
				params: { idCaja: state.boxId }
			});

			setDefaultLogistic({
				id: logisticsInfo.Id_Caja,
				box: logisticsInfo.Numero_Caja,
				invoice: logisticsInfo.Factura,
				observations: logisticsInfo.Observaciones,
				client: String(logisticsInfo.cliente_logistica.Id_Cliente),
				carrier: String(logisticsInfo.transportista_logistica.Id_Transportista),
				state: String(logisticsInfo.estado_logistica.Id_Estado)
			});
		};
		getClients();
		getCarriers();
		getLogistics();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitLogistics = async (form, values) => {
		if (form.valid) {
			try {
				const logisticBox = {
					idCaja: values.id,
					caja: values.box,
					factura: values.invoice,
					observaciones: values.observations,
					cliente: values.client,
					transportista: values.carrier,
					estado: values.state
				};
				const { data } = await axiosConfig({
					method: 'put',
					url: '/data-system/logistica/actualizar',
					data: logisticBox
				});

				if (data === 'OK') {
					alert.show(`El registro de logistica fue agregado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema al agregar el registro de logistica, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Logistica" />;
	}
	if (state) {
		if (!defaultLogistic) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center w-full">
						<p className="text-2xl mt-2">Editar Registro Logistica</p>
					</div>
					<WafoForm values={defaultLogistic} formId={'newOutputForm'} onSubmit={submitLogistics}>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0 ">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									name="id"
									label="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Caja es requerido`
										}
									}}
									customClass={'form-group-lg'}
									name="box"
									label="Numero Caja"
								/>
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									customClass="form-group-lg"
									options={clients}
									validations={{
										required: {
											value: true,
											message: 'El campo Cliente es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full md:w-1/2 m-0 p-0">
								<WafoFormSelect
									name="carrier"
									defaultValue="Seleccione una Opcion"
									label="Transportista"
									customClass="form-group-lg"
									options={carriers}
									validations={{
										required: {
											value: true,
											message: 'El campo Transportista es requerido'
										}
									}}
								/>
							</div>
						</div>
						<div className="w-full md:3/4 flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Factura es requerido`
										}
									}}
									customClass={'form-group-lg'}
									name="invoice"
									label="Factura"
								/>
								<WafoFormSelect
									name="state"
									defaultValue="Seleccione una Opcion"
									label="Estado"
									customClass="form-group-lg"
									options={states}
									validations={{
										required: {
											value: true,
											message: 'El campo Estado es requerido'
										}
									}}
								/>
							</div>
						</div>
						<div className="w-full md:w-2/4 flex flex-col lg:flex-row xl:flex-row pr-4 md:pr-0">
							<WafoFormTextArea
								name="observations"
								customClass="form-group-lg"
								placeholder="Observaciones."
								label="Observaciones"
								extraProps={{ rows: 3 }}
							/>
						</div>
						<div className="w-full flex flex-row">
							<button className="ml-2 mt-3 button btn-lg bg-green-600" type="submit">
								Editar Registro
							</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}

	return <Redirect to="/Data-System/Logistica" />;
};

export default AddLogistica;
