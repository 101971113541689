import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import ListUsuarios from './ListUsuarios';
import AddUsuario from './AddUsuario';
import EditUsuario from './EditUsuario';

const Proveedores = () => {
	return (
		<Switch>
			<Route exact path="/Data-System/Catalogos/Usuarios" component={ListUsuarios} />
			<Route exact path="/Data-System/Catalogos/Usuarios/Nuevo" component={AddUsuario} />
			<Route exact path="/Data-System/Catalogos/Usuarios/Editar" component={EditUsuario} />
			<Route render={() => <Redirect to="/Data-System/Catalogos/Usuarios" />} />
		</Switch>
	);
};

export default Proveedores;
