import jwt from 'jsonwebtoken';
import Axios from 'axios';

export function storeToken(token) {
	sessionStorage.setItem('token', token);
}

export function getToken(decode = false) {
	const token = sessionStorage.getItem('token');
	if (decode) {
		const decoded = jwt.decode(token);
		return decoded;
	}
	return token;
}

export async function refreshToken() {
	try {
		await Axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}/auth/refresh`
		})
			.then(({ data }) => {
				storeToken(data.token);
			})
			.catch(() => {
				console.log('Invalid token');
			});
	} catch (error) {
		console.error(error);
	}
}

export function isTokenExpired(token) {
	try {
		const decoded = jwt.decode(token);
		if (decoded.exp < Date.now() / 1000) {
			return true;
		}
		return false;
	} catch (error) {
		return false;
	}
}
//TODO: Cambiar session storage del publickey a localstorage
export async function getPublicKey() {
	try {
		const key = sessionStorage.getItem('publicKey');
		if (!key) {
			const { data } = await Axios({
				method: 'GET',
				url: `${process.env.REACT_APP_API_URL}/auth/publickey`
			});

			if (!data) {
				return null;
			}
			const newKey = data;
			sessionStorage.setItem('publicKey', newKey);
			return newKey;
		}
		return key;
	} catch (error) {
		console.error(error);
	}
}

export async function getVerifiedToken() {
	try {
		const token = getToken();
		if (token) {
			let key = await getPublicKey();
			const decoded = jwt.verify(token, key);
			return { token, decoded };
		}
		return null;
	} catch (error) {
		logout();
		console.error(error);
		return null;
	}
}

export function logout() {
	sessionStorage.clear();
}
