import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddUnidadMedidaForm } from '#forms';

const AddUnidadMedida = () => {
	const history = useHistory();
	const onSubmitSuccess = () => {
		history.push('/data-system/catalogos/UnidadesMedida');
	};

	return <AddUnidadMedidaForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />;
};

export default AddUnidadMedida;
