import React, { useEffect, useState } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { CustomMultipleFileInput, CustomFileInput } from '#components/form/controls';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';
import {
	AddTransportistaForm,
	AddProveedorForm,
	AddUnidadBultoForm,
	AddUnidadMedidaForm,
	EnviarEmail
} from '#forms';
import Modal from '#components/Modal';

const AddEntrada = ({ location: { state } }) => {
	const alert = useAlert();
	const [clients, setClients] = useState([]);
	const [providers, setProviders] = useState([]);
	const [carriers, setCarriers] = useState([]);
	const [lumpsUnit, setLumpsUnit] = useState([]);
	const [measureUnits, setMeasureUnits] = useState([]);
	const [countries, setCountries] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [keyCount, setKeyCount] = useState(0);
	const [entrieDepartureForm, setEntrieDepartureForm] = useState([]);
	const [partNumbersSearch, setPartNumbersSearch] = useState([]);
	const [partNumbersIMMEX, setPartNumbersIMMEX] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [selectedClient, setSelectedClient] = useState(0);
	const [loadType, setLoadType] = useState(1);
	const [masiveLoad, setMasiveLoad] = useState({ errores: [], partidas: [] });
	const [masiveLoadParts, setMasiveLoadParts] = useState([]);
	const [defaultValues, setDefaultValues] = useState({});
	const [users, setUsers] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedAddOption, setSelectedAddOption] = useState();
	const [regimes, setRegimes] = useState([]);
	const [states, setStates] = useState([]);
	const [entrieId, setEntrieId] = useState();

	useEffect(() => {
		const getStates = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/estados'
			});
			const data = rows.map(obj => {
				return { display: obj.Estado, value: String(obj.Id_Estado) };
			});

			setStates(data);
		};
		const getRegimes = async () => {
			let { rows } = await axiosConfig({
				method: 'get',
				url: '/data-system/regimenes'
			});
			const data = rows.map(obj => {
				return { display: obj.Nombre_Regimen, value: String(obj.Id_Regimen) };
			});

			setRegimes(data);
		};
		const getUsers = async () => {
			let { usuarios } = await axiosConfig({
				method: 'get',
				url: '/data-system/usuarios',
				params: {
					deshabilitados: false
				}
			});

			const data = usuarios.map(obj => {
				return { display: obj.Username, value: String(obj.Id_Usuario) };
			});
			setUsers(data);
		};
		const getClients = async () => {
			let { result: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes',
				params: {
					paginaActual: 1,
					limit: 100
				}
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Cliente, value: String(obj.Id_Cliente) };
			});

			setClients(data);
		};
		const getCountries = async () => {
			let { paises: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/paises'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Esp, value: String(obj.Id_Pais) };
			});

			setCountries(data);
		};
		const getWarehouses = async () => {
			let { almacenes: data } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});
			data = data.map(obj => {
				return { display: obj.Nombre_Almacen, value: String(obj.Id_Almacen) };
			});

			setWarehouses(data);
		};
		const getProviders = async () => {
			let { proveedores: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/proveedores'
			});

			let providers = [];
			result.map(obj => {
				providers.push({ display: obj.Nombre_Proveedor, value: String(obj.Id_Proveedor) });
			});
			setProviders(providers);
		};
		const getCarriers = async () => {
			let { transportistas: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});
			let carriers = [];
			result.map(obj => {
				carriers.push({ display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) });
			});
			setCarriers(carriers);
		};
		const getLumpsUnit = async () => {
			let { unidades: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/bultos'
			});

			let lumpsUnit = [];
			result.map(obj => {
				lumpsUnit.push({ display: obj.Unidad_Bulto, value: String(obj.Id_Unidad_Bulto) });
			});
			setLumpsUnit(lumpsUnit);
		};
		const getMeasureUnits = async () => {
			let { unidades: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/medida'
			});

			let measureUnits = [];
			result.map(obj => {
				measureUnits.push({ display: obj.Unidad_Medida, value: String(obj.Id_Unidad_Medida) });
			});
			setMeasureUnits(measureUnits);
		};

		getRegimes();
		getClients();
		getProviders();
		getCarriers();
		getLumpsUnit();
		addPartNumber();
		getMeasureUnits();
		getCountries();
		getUsers();
		getWarehouses();
		getStates();

		const getPreEntrie = async () => {
			const preEntrie = await axiosConfig({
				method: 'get',
				url: '/data-system/entradas/preentradas/getPreEntrada',
				params: {
					idPreEntrada: state.preEntrieId
				}
			});

			setDefaultValues({
				date: moment.utc(preEntrie.Fecha).format('YYYY-MM-DD'),
				numeroEntrada: preEntrie.Numero_Entrada,
				client: String(preEntrie.Id_Cliente),
				box: preEntrie.Numero_Caja,
				tracking: preEntrie.Tracking,
				idPreEntrada: preEntrie.Id_Pre_Entrada
			});

			setSelectedClient(Number(preEntrie.Id_Cliente));
		};

		if (state) {
			getPreEntrie();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getProviders = async () => {
			let { proveedores: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/proveedores'
			});

			let providers = [];
			result.map(obj => {
				providers.push({ display: obj.Nombre_Proveedor, value: String(obj.Id_Proveedor) });
			});
			setProviders(providers);
		};
		const getCarriers = async () => {
			let { transportistas: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/transportistas'
			});

			let carriers = [];
			result.map(obj => {
				carriers.push({ display: obj.Nombre_Transportista, value: String(obj.Id_Transportista) });
			});
			setCarriers(carriers);
		};
		const getLumpsUnit = async () => {
			let { unidades: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/bultos'
			});

			let lumpsUnit = [];
			result.map(obj => {
				lumpsUnit.push({ display: obj.Unidad_Bulto, value: String(obj.Id_Unidad_Bulto) });
			});
			setLumpsUnit(lumpsUnit);
		};
		const getMeasureUnits = async () => {
			let { unidades: result } = await axiosConfig({
				method: 'get',
				url: '/data-system/unidades/medida'
			});

			let measureUnits = [];
			result.map(obj => {
				measureUnits.push({ display: obj.Unidad_Medida, value: String(obj.Id_Unidad_Medida) });
			});
			setMeasureUnits(measureUnits);
		};
		getProviders();
		getCarriers();
		getLumpsUnit();
		getMeasureUnits();
	}, [showModal]);

	const submitEntrie = async (form, values) => {
		if (form.valid) {
			if (
				(loadType === 1 && entrieDepartureForm.length >= 0) ||
				(loadType === 2 && masiveLoad.errores.length < 1)
			) {
				try {
					let entrie = Object.assign({}, values);
					entrie = {
						fecha: moment(form.date.value).toISOString(),
						numeroEntrada: defaultValues.numeroEntrada,
						impoExpo: values.impoExpo,
						cliente: values.client,
						transportista: values.carrier,
						proveedor: values.provider,
						referencia: values.reference,
						caja: values.box,
						sello: values.stamp,
						factura: values.bill,
						tracking: values.tracking,
						po: values.po,
						montacarga: values.forklift,
						packingList: values.packingList,
						almacen: values.warehouse,
						observaciones: values.observations,
						revision: Number(values.pending),
						partidas: loadType === 1 ? getEntrieDepartureValues(values) : masiveLoad.partidas,
						idPreEntrada: defaultValues.idPreEntrada
					};

					const formData = new FormData();
					formData.append('packingList', values.packingList);

					values.files.map(file => {
						formData.append('archivos', file);
					});

					formData.append('data', JSON.stringify(entrie));
					const message = await axiosConfig({
						method: 'post',
						url: '/data-system/entradas/nueva',
						data: formData
					});

					if (message) {
						alert.show(`La entrada ${message.numeroEntrada} fue agregada correctamente.`, {
							type: 'success'
						});
						setSubmitted(true);
						setEntrieId(message.idEntrada);
					} else {
						throw new Error();
					}
				} catch (error) {
					alert.show('Hubo un problema al agregar la entrada, intentelo mas tarde.', {
						type: 'error'
					});
				}
			} else {
				if (loadType === 1) {
					alert.show('No puedes agregar una entrada sin al menos un numero de parte', {
						type: 'error'
					});
				} else {
					alert.show('No puedes agregar una entrada con errores en carga masiva', {
						type: 'error'
					});
				}
			}
		}
	};
	const getEntrieDepartureValues = values => {
		let partNumberRegex = new RegExp(/partNumber\d+/);
		let piecesQuantityRegex = new RegExp(/piecesQuantity\d+/);
		let lumpsQuantityRegex = new RegExp(/lumpsQuantity\d+/);
		let lumpUnitRegex = new RegExp(/lumpsUnit\d+/);
		let netWeightRegex = new RegExp(/netWeight\d+/);
		let grossWeightRegex = new RegExp(/grossWeight\d+/);
		let locationRegex = new RegExp(/location\d+/);
		let PORegex = new RegExp(/po\d+/);
		let measureUnitRegex = new RegExp(/measureUnit\d+/);
		let countryRegex = new RegExp(/country\d+/);
		let fractionRegex = new RegExp(/fraction\d+/);
		let nicoRegex = new RegExp(/nico\d+/);
		let commentsRegex = new RegExp(/comments\d+/);
		let regimeRegex = new RegExp(/regime\d+/);
		let stateRegex = new RegExp(/state\d+/);

		const partNumberKeys = Object.keys(values).filter(key => partNumberRegex.test(key));
		const pieceQuantityKeys = Object.keys(values).filter(key => piecesQuantityRegex.test(key));
		const lumpQuantityKeys = Object.keys(values).filter(key => lumpsQuantityRegex.test(key));
		const lumpUnitKeys = Object.keys(values).filter(key => lumpUnitRegex.test(key));
		const netWeightKeys = Object.keys(values).filter(key => netWeightRegex.test(key));
		const grossWeightKeys = Object.keys(values).filter(key => grossWeightRegex.test(key));
		const locationKeys = Object.keys(values).filter(key => locationRegex.test(key));
		const POKeys = Object.keys(values).filter(key => PORegex.test(key));
		const measureUnitKeys = Object.keys(values).filter(key => measureUnitRegex.test(key));
		const countryKeys = Object.keys(values).filter(key => countryRegex.test(key));
		const fractionKeys = Object.keys(values).filter(key => fractionRegex.test(key));
		const nicoKeys = Object.keys(values).filter(key => nicoRegex.test(key));
		const commentsKeys = Object.keys(values).filter(key => commentsRegex.test(key));
		const regimeKeys = Object.keys(values).filter(key => regimeRegex.test(key));
		const stateKeys = Object.keys(values).filter(key => stateRegex.test(key));

		const departures = [];

		for (let index = 0; index < partNumberKeys.length; index++) {
			departures.push({
				numeroParte: values[partNumberKeys[index]],
				cantidadPiezas: values[pieceQuantityKeys[index]],
				cantidadBultos: values[lumpQuantityKeys[index]],
				unidadBulto: values[lumpUnitKeys[index]],
				pesoNeto: values[netWeightKeys[index]],
				pesoBruto: values[grossWeightKeys[index]],
				locacion: values[locationKeys[index]],
				PO: values[POKeys[index]],
				unidadMedida: values[measureUnitKeys[index]],
				pais: values[countryKeys[index]],
				fraccion: values[fractionKeys[index]],
				nico: values[nicoKeys[index]],
				comentarios: values[commentsKeys[index]],
				regimen: values[regimeKeys[index]],
				estado: values[stateKeys[index]]
			});
		}

		return departures;
	};
	const getKey = () => {
		setKeyCount(keyCount + 1);
		return keyCount;
	};
	const addPartNumber = () => {
		const partNumber = {
			id: getKey()
		};

		const newDeparture = Object.assign([], entrieDepartureForm);
		newDeparture.push(partNumber);
		setEntrieDepartureForm(newDeparture);

		const newPartsNumberSearch = Object.assign([], partNumbersSearch);
		newPartsNumberSearch.push([]);
		setPartNumbersSearch(newPartsNumberSearch);
	};
	const removePartNumber = index => {
		const newDeparture = Object.assign([], entrieDepartureForm);
		newDeparture.splice(index, 1);
		setEntrieDepartureForm(newDeparture);

		const newPartsNumberSearch = Object.assign([], partNumbersSearch);
		newPartsNumberSearch.splice(index, 1);
		setPartNumbersSearch(newPartsNumberSearch);
	};
	const filterPartNumbers = async (search = '', index) => {
		await axiosConfig({
			method: 'get',
			url: '/data-system/partes',
			params: {
				idCliente: selectedClient,
				search,
				paginaActual: 1,
				limit: 20
			}
		}).then(({ partes }) => {
			const newPartNumbersSearch = partNumbersSearch;
			newPartNumbersSearch[index] = partes.map(obj => {
				return {
					display: `${obj.Numero_Parte}-${obj.numero_parte_cliente.Nombre_Cliente}`,
					value: String(obj.Id_Parte)
				};
			});

			setPartNumbersSearch(Object.assign([], newPartNumbersSearch));
		});
	};
	const getClient = event => {
		setSelectedClient(Number(event.target.value));
	};
	const uploadTemplate = async (form, values) => {
		if (form.valid) {
			const formData = new FormData();
			formData.append('template', values.template);
			formData.append('cliente', selectedClient);

			const data = await axiosConfig({
				method: 'post',
				url: '/data-system/entradas/template/leer',
				data: formData
			});

			setMasiveLoad(data);

			if (data.errores.length < 1) {
				const newParts = [];

				await Promise.all(
					data.partidas.map(async (part, index) => {
						const newPart = await getPartNumber(part.numeroParte);
						newParts.push(newPart);
					})
				);
				setMasiveLoadParts(newParts);
			}
		}
	};
	const getPartNumber = async partNumberId => {
		const { Numero_Parte } = await axiosConfig({
			method: 'get',
			url: '/data-system/partes/getParte',
			params: {
				idParte: partNumberId
			}
		});
		return Numero_Parte;
	};
	const downloadTemplate = async () => {
		axiosConfig({
			url: '/data-system/entradas/template',
			method: 'GET',
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Template Carga Masiva.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
	};
	const onPartNumberChange = async (event, index) => {
		const {
			IMMEX,
			Comentarios,
			Fraccion,
			Id_Pais,
			Id_Unidad_Medida,
			Peso_Unidad
		} = await axiosConfig({
			method: 'get',
			url: '/data-system/partes/getParte',
			params: {
				idParte: event.target.value
			}
		});

		let newDefaultValues = Object.assign({}, defaultValues);
		if (IMMEX) {
			const newPartNumbersIMMEX = partNumbersIMMEX;
			newPartNumbersIMMEX[index] = IMMEX;
			setPartNumbersIMMEX(Object.assign([], newPartNumbersIMMEX));
		}
		if (Comentarios) {
			const attri = `comments${index}`;

			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = Comentarios;
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: Comentarios
				};
			}
		}
		if (Id_Unidad_Medida) {
			const attri = `measureUnit${index}`;

			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = String(Id_Unidad_Medida);
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: String(Id_Unidad_Medida)
				};
			}
		}
		if (Peso_Unidad) {
			const attri = `unitWeight${index}`;

			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = Number(Peso_Unidad);
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: Number(Peso_Unidad)
				};
			}
		}
		if (Fraccion) {
			const attri = `fraction${index}`;

			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = Fraccion;
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: Fraccion
				};
			}
		}
		if (Id_Pais) {
			const attri = `country${index}`;

			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = String(Id_Pais);
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: String(Id_Pais)
				};
			}
		}

		setDefaultValues(newDefaultValues);
	};
	const onPiecesQuantityChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `netWeight${index}`;

		const netWeight = Number(newDefaultValues[`unitWeight${index}`]) * Number(event.target.value);
		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = netWeight;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: netWeight
			};
		}

		setDefaultValues(newDefaultValues);
	};
	const onLumpUnitChange = async (event, index) => {
		const { Peso_Bulto } = await axiosConfig({
			method: 'get',
			url: '/data-system/unidades/bultos/getunidad',
			params: {
				idUnidad: event.target.value
			}
		});

		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `lumpWeight${index}`;

		if (Peso_Bulto) {
			if (newDefaultValues[attri]) {
				newDefaultValues[attri] = Peso_Bulto;
			} else {
				newDefaultValues = {
					...newDefaultValues,
					[attri]: Peso_Bulto
				};
			}
		}
		setDefaultValues(newDefaultValues);
	};
	const onLumpsQuantityChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `grossWeight${index}`;

		const grossWeight =
			Number(newDefaultValues[`lumpWeight${index}`]) * Number(event.target.value) +
			Number(newDefaultValues[`netWeight${index}`]);
		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = grossWeight;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: grossWeight
			};
		}

		setDefaultValues(newDefaultValues);
	};
	const onMeasureUnitChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `measureUnit${index}`;

		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = event.target.value;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: event.target.value
			};
		}
		setDefaultValues(newDefaultValues);
	};
	const onCountryChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `country${index}`;

		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = event.target.value;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: event.target.value
			};
		}

		setDefaultValues(newDefaultValues);
	};
	const onFractionChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `fraction${index}`;

		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = event.target.value;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: event.target.value
			};
		}
		setDefaultValues(newDefaultValues);
	};
	const onNicoChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `nico${index}`;

		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = event.target.value;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: event.target.value
			};
		}
		setDefaultValues(newDefaultValues);
	};

	const onCommentsChange = async (event, index) => {
		let newDefaultValues = Object.assign({}, defaultValues);

		const attri = `comments${index}`;

		if (newDefaultValues[attri]) {
			newDefaultValues[attri] = event.target.value;
		} else {
			newDefaultValues = {
				...newDefaultValues,
				[attri]: event.target.value
			};
		}

		setDefaultValues(newDefaultValues);
	};
	function renderModal() {
		const onSubmitSuccess = () => {
			setShowModal(false);
		};

		if (showModal) {
			switch (selectedAddOption) {
				case 1:
					return (
						<Modal useDesign={false}>
							<AddTransportistaForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />
						</Modal>
					);
				case 2:
					return (
						<Modal useDesign={false}>
							<AddProveedorForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />
						</Modal>
					);
				case 3:
					return (
						<Modal useDesign={false}>
							<AddUnidadMedidaForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />
						</Modal>
					);
				case 4:
					return (
						<Modal useDesign={false}>
							<AddUnidadBultoForm onSubmitSuccess={onSubmitSuccess} onReturn={onSubmitSuccess} />
						</Modal>
					);
				case 5:
					return (
						<Modal useDesign={false}>
							<EnviarEmail
								onSubmitSuccess={onSubmitSuccess}
								onReturn={onSubmitSuccess}
								clientId={selectedClient}
								entrieId={entrieId}
							/>
						</Modal>
					);
			}
		}
	}

	// if (submitted) {
	// 	return <Redirect to="/Data-System/Entradas" />;
	// }

	return (
		<div>
			<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
				<div className="flex flex-col w-full p-4 mb-4 items-center">
					<p className="text-2xl mt-2">Agregar Entrada</p>
					<WafoForm formId={'newEntrieForm'} onSubmit={submitEntrie} values={defaultValues}>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0 ">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Fecha es requerido`
										}
									}}
									customClass={'form-group-lg'}
									type="date"
									name="date"
									label="Fecha"
								/>

								<WafoFormSelect
									name="impoExpo"
									defaultValue="Seleccione una Opcion"
									label="Impo/Expo"
									customClass="form-group-lg"
									options={[
										{ display: 'Impo', value: 'Impo' },
										{ display: 'Expo', value: 'Expo' }
									]}
									validations={{
										required: {
											value: true,
											message: 'El campo Impo/Expo es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormSelect
									name="forklift"
									defaultValue="Seleccione una Opcion"
									label="Montacarga"
									customClass="form-group-lg"
									options={users}
									validations={{
										required: {
											value: true,
											message: 'El campo Montacarga es requerido'
										}
									}}
								/>
								<WafoFormSelect
									name="client"
									defaultValue="Seleccione una Opcion"
									label="Cliente"
									customClass="form-group-lg"
									options={clients}
									validations={{
										required: {
											value: true,
											message: 'El campo Cliente es requerido'
										}
									}}
									onChangeCallback={getClient}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormSelect
									name="carrier"
									defaultValue="Seleccione una Opcion"
									label="Transportista"
									customClass="form-group-lg"
									options={carriers}
									validations={{
										required: {
											value: true,
											message: 'El campo Transportista es requerido'
										}
									}}
								/>
								<button
									className={`button bg-blue-600 ml-2 mt-12 h-10`}
									onClick={e => {
										e.preventDefault();
										setSelectedAddOption(1);
										setShowModal(true);
									}}
								>
									+
								</button>
								<WafoFormSelect
									name="provider"
									defaultValue="Seleccione una Opcion"
									label="Proveedor"
									customClass="form-group-lg"
									options={providers}
									validations={{
										required: {
											value: true,
											message: 'El campo Proveedor es requerido'
										}
									}}
								/>
								<button
									className={`button bg-blue-600 ml-2 mt-12 h-10`}
									onClick={e => {
										e.preventDefault();
										setSelectedAddOption(2);
										setShowModal(true);
									}}
								>
									+
								</button>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormSelect
									name="warehouse"
									defaultValue="Seleccione una Opcion"
									label="Almacen"
									customClass="form-group-lg"
									options={warehouses}
									validations={{
										required: {
											value: true,
											message: 'El campo Almacen es requerido'
										}
									}}
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Referencia es requerido`
										}
									}}
									extraProps={{
										maxLength: 30
									}}
									customClass={'form-group-lg'}
									placeholder="Referencia"
									type="text"
									name="reference"
									label="Referencia"
								/>
							</div>
						</div>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo # de Caja es requerido`
										}
									}}
									extraProps={{
										maxLength: 20
									}}
									customClass={'form-group-lg'}
									placeholder="# de Caja"
									type="text"
									name="box"
									label="# de Caja"
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Sello es requerido`
										}
									}}
									extraProps={{
										maxLength: 20
									}}
									customClass={'form-group-lg'}
									placeholder="Sello"
									type="text"
									name="stamp"
									label="Sello"
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo PO es requerido`
										}
									}}
									extraProps={{
										maxLength: 20
									}}
									customClass={'form-group-lg'}
									placeholder="PO"
									type="text"
									name="po"
									label="PO"
								/>
								<WafoFormSelect
									name="pending"
									defaultValue="Seleccione una Opcion"
									label="Revision Pendiente"
									customClass="form-group-lg"
									options={[
										{ display: 'Si', value: '1' },
										{ display: 'No', value: '0' }
									]}
									validations={{
										required: {
											value: true,
											message: 'El campo Revision Pendiente es requerido'
										}
									}}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col lg:flex-row xl:flex-row  pr-4 md:pr-0">
							<div className="flex flex-col md:flex-row w-full m-0 p-0">
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Factura es requerido`
										}
									}}
									extraProps={{
										maxLength: 50
									}}
									customClass={'form-group-lg'}
									placeholder="Factura"
									type="text"
									name="bill"
									label="Factura"
								/>
								<WafoFormInput
									validations={{
										required: {
											value: true,
											message: `El campo Tracking es requerido`
										}
									}}
									extraProps={{
										maxLength: 50
									}}
									customClass={'form-group-lg'}
									placeholder="Tracking"
									type="text"
									name="tracking"
									label="Tracking"
								/>
							</div>
							<div className="flex flex-col md:flex-row md:w-1/2 w-full m-0 p-0">
								<CustomFileInput
									name="packingList"
									meta={{ label: 'Packing List', accept: 'application/pdf' }}
									handleChange
								/>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row xl:flex-row w-2/4  pr-4 md:pr-0">
							<CustomMultipleFileInput
								name="files"
								meta={{ label: 'Archivos', accept: '.doc,.pdf,image/*,.xlsx' }}
								handleChange
								multiple={true}
							/>
							<WafoFormTextArea
								name="observations"
								customClass="form-group-lg"
								placeholder="Observaciones."
								label="Observaciones"
								extraProps={{ rows: 3 }}
							/>
						</div>
						<div className="w-full flex flex-row">
							{loadType === 1 ? (
								<button
									className="ml-2 mt-3 button btn-lg bg-teal-600"
									onClick={e => {
										e.preventDefault();
										setLoadType(2);
									}}
								>
									Cambiar a Carga Masiva
								</button>
							) : (
								<button
									className="ml-2 mt-3 button btn-lg bg-teal-600"
									onClick={e => {
										e.preventDefault();
										setLoadType(1);
									}}
								>
									Cambiar a Carga Individual
								</button>
							)}
							{loadType === 1 ? (
								<button
									className="ml-2 mt-3 button btn-lg bg-blue-600"
									onClick={e => {
										e.preventDefault();
										addPartNumber();
									}}
								>
									Agregar Num Parte
								</button>
							) : null}

							<button
								className="ml-2 mt-3 button btn-lg bg-green-600"
								type="submit"
								disabled={submitted}
							>
								Agregar Entrada
							</button>
							<button
								className="ml-2 mt-3 button btn-lg bg-indigo-600"
								onClick={e => {
									e.preventDefault();

									if (entrieId) {
										setSelectedAddOption(5);
										setShowModal(true);
									} else {
										alert.show('Debes guardar la entrada antes de enviar correos de la misma.', {
											type: 'error'
										});
									}
								}}
							>
								Enviar Correos
							</button>
						</div>
						{loadType === 1
							? entrieDepartureForm.map((partNumber, index) => {
									return (
										<div key={partNumber.id} className="flex flex-col mt-2">
											<div className="flex flex-row items-center">
												<p className="text-gray-700 text-lg mt-2">Partida {+index + 1}</p>
												<button
													className={`button bg-red-600 ml-2 mt-1 h-5`}
													onClick={e => {
														e.preventDefault();
														removePartNumber(partNumber.id);
													}}
												>
													Eliminar
												</button>
											</div>
											<div className="flex flex-col">
												<div className="flex flex-col xl:flex-row pr-4 md:pr-0">
													<div className="w-full xl:w-1/4">
														<div className="ml-2 mt-2  self-start">
															<p className="text-gray-700 text-lg">Buscar</p>
															<input
																className="flex-grow form-control"
																onChange={e => {
																	e.preventDefault();
																	filterPartNumbers(e.target.value, partNumber.id);
																}}
																placeholder="Buscar # de Parte"
																name={`buscador${partNumber.id}`}
																type="text"
																ignoreinput="true"
															/>
														</div>
													</div>

													<div className="flex flex-col lg:flex-row w-full">
														<WafoFormSelect
															validations={{
																required: {
																	value: true,
																	message: `El campo # de Parte es requerido`
																}
															}}
															name={`partNumber${partNumber.id}`}
															options={partNumbersSearch[partNumber.id]}
															defaultValue="Seleccione una Opcion"
															label="# Parte"
															customClass="form-group-lg"
															onChangeCallback={e => onPartNumberChange(e, partNumber.id)}
														/>
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo Cantidad Piezas es requerido`
																}
															}}
															customClass={'form-group-lg'}
															placeholder="Cantidad Piezas"
															type="number"
															name={`piecesQuantity${partNumber.id}`}
															label="Cant. Piezas"
															extraProps={{
																min: 0,
																step: 'any'
															}}
															onChangeCallback={e => onPiecesQuantityChange(e, partNumber.id)}
														/>
														<WafoFormSelect
															name={`measureUnit${partNumber.id}`}
															defaultValue="Seleccione una Opcion"
															label="Unidad Medida"
															customClass="form-group-lg"
															options={measureUnits}
															validations={{
																required: {
																	value: true,
																	message: `El campo Unidad Medida es requerido`
																}
															}}
															valuesOverride={true}
															onChangeCallback={e => onMeasureUnitChange(e, partNumber.id)}
														/>
														<button
															className={`button bg-blue-600 ml-2 mt-12 h-10`}
															onClick={e => {
																e.preventDefault();
																setSelectedAddOption(3);
																setShowModal(true);
															}}
														>
															+
														</button>
													</div>

													<div className="flex flex-col lg:flex-row w-full">
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo PO es requerido`
																}
															}}
															extraProps={{
																maxLength: 20
															}}
															customClass={'form-group-lg'}
															placeholder="PO"
															type="text"
															name={`po${partNumber.id}`}
															label="PO"
														/>
														<WafoFormSelect
															name={`country${partNumber.id}`}
															defaultValue="Seleccione una Opcion"
															label="Pais Origen"
															customClass="form-group-lg"
															options={countries}
															validations={{
																required: {
																	value: true,
																	message: `El campo Pais Origen es requerido`
																}
															}}
															valuesOverride={true}
															onChangeCallback={e => onCountryChange(e, partNumber.id)}
														/>
														<WafoFormInput
															name={`fraction${partNumber.id}`}
															label="Fraccion"
															placeholder="Fraccion"
															customClass="form-group-lg"
															validations={{
																required: {
																	value: true,
																	message: 'El campo Fraccion es requerido'
																},
																regex: {
																	value: /\d{6,10}/,
																	message: 'Fraccion invalida'
																}
															}}
															extraProps={{
																maxLength: 10
															}}
															valuesOverride={true}
															onChangeCallback={e => onFractionChange(e, partNumber.id)}
														/>
														<WafoFormInput
															name={`nico${partNumber.id}`}
															label="Nico"
															placeholder="Nico"
															customClass="form-group-lg"
															validations={{
																required: {
																	value: true,
																	message: 'El campo Nico es requerido'
																},
																regex: {
																	value: /\d{2}/,
																	message: 'Nico invalido'
																}
															}}
															extraProps={{
																maxLength: 10
															}}
															valuesOverride={true}
															onChangeCallback={e => onNicoChange(e, partNumber.id)}
														/>
													</div>
												</div>
												<div className="flex flex-col xl:flex-row pr-4 md:pr-0">
													<div className="flex flex-col lg:flex-row w-full">
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo Cantidad Bultos es requerido`
																}
															}}
															extraProps={{
																min: 0
															}}
															customClass={'form-group-lg'}
															placeholder="Cantidad Bultos"
															type="number"
															name={`lumpsQuantity${partNumber.id}`}
															label="Cant. Bultos"
															onChangeCallback={e => onLumpsQuantityChange(e, partNumber.id)}
														/>
														<WafoFormSelect
															name={`lumpsUnit${partNumber.id}`}
															defaultValue="Seleccione una Opcion"
															label="Unidad Bultos"
															customClass="form-group-lg"
															options={lumpsUnit}
															validations={{
																required: {
																	value: true,
																	message: `El campo Unidad Bultos es requerido`
																}
															}}
															onChangeCallback={e => onLumpUnitChange(e, partNumber.id)}
														/>
														<button
															className={`button bg-blue-600 ml-2 mt-12 h-10`}
															onClick={e => {
																e.preventDefault();
																setSelectedAddOption(4);
																setShowModal(true);
															}}
														>
															+
														</button>
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo Peso Neto es requerido`
																}
															}}
															customClass={'form-group-lg'}
															placeholder="Peso Neto"
															type="number"
															name={`netWeight${partNumber.id}`}
															label="Peso Neto"
															extraProps={{
																min: 0,
																step: 'any'
															}}
															valuesOverride={true}
														/>
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo Peso Bruto es requerido`
																}
															}}
															customClass={'form-group-lg'}
															placeholder="Peso Bruto"
															type="number"
															name={`grossWeight${partNumber.id}`}
															label="Peso Bruto"
															extraProps={{
																min: 0,
																step: 'any'
															}}
															valuesOverride={true}
														/>
													</div>
													<div className="flex flex-col lg:flex-row w-full">
														<WafoFormInput
															validations={{
																required: {
																	value: true,
																	message: `El campo Locacion es requerido`
																}
															}}
															extraProps={{
																maxLength: 45
															}}
															customClass={'form-group-lg'}
															placeholder="Locacion"
															name={`location${partNumber.id}`}
															label="Locacion"
														/>
														<WafoFormSelect
															name={`regime${partNumber.id}`}
															defaultValue="Seleccione una Opcion"
															label="Regimen"
															customClass="form-group-lg"
															options={regimes}
															validations={{
																required: {
																	value: true,
																	message: `El campo Regimen es requerido`
																}
															}}
														/>
														<WafoFormSelect
															name={`state${partNumber.id}`}
															defaultValue="Seleccione una Opcion"
															label="Estado"
															customClass="form-group-lg"
															options={states}
															validations={{
																required: {
																	value: true,
																	message: `El campo Estado es requerido`
																}
															}}
														/>
														<div className="ml-2 mt-2 w-full self-start">
															<p className="text-gray-700 text-lg">IMMEX</p>
															<input
																className="flex-grow form-control"
																disabled
																defaultValue={partNumbersIMMEX[partNumber.id]}
																ignoreinput="true"
															/>
														</div>
													</div>
												</div>
											</div>
											<WafoFormTextArea
												name={`comments${partNumber.id}`}
												customClass="form-group-md"
												placeholder="Comentarios."
												label="Comentarios"
												extraProps={{ rows: 3 }}
												valuesOverride={true}
												onChangeCallback={e => onCommentsChange(e, partNumber.id)}
											/>
										</div>
									);
							  })
							: null}
					</WafoForm>
				</div>
			</div>
			{loadType === 2 ? (
				<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto p-4">
					<WafoForm formId={'templateForm'} onSubmit={uploadTemplate}>
						<div className="flex flex-row w-2/4">
							<CustomFileInput
								name="template"
								meta={{
									label: 'Template',
									accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								}}
								handleChange
							/>
							<button className="mt-12 button btn-lg bg-green-600 h-10" type="submit">
								Cargar
							</button>
							<button
								className="mt-12 ml-2 button w-64 bg-blue-600 h-10"
								onClick={e => {
									e.preventDefault();
									downloadTemplate();
								}}
							>
								Descargar
							</button>
						</div>
					</WafoForm>
					{masiveLoad.partidas && masiveLoad.errores.length < 1 ? (
						masiveLoad.partidas.map((partNumber, index) => {
							const measureUnit = measureUnits.filter(
								unit => unit.value === String(partNumber.unidadMedida)
							)[0];
							const lumpUnit = lumpsUnit.filter(
								unit => unit.value === String(partNumber.unidadBulto)
							)[0];

							const regime = regimes.filter(unit => unit.value === String(partNumber.regimen))[0];
							const state = states.filter(unit => unit.value === String(partNumber.estado))[0];

							const country = countries.filter(unit => unit.value === String(partNumber.pais))[0];
							return (
								<div key={index} className="flex flex-col mt-2">
									<div className="flex flex-row items-center">
										<p className="text-gray-700 text-lg mt-2">Partida {+index + 1}</p>
									</div>
									<div className="flex flex-col ">
										<div className="flex flex-col xl:flex-row pr-4 md:pr-0">
											<div className="flex flex-col lg:flex-row w-full">
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg"># Parte</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={masiveLoadParts[index]}
														disabled
													/>
												</div>
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">Cantidad Piezas</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={partNumber.cantidadPiezas}
														disabled
													/>
												</div>
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">Unidad Medida</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={measureUnit.display}
														disabled
													/>
												</div>
											</div>
											<div className="flex flex-col lg:flex-row w-full">
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">PO</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={partNumber.PO}
														disabled
													/>
												</div>
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">Pais</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={country.display}
														disabled
													/>
												</div>
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">Fraccion</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={partNumber.fraccion}
														disabled
													/>
												</div>
												<div className="ml-2 mt-2 w-full self-start">
													<p className="text-gray-700 text-lg">Nico</p>
													<input
														className="flex-grow form-control"
														type="text"
														defaultValue={partNumber.nico}
														disabled
													/>
												</div>
											</div>
										</div>
										<div className="flex flex-col xl:flex-row pr-4 md:pr-0">
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Cantidad Bultos</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={partNumber.cantidadBultos}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Unidad Bultos</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={lumpUnit.display}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Peso Neto</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={partNumber.pesoNeto}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Peso Bruto</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={partNumber.pesoBruto}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Locacion</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={partNumber.locacion}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">IMMEX</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={partNumber.IMMEX}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Estado</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={state.display}
													disabled
												/>
											</div>
											<div className="ml-2 mt-2 w-full self-start">
												<p className="text-gray-700 text-lg">Regimen</p>
												<input
													className="flex-grow form-control"
													type="text"
													defaultValue={regime.display}
													disabled
												/>
											</div>
										</div>
										<div className="ml-2 mt-2 w-full lg:w-2/4 self-start">
											<p className="text-gray-700 text-lg">Comentarios</p>
											<textarea
												className="flex-grow form-control"
												name="comments"
												placeholder="Comentarios."
												rows="3"
												disabled
												defaultValue={partNumber.comentarios}
											></textarea>
										</div>
									</div>
								</div>
							);
						})
					) : masiveLoad.errores.length >= 1 ? (
						<div className="flex flex-col mt-2">
							<div className="flex flex-row items-center">
								<p className="text-gray-700 text-lg mt-2">Errores en carga masiva</p>
							</div>
							<ul>
								{masiveLoad.errores.map((error, index) => {
									return (
										<li key={index} className="p-2 text-red-700">
											{error}
										</li>
									);
								})}
							</ul>
						</div>
					) : null}
				</div>
			) : null}

			{renderModal()}
		</div>
	);
};

export default AddEntrada;
