import React, { useEffect, useState } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { CustomFileInput } from '#components/form/controls';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';
import moment from 'moment';

const DetallesOrden = ({ location: { state } }) => {
	//TODO: EDITAR CANTIDAD BULTOS DE ORDEN
	const titles = [
		'Entrada',
		'# Parte',
		'Cantidad Piezas',
		'U.M Piezas',
		'Cantidad Bultos',
		'U.M Bultos',
		'Peso Neto',
		'Descripcion Esp',
		'Descripcion Ing',
		'PO',
		'Pais',
		'Fraccion',
		'Marca',
		'Modelo',
		'Serie'
	];
	const [order, setOrder] = useState();
	const [departures, setDepartures] = useState();
	useEffect(() => {
		const getOrden = async () => {
			let { orden, partidas, preSalida } = await axiosConfig({
				method: 'get',
				url: '/data-system/ordenes/getordenid',
				params: {
					idOrden: state.orderId
				}
			});
			setOrder(orden);
			setDepartures(partidas);
		};

		if (state) {
			getOrden();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (state) {
		if ((!order, !departures)) {
			return <div>Cargando</div>;
		}
		return (
			<div>
				<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md my-2">
					<div className="flex flex-row w-full mt-2 md:mt-0">
						<p className="text-xl mt-2">Detalles de Orden {order.Orden}</p>
					</div>
				</div>

				<table className="text-left w-full border-collapse table">
					<thead>
						<tr>
							{titles.map(title => (
								<th className="text-gray-dark" key={title}>
									{title}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{departures.map((departure, index) => {
							return (
								<tr key={index}>
									<td>{departure.partida_orden.partida_entrada.Entrada}</td>
									<td>{departure.partida_orden.numero_parte.Numero_Parte}</td>
									<td>{departure.Cantidad_Piezas}</td>
									<td>{departure.partida_orden.unidad_partida.Unidad_Medida}</td>
									<td>{departure.partida_orden.Cantidad_Bultos}</td>
									<td>{departure.partida_orden.unidad_bulto.Unidad_Bulto}</td>
									<td>{departure.partida_orden.Peso_Neto}</td>
									<td>{departure.partida_orden.numero_parte.Descripcion_Esp}</td>
									<td>{departure.partida_orden.numero_parte.Descripcion_Ing}</td>
									<td>{departure.partida_orden.PO}</td>
									<td>{departure.partida_orden.pais_partida.Nombre_Esp}</td>
									<td>{departure.partida_orden.Fraccion}</td>
									<td>{departure.partida_orden.numero_parte.Marca}</td>
									<td>{departure.partida_orden.numero_parte.Modelo}</td>
									<td>{departure.partida_orden.numero_parte.Serie}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
	return <Redirect to="/Inventario/Ordenes" />;
};

export default DetallesOrden;
