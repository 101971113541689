import React, { useState, useEffect } from 'react';
import MenuCard from '#components/MenuCard';
import {
	FaTruckMoving,
	FaSignOutAlt,
	FaClipboardList,
	FaShippingFast,
	FaBoxes,
	FaChartArea
} from 'react-icons/fa';

import { AiOutlineFileSearch } from 'react-icons/ai';
import { GrCatalog } from 'react-icons/gr';
import { GrDocumentPerformance } from 'react-icons/gr';
import { MdAvTimer } from 'react-icons/md';
import { getVerifiedToken } from '#utils/authService';

//TODO: Agregar boton para ir hacia metricas de rendimiento o tiempo, debe estar solamente habilitado para usuarios identificados por ID
const Main = () => {
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		const getUserInfo = async () => {
			const { decoded } = await getVerifiedToken();
			setUserInfo(decoded.usuario);
		};
		getUserInfo();
	}, []);
	return (
		<div className="w-full px-8 md:px-16 lg:px-32">
			<div className="flex flex-col lg:flex-row justify-center">
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Entradas" url="/Data-System/Entradas">
						<FaTruckMoving />
					</MenuCard>
					<MenuCard title="Salidas" url="/Data-System/Salidas">
						<FaSignOutAlt />
					</MenuCard>
				</div>
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Ordenes" url="/Data-System/Ordenes">
						<FaClipboardList />
					</MenuCard>
					<MenuCard title="Inventario" url="/Data-System/Inventario">
						<FaBoxes />
					</MenuCard>
				</div>
			</div>
			<div className="flex flex-col lg:flex-row justify-center">
				<div className="flex flex-col md:flex-row w-full">
					<MenuCard title="Reportes" url="/Data-System/Reportes">
						<FaChartArea />
					</MenuCard>
					<MenuCard title="Catalogos" url="/Data-System/Catalogos">
						<GrCatalog />
					</MenuCard>
				</div>
				<div
					className={`flex flex-col md:flex-row ${
						userInfo && userInfo.Id_Tipo_Usuario === 3 ? 'w-full' : 'w-2/4'
					}`}
				>
					<MenuCard title="Logistica" url="/Data-System/Logistica">
						<FaShippingFast />
					</MenuCard>
					{userInfo && userInfo.Id_Tipo_Usuario === 3 ? (
						<MenuCard title="Logs" url="/Data-System/Logs">
							<AiOutlineFileSearch />
						</MenuCard>
					) : (
						''
					)}
				</div>
			</div>
			{userInfo && process.env.REACT_APP_METRICS_USERS.includes(userInfo.Id_Usuario) ? (
				<div className="flex flex-col lg:flex-row ">
					<div className="flex flex-col md:flex-row w-full">
						<MenuCard title="Metricas Rendimiento" url="/Data-System/Metricas/Rendimiento">
							<GrDocumentPerformance />
						</MenuCard>
						<MenuCard title="Metricas Tiempo" url="/Data-System/Metricas/Tiempo">
							<MdAvTimer />
						</MenuCard>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Main;
