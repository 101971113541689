import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';

const Inventario = () => {
	const titles = [
		'Entrada',
		'Fecha',
		'Almacen',
		'Referencia',
		'Numero_de_parte',
		'Cant. Piezas',
		'Cant. En Transito',
		'Bultos',
		'Bultos En Transito',
		'Peso Neto',
		'Descripcion Ing',
		'Descripcion Esp',
		'PO',
		'Pais',
		'Fraccion',
		'Marca',
		'Modelo',
		'Serie'
	];
	const [inventory, setInventory] = useState([]);
	const [searchParams, setSearchParams] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState();
	const [pageNumbers, setPageNumbers] = useState([]);
	const [partsPerPage, setPartsPerPage] = useState(20);
	const [warehouses, setWarehouses] = useState([]);
	const [warehouse, setWarehouse] = useState(0);

	useEffect(() => {
		const getInventory = async () => {
			const { result, FilasTotales } = await axiosConfig({
				method: 'get',
				url: '/inventario/',
				params: {
					search: searchParams,
					rowsPorPagina: partsPerPage,
					paginaActual: currentPage,
					almacen: warehouse
				}
			});
			if (result) {
				if (FilasTotales > 0) {
					setTotalRows(FilasTotales);
				} else {
					setTotalRows(1);
				}
				setInventory(result);
			}
		};
		const getWarehouses = async () => {
			let { almacenes } = await axiosConfig({
				method: 'get',
				url: '/data-system/almacenes'
			});

			setWarehouses(almacenes);
		};
		getInventory();
		getWarehouses();
	}, [searchParams, partsPerPage, currentPage, warehouse]);

	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / partsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, partsPerPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [partsPerPage, searchParams]);

	const downloadInventoryReport = async () => {
		const params = {
			search: searchParams,
			almacen: warehouse
		};
		axiosConfig({
			url: '/data-system/inventario/reporte',
			method: 'GET',
			params,
			responseType: 'blob'
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte-Inventario.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
	};
	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col md:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row mt-2 md:mt-0">
					<p className="text-xl mt-2">Inventario</p>
					<input
						className="ml-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
						id="search"
						type="text"
						value={searchParams}
						onChange={event => setSearchParams(event.target.value)}
						placeholder="Buscar numero de parte"
					/>
					<select
						value={warehouse}
						name="warehouse"
						id="warehouse"
						onChange={event => setWarehouse(parseInt(event.target.value))}
						className="appearance-none w-64 ml-2 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					>
						<option value="0">Todos</option>
						{warehouses.map(obj => {
							return (
								<option key={obj.Id_Almacen} value={obj.Id_Almacen}>
									{obj.Nombre_Almacen}
								</option>
							);
						})}
					</select>
					<button
						className="ml-2 mt-1 button w-48 bg-green-600 h-8"
						onClick={event => {
							event.preventDefault();
							downloadInventoryReport();
						}}
					>
						Descargar
					</button>
				</div>
				<div className="flex flex-row">
					<div className="ml-4 flex flex-row my-2">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostar
						<select
							value={partsPerPage}
							onChange={event => setPartsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{inventory.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{inventory.map(
								(
									{
										Peso_Neto,
										partida_entrada,
										partida_inventario,
										numero_parte,
										unidad_partida,
										pais_partida,
										PO,
										Fraccion
									},
									index
								) => {
									const warehouseIndex = warehouses
										.map(x => {
											return x.Id_Almacen;
										})
										.indexOf(partida_inventario.Id_Almacen);

									const warehouseName = warehouses[warehouseIndex].Nombre_Almacen;
									return (
										<tr key={index}>
											<td>{partida_entrada.Entrada}</td>
											<td>{partida_entrada.Fecha}</td>
											<td>{warehouseName}</td>
											<td>{partida_entrada.Referencia}</td>
											<td>{numero_parte.Numero_Parte}</td>
											<td>{`${partida_inventario.Cantidad_Piezas}  ${unidad_partida.Unidad_Medida}`}</td>
											<td>{`${partida_inventario.Piezas_Transito}  ${unidad_partida.Unidad_Medida}`}</td>
											<td>{partida_inventario.Cantidad_Bultos}</td>
											<td>{partida_inventario.Bultos_Transito}</td>
											<td>{Peso_Neto}</td>
											<td>{numero_parte.Descripcion_Ing}</td>
											<td>{numero_parte.Descripcion_Esp}</td>
											<td>{PO}</td>
											<td>
												{pais_partida.Nombre_Esp}-{pais_partida.Alfa3}
											</td>
											<td>{Fraccion}</td>
											<td>{numero_parte.Marca}</td>
											<td>{numero_parte.Modelo}</td>
											<td>{numero_parte.Serie}</td>
										</tr>
									);
								}
							)}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Inventario;
