import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { FaCheckCircle, FaFilePdf, FaTrashAlt, FaEdit } from 'react-icons/fa';
import Modal from '#components/Modal';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import moment from 'moment';
const Logs = () => {
	const titles = ['Fecha', 'Usuario', 'Operacion', 'Tabla'];
	const [logs, setLogs] = useState([]);
	const [search, setSearch] = useState('');
	const [totalRows, setTotalRows] = useState();
	const [logsPerPage, setLogsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumbers, setPageNumbers] = useState([]);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(0);
	const [operationType, setOperationType] = useState(0);

	useEffect(() => {
		setCurrentPage(1);
	}, [logsPerPage]);

	useEffect(() => {
		getLogs();
		getUsers();
	}, [currentPage, logsPerPage, user, operationType]);

	const getLogs = async () => {
		const { bitacoras, FilasTotales } = await axiosConfig({
			method: 'get',
			url: '/data-system/bitacoras',
			params: {
				limit: logsPerPage,
				paginaActual: currentPage,
				idUsuario: user,
				tipoOperacion: operationType
			}
		});
		if (bitacoras) {
			if (FilasTotales > 0) {
				setTotalRows(FilasTotales);
			} else {
				setTotalRows(1);
			}
			setLogs(bitacoras);
		}
	};
	const getUsers = async () => {
		let { usuarios: data } = await axiosConfig({
			method: 'get',
			url: '/data-system/usuarios',
			params: {
				paginaActual: 1,
				limit: 100,
				deshabilitados: true
			}
		});
		setUsers(data);
	};
	useEffect(() => {
		const auxPageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalRows / logsPerPage); i++) {
			auxPageNumbers.push(i);
		}
		setPageNumbers(auxPageNumbers);
	}, [totalRows, logsPerPage]);

	return (
		<div className="mt-4 flex flex-col m-h-full h-full">
			<div className="flex flex-col lg:flex-row items-center px-3 bg-gray-100 shadow-md">
				<div className="flex flex-row items-center">
					<p className="text-xl mt-2">Logs</p>
					<select
						className="mt-1 w-full md:w-2/3 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						onChange={event => setUser(event.target.value)}
						defaultValue="0"
					>
						<option value="0" disabled="true">
							Seleccione Usuario
						</option>
						<option value="0">Todos</option>
						{users.map(obj => {
							return (
								<option key={obj.Id_Usuario} value={obj.Id_Usuario}>
									{obj.Username}
								</option>
							);
						})}
					</select>
					<select
						className="mt-1 w-full md:w-2/3 md:mt-0 ml-2 bg-gray-200 appearance-none border border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						onChange={event => setOperationType(event.target.value)}
						defaultValue="0"
					>
						<option value="0" disabled="true">
							Seleccione Operacion
						</option>
						<option value="0">Todas</option>
						<option value="1">Creacion</option>
						<option value="2">Edicion</option>
						<option value="3">Eliminacion</option>
					</select>
					<div className="ml-4 flex flex-row">
						<button
							className={'text-xl ' + (currentPage === 1 ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage - 1);
							}}
							disabled={currentPage === 1 ? 'disabled' : ''}
						>
							&laquo;
						</button>
						<select
							value={currentPage}
							name="currentPage"
							id="currentPage"
							onChange={event => setCurrentPage(parseInt(event.target.value))}
							className="appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							{pageNumbers.map(p => {
								return (
									<option key={p} value={p}>
										{p}
									</option>
								);
							})}
						</select>
						<button
							className={'text-xl ' + (currentPage === pageNumbers.length ? 'opacity-50' : '')}
							onClick={() => {
								setCurrentPage(currentPage + 1);
							}}
							disabled={currentPage === pageNumbers.length ? 'disabled' : ''}
						>
							&raquo;
						</button>
					</div>
				</div>
				<div className="flex flex-row">
					<label className="ml-2 mt-2 text-gray-700 text-xs font-bold mb-2">
						Mostrar
						<select
							value={logsPerPage}
							onChange={event => setLogsPerPage(parseInt(event.target.value))}
							className="ml-2 appearance-none w-20 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
						</select>
					</label>
				</div>
			</div>

			<div className="flex bg-gray-100 shadow-md mt-3 overflow-y-auto">
				{logs.length === 0 ? (
					<h4>Sin Resultados</h4>
				) : (
					<table className="text-left w-full border-collapse table">
						<thead>
							<tr>
								{titles.map(title => (
									<th className="text-gray-dark" key={title}>
										{title}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{logs.map(log => {
								const logsDateTime = moment.utc(log.Created_at).toDate();
								const localDateTime = moment(logsDateTime)
									.local()
									.format('YYYY-MM-DD HH:mm:ss');

								let operationType = 'Creacion';
								if (log.Operacion === 2) operationType = 'Edicion';
								if (log.Operacion === 3) operationType = 'Eliminacion';
								return (
									<tr key={log.Id_Registro}>
										<td>{localDateTime}</td>
										<td>{log.usuario_bitacora.Username}</td>
										<td>{operationType}</td>
										<td>{log.Tabla_Operacion}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Logs;
